import { Flex } from "@chakra-ui/react";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { ReactNode } from "react";

type PaddedFlexProps = React.ComponentProps<typeof Flex> & {
    children: ReactNode;
};

const PaddedFlex: React.FC<PaddedFlexProps> = ({ children, ...rest }) => {
    const { isAppSession } = useContext(UserContext);
    let padding = isAppSession ? { sm: "60px", lg: "60px" } : { sm: "125px", lg: "75px" };

    return (
        <Flex pt={padding} {...rest}>
            {children}
        </Flex>
    );
};

export default PaddedFlex;
