// Chakra imports
import {
    Box,
    Flex,
    FormControl,
    Table,
    Thead,
    Tbody,
    Text,
    Tr,
    Th,
    Spinner,
    HStack,
    VStack,
    Td,
    IconButton,
    useColorModeValue, useDisclosure,
} from "@chakra-ui/react";
import {EditIcon} from '@chakra-ui/icons'
import * as React from "react";
import {Controller, FormProvider, useForm, useFormContext} from "react-hook-form";
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';

import {useToast} from "@chakra-ui/react";

import {useEffect, useState} from "react";
import useGetOrderModes from "../../../../../api/productLibrary/getOrderModes";
import useGetTaxRates from "../../../../../api/productLibrary/getTaxRates";

import EditPricingModal from "./EditPricingModal";
import useManageProduct from "../../../../../api/productLibrary/manageProduct";
import Dinero from "dinero.js";
import {ObjectId} from "bson";

type OrderMode = {
    _id: string;
    name: string;
};

type VariantPriceDetail = {
    price: Dinero.Dinero;
    costPrice: Dinero.Dinero;
    taxRate: number;
    variant: string;
    orderMode: string;
    productName: string;
    orderModeName: string;
    variantName?: string;
    _id: string;
};

type VariantData = {
    [orderModeName: string]: VariantPriceDetail;
};

type Rows = {
    [variantName: string]: VariantData;
};

// @ts-ignore
export default function Pricing({data, refetch}) {
    // Chakra Color Mode
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'secondaryGray.600';

    const {reset, handleSubmit, control, formState: {errors}, getValues, watch, setValue, register} = useForm();

    const [{data: orderModesRaw}] = useGetOrderModes()
    const [{isLoading: loadingTaxRates, data: taxRates}] = useGetTaxRates();
    const [orderModes, setOrderModes] = useState<OrderMode[]>([]);
    const [rows, setRows] = useState<Rows>({});

    const [productId, setProductId] = useState('');

    const methods = useForm();

    const { updatePriceDetail, loading, error } = useManageProduct();

    const toast = useToast();

    const getTaxRateName = (taxRateId: any) => {
        const taxRate = taxRates?.find((taxRate: { _id: string; }) => taxRate._id === taxRateId);
        return taxRate ? taxRate.name + " - " + (taxRate.rate / 100) + "%" : "No Tax";
    };

    useEffect(() => {
        if (taxRates) {
            console.log(taxRates)
        }
    }, [taxRates]);

    useEffect(() => {
        if (orderModesRaw) {
            setOrderModes(orderModesRaw);
        }
    }, [orderModesRaw]);

    useEffect(() => {
        if (data?._id) {
            setProductId(data._id)
        }
    }, [data]);


    useEffect(() => {
        if (orderModes && data) {
            reset();
            let newRows = {};

            if (data.variantGroups && data.variantGroups.length === 0) {
                // The product has no variants
                let row = {};
                orderModes.forEach(orderMode => {
                    let productDetails = data.priceDetails.find(
                        (pd: { orderMode: string; }) => pd.orderMode === orderMode._id
                    );

                    if (productDetails) {
                        const priceDetail = {
                            ...productDetails,
                            productName: data.name,  // assuming `data.name` is the product name
                            orderModeName: orderMode.name,
                        };
                        // @ts-ignore
                        row[orderMode.name] = priceDetail;
                    }
                });
                // @ts-ignore
                newRows["default"] = row;
            } else if (data.variantGroups) {
                // The product has variants
                data.variantGroups.forEach((variantGroup: { variants: any[]; }) =>
                    variantGroup.variants.forEach(variant => {
                        let row = {};
                        orderModes.forEach(orderMode => {
                            let productDetails = data.priceDetails.find(
                                (pd: { variant: any; orderMode: string; }) =>
                                    pd.variant === variant._id && pd.orderMode === orderMode._id
                            );

                            if (productDetails) {
                                const priceDetail = {
                                    ...productDetails,
                                    productName: data.name,  // assuming `data.name` is the product name
                                    orderModeName: orderMode.name,
                                    variantName: variant.name,  // optional variant name
                                };
                                // @ts-ignore
                                row[orderMode.name] = priceDetail;
                            }
                        });
                        // @ts-ignore
                        newRows[variant._id] = { ...row, variantName: variant.name }; // Include variantName here
                    })
                );
            }

            setRows(newRows);
        }
    }, [data, orderModes]);

    const onSubmit = (prices: any) => alert(JSON.stringify(prices));

    const {isOpen, onOpen, onClose} = useDisclosure()
    const [selectedData, setSelectedData] = useState(null)

    useEffect(() => {
        if (selectedData) {

        }
    }, [selectedData, methods]);

    const [formData, setFormData] = useState(null);

    const openModal = (data: {
        _id: string;
        price: any; costPrice: any; taxRate: any; variant?: string; orderMode?: string; }) => {
        console.log(data)
        setSelectedData(data);
        setFormData({
            price: data.price,
            costPrice: data.costPrice,
            taxRate: data.taxRate,
            _id: data._id
        });
        onOpen();
    }

    const onModalFormSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        formData.orderMode = selectedData.orderMode


        console.log("SD VARIANT", selectedData.variant)
        if(selectedData.variant) {
            formData.variant = selectedData.variant
        }

        const updatedFormData = {
            ...formData,
            _id: formData._id,  // Add the _id here
            price: typeof formData.price === 'string'
                ? Dinero({ amount: Math.round(Number(formData.price) * 100), currency: 'GBP' })
                : formData.price,
            costPrice: typeof formData.costPrice === 'string'
                ? Dinero({ amount: Math.round(Number(formData.costPrice) * 100), currency: 'GBP' })
                : formData.costPrice,
        };

        console.log(updatedFormData);

        try {
            await updatePriceDetail(productId, updatedFormData);
            refetch();
            toast({
                title: "Product pricing updated.",
                description: "Your product has been successfully updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (err) {
            console.log(err);
            toast({
                title: "An error occurred.",
                description: "Unable to update product pricing.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }

        setSelectedData(null);
    }

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const {name, value} = e.target;
        setFormData((prev: any) => ({
            ...prev,
            [name]: value
        }));
    }


    if (!rows || loadingTaxRates) {
        <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
            <Spinner size="xl"/>
        </Box>
    }


    return (
        <FormControl as="form">
            <Card mb="20px">
                <Flex direction='column' mb='40px' ms='10px'>
                    <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
                        Product Pricing
                    </Text>
                </Flex>
                <Box overflowX="auto">
                    <Table variant="striped" colorScheme="default">
                        <Thead>
                            <Tr>
                                <Th></Th>
                                {orderModes.map((orderMode: {
                                    _id: React.Key;
                                    name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
                                }) => (
                                    <Th key={orderMode._id}>{orderMode.name}</Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {Object.entries(rows).map(([variantId, variantData]) => (
                                <Tr key={variantId}>
                                    <Th>{variantData.variantName || "default"}</Th> {/* Access and render variant name here */}
                                    {orderModes.map((orderMode) => (
                                        <Td key={orderMode._id}>
                                            {(() => {
                                                let activeData = variantData[orderMode.name]
                                                    ? variantData[orderMode.name]
                                                    // Here, you create a dummy record with necessary minimal data
                                                    : {
                                                        _id: new ObjectId().toString(),
                                                        price:  Dinero({ amount: 0, currency: 'GBP' }),
                                                        costPrice: Dinero({ amount: 0, currency: 'GBP' }),
                                                        taxRate: '',
                                                        variant: variantId, // Use variantId here
                                                        orderMode: orderMode._id,
                                                        productName: data.name,
                                                        orderModeName: orderMode.name
                                                    };

                                                return (
                                                    <HStack spacing={3}>
                                                        <VStack align="start" spacing={1}>
                                                            <Text color={textColorPrimary} fontSize={'lg'}>
                                                                Price: {activeData.price.toFormat()}
                                                            </Text>
                                                            <Text color={textColorPrimary}>
                                                                Cost Price: {activeData.costPrice.toFormat()}
                                                            </Text>
                                                            <Text color={textColorPrimary}>
                                                                Tax Rate: {getTaxRateName(activeData.taxRate)}
                                                            </Text>
                                                        </VStack>
                                                        <IconButton
                                                            aria-label="Edit"
                                                            icon={<EditIcon />}
                                                            colorScheme="teal"
                                                            variant="outline"
                                                            onClick={() => openModal(activeData)}
                                                        />
                                                    </HStack>
                                                );
                                            })()}
                                        </Td>
                                    ))}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </Card>

            <EditPricingModal
                selectedData={selectedData}
                taxRates={taxRates}
                onModalFormSubmit={onModalFormSubmit}
                handleChange={handleChange}
                formData={formData}
                setFormData={setFormData}
            />

        </FormControl>
    );
}
