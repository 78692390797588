import {
    Flex,
    Spinner,
    Text,
    Button,
    Select,
    Card,
    Icon,
    useDisclosure
} from '@chakra-ui/react';
import React, { useState, useCallback, useEffect } from 'react';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import update from 'immutability-helper';
import useGetPrintRoutes from "../../../api/printing/getPrintRoutes";
import useGetCategories from "../../../api/productLibrary/getCategories";
import useGetPrinters from "../../../api/printing/getPrinters";
import { ObjectId } from 'bson';
import { DndProvider } from "react-dnd-multi-backend";
import AddCategoryModal from "./components/addCategoryModal";
import DraggableCategory from './components/draggableCategory';
import useSavePrintRoute from "../../../api/printing/managePrintRoute"; // Assuming DraggableCategory is in the same directory

const Printing: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { printRoutes: initialPrintRoutes, loading: routesLoading, error: routesError } = useGetPrintRoutes();
    const [{ data: categories, isLoading: categoriesLoading, isError: categoriesError }] = useGetCategories();
    const { printers, loading: printersLoading, error: printersError } = useGetPrinters();
    const [printRoutes, setPrintRoutes] = useState(initialPrintRoutes || []);
    const [selectedRouteIndex, setSelectedRouteIndex] = useState<number | null>(null);
    const savePrintRoute = useSavePrintRoute();

    useEffect(() => {
        if (initialPrintRoutes) {
            setPrintRoutes(initialPrintRoutes);
        }
    }, [initialPrintRoutes]);

    const getCategoryNameById = useCallback(
        (categoryId: ObjectId) => {
            const category = categories?.find(
                (cat: { _id: { toString: () => string } }) => cat._id.toString() === categoryId.toString()
            );
            return category ? category.name : 'Unknown Category';
        },
        [categories]
    );

    const addCategoryToRoute = (category: ObjectId) => {
        if (selectedRouteIndex !== null) {
            setPrintRoutes((prevRoutes) =>
                update(prevRoutes, {
                    [selectedRouteIndex]: {
                        category: {
                            $push: [{
                                category: category,
                                position: prevRoutes[selectedRouteIndex].category.length,
                            }],
                        },
                    },
                })
            );
            onClose();
        }
    };

    const moveCategory = useCallback(
        (routeIndex: number) => (fromIndex: number, toIndex: number) => {
            setPrintRoutes((prevRoutes) =>
                update(prevRoutes, {
                    [routeIndex]: {
                        category: {
                            $splice: [
                                [fromIndex, 1],
                                [toIndex, 0, prevRoutes[routeIndex].category[fromIndex]],
                            ],
                        },
                    },
                })
            );

            setPrintRoutes((prevRoutes) => {
                const updatedRoute = prevRoutes[routeIndex];
                const updatedCategories = updatedRoute.category.map((cat, idx) => ({
                    ...cat,
                    position: idx,
                }));
                const newRoutes = [
                    ...prevRoutes.slice(0, routeIndex),
                    { ...updatedRoute, category: updatedCategories },
                    ...prevRoutes.slice(routeIndex + 1),
                ];
                return newRoutes;
            });
        },
        []
    );

    const removeCategory = (routeIndex: number, categoryIndex: number) => {
        setPrintRoutes((prevRoutes) =>
            update(prevRoutes, {
                [routeIndex]: {
                    category: {
                        $splice: [
                            [categoryIndex, 1],
                        ],
                    },
                },
            })
        );
    };

    const handleSaveRoute = async (routeIndex: number) => {
        const routeToSave = printRoutes[routeIndex];

        const updatedCategories = routeToSave.category.map((cat, index) => ({
            category: cat.category,
            position: index,
        }));

        try {
            await savePrintRoute({
                routeId: routeToSave._id,
                name: routeToSave.name || '',
                printer: routeToSave.printer || '',
                copies: routeToSave.copies || 0,
                categories: updatedCategories,
            });
            console.log('Route saved successfully');
        } catch (error) {
            console.error(`Failed to save print route`);
        }
    };

    const handlePrinterChange = (routeIndex: number, printerId: string) => {
        setPrintRoutes((prevRoutes) =>
            update(prevRoutes, {
                [routeIndex]: {
                    printer: { $set: printerId }
                }
            })
        );
    };

    const handleCopiesChange = (routeIndex: number, copies: number) => {
        setPrintRoutes((prevRoutes) =>
            update(prevRoutes, {
                [routeIndex]: {
                    copies: { $set: copies }
                }
            })
        );
    };

    if (routesLoading || categoriesLoading || printersLoading) {
        return (
            <Flex justifyContent="center" alignItems="center" height="100vh">
                <Spinner size="xl" />
            </Flex>
        );
    }

    if (routesError) {
        return (
            <Flex justifyContent="center" alignItems="center" height="100vh">
                <Text>{routesError.message}</Text>
            </Flex>
        );
    }

    if (categoriesError) {
        return (
            <Flex justifyContent="center" alignItems="center" height="100vh">
                <Text>{categoriesError}</Text>
            </Flex>
        );
    }

    if (printersError) {
        return (
            <Flex justifyContent="center" alignItems="center" height="100vh">
                <Text>{printersError.message}</Text>
            </Flex>
        );
    }

    return (
        <DndProvider options={HTML5toTouch}>
            <Flex
                direction="column"
                pt={{ sm: '125px', lg: '75px' }}
                height="100vh"
                w="100%"
            >
                <Flex w="100%" h="100%" wrap="wrap" gap={4}>
                    {printRoutes.map((route, routeIndex) => (
                        <Card
                            key={route._id.toString()}
                            p={4}
                            width="300px"
                        >
                            <Text mt={2} mb={2} fontWeight="bold" size="lg">
                                {route.name || 'Unnamed Route'}
                            </Text>

                            <Text>Printer:</Text>
                            <Select
                                placeholder="Select printer"
                                value={route.printer || ''}
                                onChange={(e) => handlePrinterChange(routeIndex, e.target.value)}
                            >
                                {printers?.map((printer) => (
                                    <option key={printer._id.toString()} value={printer._id.toString()}>
                                        {printer.name || 'Unnamed Printer'}
                                    </option>
                                ))}
                            </Select>

                            <Text mt={2}>Copies:</Text>
                            <Select
                                placeholder="Select copies"
                                value={route.copies || ''}
                                onChange={(e) => handleCopiesChange(routeIndex, parseInt(e.target.value, 10))}
                            >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </Select>

                            <Flex direction="column" mb={2} mt={2}>
                                <Button colorScheme="green" onClick={() => { setSelectedRouteIndex(routeIndex); onOpen(); }}>
                                    Add Category
                                </Button>
                            </Flex>

                            <Flex direction="column">
                                {route?.category?.sort((a, b) => a.position - b.position).map((categoryObj, index) => (
                                    <DraggableCategory
                                        key={categoryObj.category.toString()}
                                        categoryObj={categoryObj}
                                        index={index}
                                        moveCategory={moveCategory(routeIndex)}
                                        getCategoryNameById={getCategoryNameById}
                                        removeCategory={(categoryIndex) => removeCategory(routeIndex, categoryIndex)}
                                    />
                                ))}
                            </Flex>

                            <Flex direction="column" mt={2}>
                                <Button colorScheme="blue" onClick={() => handleSaveRoute(routeIndex)}>
                                    Save Changes
                                </Button>
                            </Flex>
                        </Card>
                    ))}
                </Flex>
            </Flex>

            <AddCategoryModal
                isOpen={isOpen}
                onClose={onClose}
                categories={categories || []}
                existingCategories={selectedRouteIndex !== null ? printRoutes[selectedRouteIndex].category.map(cat => cat.category) : []}
                addCategory={addCategoryToRoute}
            />
        </DndProvider>
    );
};

export default Printing;
