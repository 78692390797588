// components/TableAreaList.tsx
import React, { useState } from 'react';
import {
    Box, Button, List, ListItem,
    useDisclosure, FormControl, FormLabel, Input,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { ObjectId } from 'bson';

interface TableArea {
    _id: ObjectId;
    name: string;
    siteId: string;
}

interface TableAreaListProps {
    tableAreas: TableArea[];
    selectedTableAreaId: string | null;
    onSelect: (id: string) => void;
    onAddNew: (name: string) => void;  // Handle add new action
}

const TableAreaList: React.FC<TableAreaListProps> = ({ tableAreas, selectedTableAreaId, onSelect, onAddNew }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [name, setName] = useState('');

    const handleAddNewTableArea = () => {
        onAddNew(name);
        setName('');
        onClose();
    };

    return (
        <Box w="250px" p="4" borderRight="1px solid" borderColor="gray.200">
            <Button
                rightIcon={<AddIcon />}
                colorScheme="green"
                mb="4"
                onClick={onOpen}
                aria-label="Add new table area"
            >
                Add new area
            </Button>
            <List spacing={3}>
                {tableAreas.map((area) => (
                    <ListItem
                        key={area._id.toString()} // Convert ObjectId to string
                        p="2"
                        pl={4}
                        cursor="pointer"
                        borderRadius={"10px"}
                        bg={selectedTableAreaId === area._id.toString() ? "brand.500" : "white"}
                        color={selectedTableAreaId === area._id.toString() ? "white" : "black"}
                        onClick={() => onSelect(area._id.toString())} // Convert ObjectId to string on select
                    >
                        {area.name}
                    </ListItem>
                ))}
            </List>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New Table Area</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Table Area Name</FormLabel>
                            <Input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter table area name"
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="green" onClick={handleAddNewTableArea}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default TableAreaList;
