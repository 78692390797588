// Chakra imports
import {Button, LightMode, useToast, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay} from '@chakra-ui/react';
import Card from 'components/card/Card';
import {useFormContext} from "react-hook-form";
import useManageProduct from "../../../../../api/productLibrary/manageProduct";
import {useRef, useState} from "react";



// @ts-ignore
export default function Delete({productId, refetch}) {
	const { handleSubmit } = useFormContext();
	const toast = useToast()
	const onSubmitForm = async (data: any) => {

		try {
			await updateProductInfo(productId, data);
			toast({
				title: "Success",
				description: "Product information updated successfully",
				status: "success",
				duration: 5000,
				isClosable: true,
			})
		} catch(err) {

			toast({
				title: "An error occurred",
				description: "There was an error while updating product information",
				status: "error",
				duration: 5000,
				isClosable: true,
			})
		}

	};

	const { updateProductInfo, markProductAsDeleted } = useManageProduct();

	const [isOpen, setIsOpen] = useState(false);
	const onClose = () => setIsOpen(false);
	const cancelRef = useRef();

	const onSubmit = async () => {
		await markProductAsDeleted(productId);
		onClose();
		refetch();
	};

	return (
		<Card p='60px 30px' flexDirection={{base: 'column', md: 'row'}} alignItems='center'>
			<LightMode>
				<Button
					colorScheme='red'
					variant='outline'
					p='15px 40px'
					fontSize='sm'
					fontWeight='500'
					ms={{base: '0', md: 'auto'}}
					mb={{base: '20px', md: '0'}}
					me={{base: '0', md: '20px'}}
					_hover={{bg: 'whiteAlpha.100'}}
					_focus={{bg: 'transparent'}}
					_active={{bg: 'transparent'}}
					onClick={() => setIsOpen(true)}>
					Delete product
				</Button>
			</LightMode>
			<Button variant='brand' minW='183px' fontSize='sm' fontWeight='500' onClick={handleSubmit(onSubmitForm)}>
				Save changes
			</Button>
			<div>
				<AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef} isCentered>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader>Delete Product</AlertDialogHeader>
							<AlertDialogBody>Are you sure you want to delete this product? This can be undone in the future.</AlertDialogBody>
							<AlertDialogFooter>
								<Button ref={cancelRef} onClick={onClose}>
									Cancel
								</Button>
								<Button colorScheme="red" onClick={onSubmit} ml={3}>
									Delete
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			</div>
		</Card>

	);
}
