import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalCloseButton,
    useDisclosure,
    Switch,
    Text, useToast, Grid, Image, Box, Select
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";

import {AddIcon} from "@chakra-ui/icons";

import useCreateCategory from "../../../../../api/productLibrary/createCategory";

import baguette from "../../../../../assets/layoutIcons/baguette.svg";
import bbq from "../../../../../assets/layoutIcons/bbq.svg";
import beer from "../../../../../assets/layoutIcons/beer.svg";
import broccoli from "../../../../../assets/layoutIcons/broccoli.svg";
import burger from "../../../../../assets/layoutIcons/burger.svg";
import cake from "../../../../../assets/layoutIcons/cake.svg";
import champagne_glass from "../../../../../assets/layoutIcons/champagne_glass.svg";
import cheese from "../../../../../assets/layoutIcons/cheese.svg";
import cocktail_glass from "../../../../../assets/layoutIcons/cocktail_glass.svg";
import cookie from "../../../../../assets/layoutIcons/cookie.svg";
import croissant from "../../../../../assets/layoutIcons/croissant.svg";
import coffee from "../../../../../assets/layoutIcons/coffee.svg";
import cup_to_go from "../../../../../assets/layoutIcons/cup_to_go.svg";
import dish from "../../../../../assets/layoutIcons/dish.svg";
import donut from "../../../../../assets/layoutIcons/donut.svg";
import fish from "../../../../../assets/layoutIcons/fish.svg";
import french_fries from "../../../../../assets/layoutIcons/french_fries.svg";
import fried_egg from "../../../../../assets/layoutIcons/fried_egg.svg";
import gyro from "../../../../../assets/layoutIcons/gyro.svg";
import hot_dog from "../../../../../assets/layoutIcons/hot_dog.svg";
import ice_cream from "../../../../../assets/layoutIcons/ice_cream.svg";
import juice from "../../../../../assets/layoutIcons/juice.svg";
import meat from "../../../../../assets/layoutIcons/meat.svg";
import noodles from "../../../../../assets/layoutIcons/noodles.svg";
import pizza from "../../../../../assets/layoutIcons/pizza.svg";
import strawberry from "../../../../../assets/layoutIcons/strawberry.svg";
import taco from "../../../../../assets/layoutIcons/taco.svg";
import tea from "../../../../../assets/layoutIcons/tea.svg";
import wine_glass from "../../../../../assets/layoutIcons/wine_glass.svg";
import useUpdateCategory from "../../../../../api/productLibrary/useUpdateCategory";
import useGetPrintRoutes from "../../../../../api/printing/getPrintRoutes";
import useAddCategoryToPrintRoute from "../../../../../api/printing/useAddCategoryToPrintRoute";

const icons = [
    { src: baguette, alt: 'Baguette', name: 'baguette', tags: ['bread', 'bakery', 'food'] },
    { src: bbq, alt: 'BBQ', name: 'bbq', tags: ['grill', 'meat', 'barbecue', 'food', 'bbq'] },
    { src: beer, alt: 'Beer', name: 'beer', tags: ['alcohol', 'drink', 'brew'] },
    { src: broccoli, alt: 'Broccoli', name: 'broccoli', tags: ['vegetable', 'vegan', 'food', 'healthy'] },
    { src: burger, alt: 'Burger', name: 'burger', tags: ['fast food', 'meat', 'food', 'fries', 'burger', 'burgers'] },
    { src: cake, alt: 'Cake', name: 'cake', tags: ['dessert', 'sweet', 'birthday'] },
    { src: champagne_glass, alt: 'Champagne Glass', name: 'champagne_glass', tags: ['drink', 'alcohol', 'celebration', 'drinks'] },
    { src: cheese, alt: 'Cheese', name: 'cheese', tags: ['dairy', 'food', 'burger'] },
    { src: cocktail_glass, alt: 'Cocktail Glass', name: 'cocktail_glass', tags: ['drink', 'alcohol', 'party', 'drinks', 'cocktail', 'cocktails'] },
    { src: cookie, alt: 'Cookie', name: 'cookie', tags: ['dessert', 'sweet', 'biscuit', 'desserts'] },
    { src: croissant, alt: 'Croissant', name: 'croissant', tags: ['bread', 'bakery', 'food'] },
    { src: coffee, alt: 'Coffee', name: 'coffee', tags: ['drink', 'dark', 'cup', 'coffees'] },
    { src: cup_to_go, alt: 'Cup to go', name: 'cup_to_go', tags: ['drink', 'coffee', 'takeaway', 'coffees'] },
    { src: dish, alt: 'Dish', name: 'dish', tags: ['plate', 'food', 'meal'] },
    { src: donut, alt: 'Donut', name: 'donut', tags: ['dessert', 'sweet', 'baked'] },
    { src: fish, alt: 'Fish', name: 'fish', tags: ['seafood', 'food', 'fish', 'sea'] },
    { src: french_fries, alt: 'French Fries', name: 'french_fries', tags: ['fast food', 'potato', 'food', 'fries'] },
    { src: fried_egg, alt: 'Fried Egg', name: 'fried_egg', tags: ['breakfast', 'food', 'egg'] },
    { src: gyro, alt: 'Gyro', name: 'gyro', tags: ['meat', 'sandwich', 'food'] },
    { src: hot_dog, alt: 'Hot Dog', name: 'hot_dog', tags: ['fast food', 'meat', 'food'] },
    { src: ice_cream, name: 'ice_cream', alt: 'Ice Cream', tags: ['dessert', 'sweet', 'cold'] },
    { src: juice, name: 'juice', alt: 'Juice', tags: ['drink', 'fruit', 'refreshing', 'drinks', 'soft', 'softs'] },
    { src: meat, name: 'meat', alt: 'Meat', tags: ['protein', 'food', 'bbq', 'meat', 'hot'] },
    { src: noodles, name: 'noodles', alt: 'Noodles', tags: ['asian', 'food', 'dinner', 'pasta'] },
    { src: pizza, name: 'pizza', alt: 'Pizza', tags: ['fast food', 'italian', 'food', 'pizza', 'pizzas'] },
    { src: strawberry, name: 'strawberry', alt: 'Strawberry', tags: ['fruit', 'food', 'berry', 'dessert', 'desserts'] },
    { src: taco, name: 'taco', alt: 'Taco', tags: ['mexican', 'food', 'fast food', 'food'] },
    { src: tea, name: 'tea', alt: 'Tea', tags: ['drink', 'hot', 'cup'] },
    { src: wine_glass, name: 'wine_glass', alt: 'Wine Glass', tags: ['drink', 'alcohol', 'wine', 'drinks', 'wines'] }
];

type PrintRoute = {
    name: string;
    _id: string;
};

export default function AddCategoryModal(props: { refetch?: any, title?: string, initialCategory?: any, isOpen?: any, onOpen?: any, onClose?: any}) {
    const {refetch, title, initialCategory, onOpen, isOpen, onClose} = props;
    const {createCategory, isLoading, isError} = useCreateCategory();
    const {updateCategoryById} = useUpdateCategory();
    const {printRoutes} = useGetPrintRoutes();
    const addCategoryToPrintRoute = useAddCategoryToPrintRoute();

    const [category, setCategory] = useState({
        name: '',
        enableAutoLayout: true,
        icon: icons[0].name,
    });
    const [editing, setEditing] = useState(false);

    const [selectedRoute, setSelectedRoute] = useState<string | null>(null);

    useEffect(() => {
        if (initialCategory?._id) {
            setCategory(initialCategory);
            setEditing(true);
        }
    }, [initialCategory]);

    const inputWords = category.name.toLowerCase().split(' ');
    const matchedIcons = icons.filter(icon =>
        icon.tags.some(tag => inputWords.includes(tag))
    );
    const unmatchedIcons = icons.filter(icon =>
        !icon.tags.some(tag => inputWords.includes(tag))
    );
    const sortedIcons = [...matchedIcons, ...unmatchedIcons];

    const [selectedIcon, setSelectedIcon] = useState(sortedIcons[0].name);

    useEffect(() => {
        if (matchedIcons.length > 0) {
            setSelectedIcon(matchedIcons[0].name);
        }
    }, [category.name]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedRoute(event.target.value);
    };

    // Function to handle icon selection
    const handleIconSelect = (name: string) => {
        setSelectedIcon(name);
        setCategory((prev) => ({
            ...prev,
            icon: name
        }))
    };

    const toast = useToast();

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if(!editing) {
            if (!category.name) {
                toast({
                    title: "Validation Error",
                    description: "Category name is required",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }
            try {
                let catId = await createCategory(category);

                if(selectedRoute !== null){
                    await addCategoryToPrintRoute(selectedRoute, catId);
                }

                toast({
                    title: "Category Created",
                    description: "You have successfully created a new category",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                refetch();
                onClose();
                setCategory({name: '', enableAutoLayout: true, icon: icons[0].name});
            } catch (err) {
                console.log(err);
                toast({
                    title: "Error",
                    description: "An error occurred while creating the category",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            let updateCat = {...category, _id: initialCategory._id};
            await updateCategoryById(updateCat);
            toast({
                title: "Category Update",
                description: "You have successfully updated the category",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            refetch();
            onClose();
            setCategory({name: '', enableAutoLayout: true, icon: icons[0].name});
        }
    };

    const close = () => {
        setCategory({name: '', enableAutoLayout: true, icon: icons[0].name});
        onClose();
    };

    return (
        <>
            <Button colorScheme='green' rightIcon={<AddIcon/>}
                    onClick={onOpen}>{title ? title : "Add New"}</Button>

            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={close}>
                <ModalOverlay/>
                <ModalContent>
                    {!editing &&
                        <ModalHeader>Create a new category</ModalHeader>
                    }
                    {editing &&
                        <ModalHeader>Edit category</ModalHeader>
                    }
                    <ModalCloseButton/>
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>Category Name</FormLabel>
                            <Input
                                value={category.name}
                                isDisabled={isLoading}
                                onChange={(e) =>
                                    setCategory((prev) => ({
                                        ...prev,
                                        name: e.target.value,
                                    }))
                                }
                            />
                        </FormControl>
                        { !editing &&
                            <FormControl mt={4}>
                                <FormLabel>Enable Auto Layout</FormLabel>
                                <Switch
                                    defaultChecked={true}
                                    isDisabled={isLoading}
                                    onChange={(e) =>
                                        setCategory((prev) => ({
                                            ...prev,
                                            enableAutoLayout: e.target.checked,
                                        }))
                                    }/>
                            </FormControl>
                        }

                        {category.enableAutoLayout && (
                            <FormControl mt={4}>
                                <FormLabel>Layout Icon</FormLabel>
                                <Grid templateColumns="repeat(6, 1fr)" gap={2}>
                                    {sortedIcons.map((icon, index) => (
                                        <Box
                                            w="50px"
                                            h="50px"
                                            borderRadius='md'
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            key={index}
                                            border={selectedIcon === icon.name ? '2px solid blue' : '2px solid transparent'}
                                            onClick={() => handleIconSelect(icon.name)}
                                            backgroundColor={selectedIcon === icon.name ? 'brand.200' : 'gray.300'}
                                        >
                                            <Image src={icon.src} alt={icon.name} boxSize="40px"/>
                                        </Box>
                                    ))}
                                </Grid>
                            </FormControl>
                        )}

                        {printRoutes.length > 0 && !editing &&(
                            <FormControl mt={4}>
                                <FormLabel>Assign to print route?</FormLabel>
                                <Select placeholder="Select Route" onChange={handleChange}>
                                    {printRoutes.map(route => (
                                        <option key={route._id.toString()} value={route._id.toString()}>
                                            {route.name}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        {isError && <Text color="red.500">An error occurred while creating the category. Please try again.</Text>}
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} type="submit" isLoading={isLoading} onClick={handleSubmit}>
                            Save
                        </Button>
                        <Button onClick={close}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
