// Chakra imports
import {
  Flex,
  Box,
  Icon,
  Select,
  Text,
    Spinner,
  useColorModeValue,
} from '@chakra-ui/react';
import LineChart from 'components/charts/LineChart';

// Custom components
import Card from 'components/card/Card';
import {
  lineChartDataOverallRevenue,
  lineChartOptionsOverallRevenue,
} from 'variables/charts';

// Assets
import { RiArrowUpSFill } from 'react-icons/ri';
import {useGetSalesByDay} from "../../../../../api/reports/getSalesByDay";
import {useEffect, useState} from "react"
import {formatCurrency} from "../../../../../helpers/accountCurrency";


const toFormattedDate = (date: string | number | Date) => {
  const formatedDate = new Date(date);
  const day = formatedDate.getDate();
  const month = formatedDate.toLocaleString('default', { month: 'long' });
  return `${day}${day%10 === 1 && day !== 11 ? 'st' : day%10 === 2 && day !== 12 ? 'nd' : day%10 === 3 && day !== 13 ? 'rd' : 'th'} ${month}`;
};

const formatSalesData = (rawSalesData: { salesData: any[]; map: (arg0: { (item: any): any; (item: any): string; }) => any; }) => {
  const grossSalesData = rawSalesData.salesData?.map(item => item.grossSales);
  const netSalesData = rawSalesData.salesData?.map(item => item.netSales);
  const formattedDates = rawSalesData.salesData?.map(item => toFormattedDate(item.date));

  const formattedSalesData = [
    {
      name: 'Gross Sales',
      data: grossSalesData,
    }
  ];

  return {
    formattedSalesData,
    formattedDates
  };
};

export default function OverallRevenue(props: { [x: string]: any }) {
  const { ...rest } = props;
  //eslint-disable-next-line

  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState(lineChartOptionsOverallRevenue);
  const [quickDate, setQuickDate] = useState("this week");
  const [shouldRenderChart, setShouldRenderChart] = useState(false);

  const newOptions = {
    ...lineChartOptionsOverallRevenue,
    colors: ['var(--chakra-colors-brand-500)', '#39B8FF'],
  };

  const [{ data, isLoading, isError }] = useGetSalesByDay(quickDate);

  useEffect(() => {
    if (data) {
      const { formattedSalesData, formattedDates } = formatSalesData(data);

      setChartData(formattedSalesData);

      setChartOptions((prev) => ({
        ...prev,
        xaxis: {
          ...prev.xaxis,
          categories: formattedDates,
        },
      }));
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading) {
      setShouldRenderChart(true);
    } else {
      setShouldRenderChart(false);
    }
  }, [isLoading]);

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Card
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100%"
      mb={{ base: '20px', lg: '0px' }}
      {...rest}
    >
      <Flex justify="space-between" px="20px" pt="5px" w="100%">
        <Flex align="center" w="100%">
          <Flex flexDirection="column" me="20px">
            <Text
              color={textColor}
              fontSize="34px"
              fontWeight="700"
              lineHeight="100%"
            >
              {formatCurrency(data?.totalGrossSales)}
            </Text>
            <Text
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
              mt="4px"
            >
              Gross Sales
            </Text>
          </Flex>
          <Flex align="center">
            {/* <Icon as={RiArrowUpSFill} color="green.500" me="2px" />
            <Text color="green.500" fontSize="sm" fontWeight="700">
              +2.45%
            </Text> */}
          </Flex>
        </Flex>
        <Select
          fontSize="sm"
          variant="subtle"
          defaultValue="this week"
          width="unset"
          fontWeight="700"
          onChange={(e) => setQuickDate(e.target.value)}
        >
          <option value="this week">This Week</option>
          <option value="this month">This Month</option>
          <option value="this quarter">This Quarter</option>
        </Select>
      </Flex>
      <Box minH="260px" mt="auto" w="100%" alignItems="center" justifyContent="center">
        {
          isLoading ?
              <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                  position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)"
              />
              :
              (shouldRenderChart &&
                  <LineChart
                      chartData={chartData}
                      chartOptions={chartOptions}
                  />
              )
        }
      </Box>
    </Card>
  );
}
