import { gql, useQuery } from "@apollo/client";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

const GET_MODIFIER_GROUPS = gql`
    query GetModifierGroups {
        modifierGroups {
            steps {
                allowedChoices
                modifiers {
                    _id
                    name
                    category {
                        _id
                        name
                    }
                    price
                    product {
                        _id
                        name
                    }
                    type
                }
                step
                _id
            }
            _id
            name
        }
    }
`;

const useGetModifierGroups = () => {
    const { user } = useContext(UserContext);

    const shouldSkip = !user?.accessToken;

    const { data, loading, error, refetch } = useQuery(GET_MODIFIER_GROUPS, {
        context: {
            headers: {
                Authorization: "Bearer " + user?.accessToken,
            },
        },
        skip: shouldSkip,
    });

    const modifierGroups = data?.modifierGroups;

    return [
        {
            data: modifierGroups,
            isLoading: loading,
            isError: !!error,
            refetch,
        },
    ] as const;
};

export default useGetModifierGroups;
