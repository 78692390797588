import {
    Button,
    FormControl, FormLabel, Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, useDisclosure, Text, Grid, Box, Image, useToast
} from "@chakra-ui/react";
import CustomCirclePicker from "../../productList/components/CustomCirclePicker";
import * as React from "react";
import useCreateLayout from "../../../../../api/productLibrary/createLayout";
import {AddIcon} from "@chakra-ui/icons";

import baguette from "../../../../../assets/layoutIcons/baguette.svg";
import bbq from "../../../../../assets/layoutIcons/bbq.svg";
import beer from "../../../../../assets/layoutIcons/beer.svg";
import broccoli from "../../../../../assets/layoutIcons/broccoli.svg";
import burger from "../../../../../assets/layoutIcons/burger.svg";
import cake from "../../../../../assets/layoutIcons/cake.svg";
import champagne_glass from "../../../../../assets/layoutIcons/champagne_glass.svg";
import cheese from "../../../../../assets/layoutIcons/cheese.svg";
import cocktail_glass from "../../../../../assets/layoutIcons/cocktail_glass.svg";
import cookie from "../../../../../assets/layoutIcons/cookie.svg";
import croissant from "../../../../../assets/layoutIcons/croissant.svg";
import coffee from "../../../../../assets/layoutIcons/coffee.svg";
import cup_to_go from "../../../../../assets/layoutIcons/cup_to_go.svg";
import dish from "../../../../../assets/layoutIcons/dish.svg";
import donut from "../../../../../assets/layoutIcons/donut.svg";
import fish from "../../../../../assets/layoutIcons/fish.svg";
import french_fries from "../../../../../assets/layoutIcons/french_fries.svg";
import fried_egg from "../../../../../assets/layoutIcons/fried_egg.svg";
import gyro from "../../../../../assets/layoutIcons/gyro.svg";
import hot_dog from "../../../../../assets/layoutIcons/hot_dog.svg";
import ice_cream from "../../../../../assets/layoutIcons/ice_cream.svg";
import juice from "../../../../../assets/layoutIcons/juice.svg";
import meat from "../../../../../assets/layoutIcons/meat.svg";
import noodles from "../../../../../assets/layoutIcons/noodles.svg";
import pizza from "../../../../../assets/layoutIcons/pizza.svg";
import strawberry from "../../../../../assets/layoutIcons/strawberry.svg";
import taco from "../../../../../assets/layoutIcons/taco.svg";
import tea from "../../../../../assets/layoutIcons/tea.svg";
import wine_glass from "../../../../../assets/layoutIcons/wine_glass.svg";
import {useEffect, useState} from "react";
import useUpdateLayout from "../../../../../api/productLibrary/useUpdateLayout";

const icons = [
    { src: baguette, alt: 'Baguette', name: 'baguette', tags: ['bread', 'bakery', 'food'] },
    { src: bbq, alt: 'BBQ', name: 'bbq', tags: ['grill', 'meat', 'barbecue', 'food'] },
    { src: beer, alt: 'Beer', name: 'beer', tags: ['alcohol', 'drink', 'brew'] },
    { src: broccoli, alt: 'Broccoli', name: 'broccoli', tags: ['vegetable', 'vegan', 'food', 'healthy'] },
    { src: burger, alt: 'Burger', name: 'burger', tags: ['fast food', 'meat', 'food', 'fries'] },
    { src: cake, alt: 'Cake', name: 'cake', tags: ['dessert', 'sweet', 'birthday'] },
    { src: champagne_glass, alt: 'Champagne Glass', name: 'champagne_glass', tags: ['drink', 'alcohol', 'celebration', 'drinks'] },
    { src: cheese, alt: 'Cheese', name: 'cheese', tags: ['dairy', 'food', 'burger'] },
    { src: cocktail_glass, alt: 'Cocktail Glass', name: 'cocktail_glass', tags: ['drink', 'alcohol', 'party', 'drinks', 'cocktail', 'cocktails'] },
    { src: cookie, alt: 'Cookie', name: 'cookie', tags: ['dessert', 'sweet', 'biscuit', 'desserts'] },
    { src: croissant, alt: 'Croissant', name: 'croissant', tags: ['bread', 'bakery', 'food'] },
    { src: coffee, alt: 'Coffee', name: 'coffee',tags: ['drink', 'dark', 'cup', 'coffees'] },
    { src: cup_to_go, alt: 'Cup to go', name: 'cup_to_go', tags: ['drink', 'coffee', 'takeaway', 'coffees'] },
    { src: dish, alt: 'Dish', name: 'dish', tags: ['plate', 'food', 'meal'] },
    { src: donut, alt: 'Donut', name: 'donut', tags: ['dessert', 'sweet', 'baked'] },
    { src: fish, alt: 'Fish', name: 'fish', tags: ['seafood', 'food', 'fish', 'sea'] },
    { src: french_fries, alt: 'French Fries', name: 'french_fries', tags: ['fast food', 'potato', 'food', 'fries'] },
    { src: fried_egg, alt: 'Fried Egg', name: 'fried_egg', tags: ['breakfast', 'food', 'egg'] },
    { src: gyro, alt: 'Gyro', name: 'gyro', tags: ['meat', 'sandwich', 'food'] },
    { src: hot_dog, alt: 'Hot Dog', name: 'hot_dog', tags: ['fast food', 'meat', 'food'] },
    { src: ice_cream, name:"ice_cream", alt: 'Ice Cream', tags: ['dessert', 'sweet', 'cold'] },
    { src: juice, name:"juice", alt: 'Juice', tags: ['drink', 'fruit', 'refreshing', 'drinks', 'soft', 'softs'] },
    { src: meat, name:"meat", alt: 'Meat', tags: ['protein', 'food', 'bbq', 'meat', 'hot'] },
    { src: noodles, name:"noodles", alt: 'Noodles', tags: ['asian', 'food', 'dinner', 'pasta'] },
    { src: pizza, name:"pizza", alt: 'Pizza', tags: ['fast food', 'italian', 'food', 'pizza', 'pizzas'] },
    { src: strawberry, name:"strawberry", alt: 'Strawberry', tags: ['fruit', 'food', 'berry', 'dessert', 'desserts'] },
    { src: taco, name:"taco", alt: 'Taco', tags: ['mexican', 'food', 'fast food', 'food'] },
    { src: tea, name:"tea", alt: 'Tea', tags: ['drink', 'hot', 'cup'] },
    { src: wine_glass, name:"wine_glass", alt: 'Wine Glass', tags: ['drink', 'alcohol', 'wine', 'drinks', 'wines'] }
];


export default function AddLayoutModal(props: { refetch?: any, title?: string, initialLayout?: any, isOpen: any, onClose: any, onOpen: any }) {

    console.log(props?.initialLayout)

    const {refetch, title, initialLayout, isOpen, onClose, onOpen} = props;

    const [name, setName] = React.useState(initialLayout ? initialLayout.name : '');
    const [editing, setEditing] = React.useState(!!initialLayout?._id);

    const inputWords = name.toLowerCase().split(' ');

    const matchedIcons = icons.filter(icon =>
        icon.tags.some(tag => inputWords.includes(tag))
    );

    const unmatchedIcons = icons.filter(icon =>
        !icon.tags.some(tag => inputWords.includes(tag))
    );

    const sortedIcons = [...matchedIcons, ...unmatchedIcons];

    const [selectedIcon, setSelectedIcon] = useState(initialLayout ? initialLayout.icon : sortedIcons[0].name);

    const { createLayout, isLoading, isError } = useCreateLayout();
    const { updateLayoutById } = useUpdateLayout();

    const toast = useToast();

    useEffect(() => {
     if(initialLayout?.name){
         setName(initialLayout.name)
     }

     if(initialLayout?.icon){
         setSelectedIcon(initialLayout.icon);
     }

     if(initialLayout?._id){
         setEditing(true);
     }
    }, [initialLayout]);


    const handleAddNewLayout = async () => {

        if(!editing) {

            await createLayout({name: name, btnColour: " ", icon: selectedIcon});

            toast({
                title: "Layout Created",
                description: "You have successfully created a new layout",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            refetch();
            onClose();

            setName('');
            setSelectedIcon('');
        } else {

            await updateLayoutById({_id: initialLayout._id, name: name, icon: selectedIcon});

            toast({
                title: "Layout Update",
                description: "You have successfully updated the layout",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

            refetch();
            onClose();

            setName('');
            setSelectedIcon('');

        }
    }

    const handleIconSelect = (name: string) => {
        setSelectedIcon(name);
    };

    const handleOpen = () => {
       setEditing(false);
        setName('');
        setSelectedIcon('');

         onOpen();
    }


    return (
        <>
        <Button colorScheme='green' rightIcon={<AddIcon />} onClick={handleOpen}>Add New</Button>

        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent>
                { !editing &&
                <ModalHeader>Add New Layout</ModalHeader>
                }
                { editing &&
                <ModalHeader>Edit Layout</ModalHeader>
                }
                <ModalCloseButton/>
                <ModalBody pb={6}>

                    { !editing &&
                    <Text pb={2}>You are creating a layout that is not linked to a category - all products will need to be added manually after creation.</Text>
                    }
                    <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input placeholder="Layout Name" value={name} onChange={(e) => setName(e.target.value)}/>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Layout Icon</FormLabel>
                        <Grid templateColumns="repeat(6, 1fr)" gap={2}>
                            {sortedIcons.map((icon, index) => (
                                <Box
                                    w="50px"
                                    h="50px"
                                    borderRadius='md'
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    key={index}
                                    border={selectedIcon === icon.name ? '2px solid blue' : '2px solid transparent'}
                                    onClick={() => handleIconSelect(icon.name)}
                                    backgroundColor={selectedIcon === icon.name ? 'brand.200' : 'gray.300'}
                                >
                                    <Image src={icon.src} alt={icon.name} boxSize = "40px"/>
                                </Box>
                            ))}
                        </Grid>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleAddNewLayout}>
                        Save
                    </Button>
                    <Button onClick={() => onClose()}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
            </>
            )
}
