import { Header, Row } from "@tanstack/react-table";
import innerText from "react-innertext";

export const getCsvBlob = (
    headers: Header<any, unknown>[],
    rows: Row<any>[],
    footer: string[] | null
): Blob => {
    let csvContent = "";
    const headerNames = getHeaderNames(headers);
    csvContent += headerNames.join(",") + "\n";

    const data = getRowsData(rows);
    data.forEach((row) => {
        csvContent += row.join(",") + "\n";
    });

    if(footer !== null) {
        footer.forEach(line => {
            csvContent += line + "\n";
        });
    }

    return new Blob([csvContent], { type: "text/csv" });
};

const exportToCsv = (
    fileName = "data",
    headers: Header<any, unknown>[],
    rows: Row<any>[],
    footer: string[] | null
): void => {
    const blob = getCsvBlob(headers, rows, footer);
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const getHeaderNames = (headers: Header<any, unknown>[]): string[] =>
    headers.map((header) => {
        if (typeof header.column.columnDef.header === "function") {
            const headerContext = header.column.columnDef.header(header.getContext());
            if (typeof headerContext === "string") {
                return headerContext;
            }
            return innerText(headerContext);
        } else {
            return header.column.columnDef.header ?? header.id;
        }
    });

const getRowsData = (rows: Row<any>[]): string[][] => {
    //@ts-ignore
    return rows.map((row: Row<any>) => {
        const cells = row.getAllCells();
        const cellsContent = cells.map((cell) => {
            let value = cell.getValue();
            if (typeof value === 'string') {
                value = value.replace(/£/g, '').replace(/"/g, '""');
                return `"${value}"`;
            }
            return value;
        });
        return cellsContent;
    });
};

export default exportToCsv;
