import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Spinner,
    Flex,
    Switch,
    Text,
    Textarea,
    Tooltip
} from "@chakra-ui/react";
import React, { useState, useEffect} from "react";
import useManageSiteSettings from "../../../../api/site/useManageSiteSettings";
import {InfoOutlineIcon} from "@chakra-ui/icons";

export default function ReceiptSettings() {

    const {setReceiptSettings, getReceiptSettings} = useManageSiteSettings();

    const [headerText, setHeaderText] = useState("");
    const [footerText, setFooterText] = useState("");
    const [largeOrderNumber, setLargeOrderNumber] = useState(false);
    const [alwaysPrint, setAlwaysPrint] = useState(false);

    const handleTextareaChange = (setTextFunc: (arg0: any) => void) => (event: { target: { value: any; }; }) => {
        setTextFunc(event.target.value);
    };

    const handleLargeOrderNumberChange = (selected: boolean) => {
        setLargeOrderNumber(selected);
        setReceiptSettings(headerText, footerText, selected, alwaysPrint);
    }

    const handleAlwaysPrintChange = (selected: boolean) => {
        setAlwaysPrint(selected);
        setReceiptSettings(headerText, footerText, largeOrderNumber, selected);
    }


    const handleBlur = async (field: string) => {
        const header = field === "header" ? headerText : null;
        const footer = field === "footer" ? footerText : null;
        // Call your hook here with only the required field being updated
        await setReceiptSettings(header, footer, largeOrderNumber);
    };

    useEffect(() => {
        const fetchSettings = async () => {
            let settings = await getReceiptSettings();
            if (settings) {
                setHeaderText(settings.header);
                setFooterText(settings.footer);
                setLargeOrderNumber(settings.largeOrderNumber);
                setAlwaysPrint(settings.alwaysPrint);
            }
        }
        fetchSettings();
    }, []);

    return (
        <Box overflowX="auto" ml={'20px'} mr={'20px'}>
            <Flex align={{lg: 'center'}} justify={{base: 'space-between'}} w='100%' px='20px' mb='20px'>
                <Text fontSize='xl' fontWeight='600' lineHeight='100%'>Receipt Settings</Text>
            </Flex>

            <Table width={'100%'} size="md" p={'40px'} variant='striped'>
                <Thead>

                </Thead>
                <Tbody>
                    <Tr>
                        <Td>Receipt Header</Td>
                        <Td><Textarea variant="brandPrimary" onChange={handleTextareaChange(setHeaderText)} onBlur={() => handleBlur("header")} defaultValue={headerText} placeholder='Enter receipt header' /></Td>
                    </Tr>
                    <Tr>
                        <Td>Receipt Footer</Td>
                        <Td><Textarea variant="brandPrimary" onChange={handleTextareaChange(setFooterText)} onBlur={() => handleBlur("footer")} defaultValue={footerText} placeholder='Enter receipt footer' /></Td>
                    </Tr>
                    <Tr>
                        <Td>Print large order number
                            <Tooltip label="Prints a large order number at the top of the customers receipt. Perfect if you call out order numbers" placement="right" ml="2">
                            <InfoOutlineIcon ml={1} />
                            </Tooltip>
                        </Td>
                        <Td><Switch id='manual-enable' isChecked={largeOrderNumber} onChange={(e) => handleLargeOrderNumberChange(e.target.checked)}  /></Td>
                    </Tr>
                    <Tr>
                        <Td>Always print receipt
                            <Tooltip label="Enable this to always print a customer receipt." placement="right" ml="2">
                                <InfoOutlineIcon ml={1} />
                            </Tooltip>
                        </Td>
                        <Td><Switch id='manual-enable' isChecked={alwaysPrint} onChange={(e) => handleAlwaysPrintChange(e.target.checked)}  /></Td>
                    </Tr>

                </Tbody>
            </Table>
        </Box>
    );
}
