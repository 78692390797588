import {gql, useQuery} from "@apollo/client";
import {useContext} from "react";
import {UserContext} from "../../contexts/UserContext";

const GET_LAYOUT_BY_ID = gql`
    query GetLayoutById($_id: ObjectId!) {
        layout(query: { _id: $_id }) {
            _id,
            name,
            category{
                name
            },
            layoutItems {
                type
                linkedProduct {
                    _id
                    name
                    btnColour
                }
                order
            }
        }
    }
`;

const useGetLayout = (_id: string) => {
    const { user } = useContext(UserContext);

    const shouldSkip = !user?.accessToken || !_id;

    const { data, loading, error, refetch } = useQuery(GET_LAYOUT_BY_ID,
        {
            variables: { _id },
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            },
            skip: shouldSkip,
        });

    const layout = data?.layout;

    return [{
        data: layout,
        isLoading: loading,
        isError: !!error,
        refetch,
    }] as const;
};

export default useGetLayout;
