import {useApp} from "@realm/react";
import {useContext, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {BSON} from 'realm';

const useManageModifierGroups = () => {
    const app = useApp();
    const {user} = useContext(UserContext);
    let mongo: { db: (arg0: string) => { (): any; new(): any; collection: { (arg0: string): any; new(): any; }; }; };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const updateUser = () => {
        if (user) {
            mongo = user.mongoClient("mongodb-atlas");
        }
    };

    updateUser();

    const addModifier = async (stepId: string, modifierData: any) => {
        setLoading(true);
        setError(null);

        if(!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const modifierCollection = mongo.db("pos").collection("Modifier");
        const modifierStepCollection = mongo.db("pos").collection("ModifierStep");
        const modifierGroupCollection = mongo.db("pos").collection("ModifierGroup");

        try {
            modifierData.owner_id = user.id;
            modifierData._id = {};
            modifierData._id.$oid = new BSON.ObjectId().toString();
            modifierData.siteId = app.currentUser.customData.currentSite.toString();

            const newModifier = await modifierCollection.insertOne(modifierData);

            let stepOid = {}
            // @ts-ignore
            stepOid['$oid'] = stepId;

            await modifierStepCollection.updateOne(
                {_id: stepOid},
                {$push: {modifiers: modifierData._id}}
            );
        } catch (err) {
            console.log(err)
            setError(err);
        }
        setLoading(false);
    };

    const setAllowedChoices = async (stepId: string, allowedChoices: any) => {
        setLoading(true);
        setError(null);

        if(!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const modifierStepCollection = mongo.db("pos").collection("ModifierStep");

        try {

            let stepOid = {}
            // @ts-ignore
            stepOid['$oid'] = stepId;
            await modifierStepCollection.updateOne(
                {_id: stepOid},
                {$set: {allowedChoices: allowedChoices}}
            );
        } catch (err) {
            console.log(err)
            setError(err);
        }
        setLoading(false);
    };


    const deleteModifier = async (modifierId: string, stepId: string) => {
        setLoading(true);
        setError(null);

        if(!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const modifierCollection = mongo.db("pos").collection("Modifier");
        const modifierStepCollection = mongo.db("pos").collection("ModifierStep");

        try {
            let modifierOid = {}
            // @ts-ignore
            modifierOid['$oid'] = modifierId;

            let stepOid = {}
            // @ts-ignore
            stepOid['$oid'] = stepId;

            const deletedModifier = await modifierCollection.deleteOne({_id: modifierOid});

            if (deletedModifier.deletedCount === 1) {
                console.log(`Successfully deleted modifier with _id: ${modifierId}`);

                // remove the reference of modifier in modifierStep
                const updatedStep = await modifierStepCollection.updateOne(
                    {_id: stepOid},
                    {$pull: {modifiers: modifierOid}}
                );
                console.log(`Successfully removed modifier with _id: ${modifierId} from modifierStep with _id: ${stepId}`);
            } else {
                console.log(`No modifiers were deleted`);
            }
        } catch (err) {
            setError(err);
            console.log(err);
        }
        setLoading(false);
    };
    const addStep = async (modifierGroupId: string, stepType: string) => {
        if(!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const modifierStepCollection = mongo.db("pos").collection("ModifierStep");
        const modifierGroupCollection = mongo.db("pos").collection("ModifierGroup");

        setLoading(true);
        setError(null);

        try {
            // Get the ModifierGroup
            const modifierGroup = await modifierGroupCollection.findOne({_id: {"$oid": modifierGroupId}});

            if (!modifierGroup) {
                throw new Error('Modifier group not found with id ' + modifierGroupId);
            }

            console.log(modifierGroup)

            const stepNumbers = [];
            for (const stepId of modifierGroup.steps) {
                //@ts-ignore

                let st = String(stepId)
                const step = await modifierStepCollection.findOne({_id: { "$oid" : st }});
                if (step && step.step) {
                    stepNumbers.push(parseInt(step.step));
                }
            }

            let latestStepNumber = Math.max(...stepNumbers) + 1;
            if(latestStepNumber <= 0){
                latestStepNumber = 1;
            }


            const stepData = {
                _id: { $oid: new BSON.ObjectId().toString() },
                allowedChoices: 1,
                // @ts-ignore
                modifiers: [],
                owner_id: user.id,
                step: latestStepNumber,
                type: stepType,
                siteId: app.currentUser.customData.currentSite.toString()
            };

            // Insert the new step into the ModifierStep collection
            await modifierStepCollection.insertOne(stepData);

            // Push the new step's _id to the ModifierGroup's steps array
            await modifierGroupCollection.updateOne(
                { _id: {"$oid": modifierGroupId} },
                { $push: { steps: { $oid: stepData._id.$oid } } }
            );

        } catch (err) {
            console.error(err);
            setError(err);
        }

        setLoading(false);
    };

    const addModifierGroup = async (modifierGroupData: any) => {
        setLoading(true);
        setError(null);

        if(!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const modifierGroupCollection = mongo.db("pos").collection("ModifierGroup");
        const modifierStepCollection = mongo.db("pos").collection("ModifierStep");

        try {

            let newGroup = {
                owner_id: String,
                name: String,
                _id: {
                    $oid: String
                },
                siteId: ''

            };

            newGroup.owner_id = user.id;
            newGroup.name = modifierGroupData.name;
            //@ts-ignore
            newGroup._id.$oid = new BSON.ObjectId().toString();
            newGroup.siteId = app.currentUser.customData.currentSite.toString();

            const newModifierGroup = await modifierGroupCollection.insertOne(newGroup);

            // Define step data
            const stepData = {
                owner_id: user.id,
                _id: {
                    $oid: new BSON.ObjectId().toString()
                },
                allowedChoices: 1,
                step: 1,
                type: modifierGroupData.type,
                //@ts-ignore
                modifiers: [],
                siteId: app.currentUser.customData.currentSite.toString()
            };

            await modifierStepCollection.insertOne(stepData);


            await modifierGroupCollection.updateOne(
                {_id: { $oid: newModifierGroup.insertedId.toString() } },
                { $push: { steps: { $oid: stepData._id.$oid } } }
            );

            return newModifierGroup.insertedId.toString();
        } catch (err) {
            console.error(err);
            setError(err);
        }

        setLoading(false);
    };


    const deleteStep = async (stepId: string, modifierGroupId: string) => {
        if(!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const modifierStepCollection = mongo.db("pos").collection("ModifierStep");
        const modifierGroupCollection = mongo.db("pos").collection("ModifierGroup");

        setLoading(true);
        setError(null);

        try {
            // Get the ModifierGroup

            await modifierStepCollection.deleteOne( {_id: {
                $oid: stepId
            }});


        } catch (err) {
            console.error(err);
            setError(err);
        }

        setLoading(false);
    };

    return {
        addModifier,
        deleteModifier,
        addStep,
        addModifierGroup,
        setAllowedChoices,
        deleteStep,
        loading,
        error,
    };
};

export default useManageModifierGroups;
