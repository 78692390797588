import React, {useEffect, useState} from 'react';
// Chakra imports
import {
    Box,
    Flex,
    Icon,
    Text,
    useColorModeValue,
    SimpleGrid,
    Grid,
    Button,
    useDisclosure,
    HStack,
    VStack,
    IconButton,
    ModalContent,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalBody, ModalFooter, Circle, Spacer
} from '@chakra-ui/react';

// Custom components
import Card from "../../../../components/card/Card";

// Assets
import {VSeparator} from "../../../../components/separator/Separator";
import useGetModifierGroupById from "../../../../api/productLibrary/getModifierGroupById";
import useGetOrderModes from "../../../../api/productLibrary/getOrderModes";
import useGetTaxRates from "../../../../api/productLibrary/getTaxRates";
import PaddedFlex from "../../../../components/PaddedFlex";
import {MdKeyboardArrowDown} from "react-icons/md";
import {useNavigate, useParams} from "react-router-dom";
import useManageModifierGroups from "../../../../api/productLibrary/manageModifierGroups";
import ProductModifierGroupCard from "./components/ProductModifierGroupCard";
import InstructionModifierGroupCard from "./components/InstructionModifierGroupCard";
import {AddIcon} from "@chakra-ui/icons";

interface Props {
    setTitle?: any;
}

export default function ModifierGroup(propsIn: Props) {

    const {modifierGroupId} = useParams();

    const [{data, isLoading, isError, refetch}] = useGetModifierGroupById(modifierGroupId);
    const [{data: orderModes}] = useGetOrderModes();
    const [{data: taxRates}] = useGetTaxRates();

    const {addStep} = useManageModifierGroups();

    let padding = {sm: '125px', lg: '75px'};

    let navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const addProductStep = async function () {
        await addStep(modifierGroupId, "PRODUCT")
        await refetch();
        onClose();
    }

    const addInstructionStep = async function () {
        await addStep(modifierGroupId, "INSTRUCTION")
        await refetch();
        onClose();
    }

    const exit = async function () {
        navigate('/admin/library/modifiergrouplist');
    }

    useEffect(() => {
        if (!isLoading) {
            // @ts-ignore
            propsIn.setTitle(data?.name + " Modifier Group");
        }
    }, [isLoading]);

    // Chakra Color Mode
    return (
        <PaddedFlex direction='column' pt={padding}>
            <Grid
                pt={{base: '130px', md: '10px', xl: '10px'}}
                gridTemplateColumns={{md: '2.15fr 1fr', xl: '2.95fr 1fr'}}
                display={{base: 'block', lg: 'grid'}}>
                <Flex gridArea='1 / 1 / 2 / 2' display={{base: 'block', lg: 'flex'}}>

                    <SimpleGrid columns={1} gap='20px'>
                        <Box>
                            <Flex direction="row" justify="space-between" pl={7}>
                                <Text fontSize={'28px'} fontWeight={700}>{data?.name}</Text>
                                <Button colorScheme="blue" onClick={() => onOpen()} mb={3}>Add New
                                    Step</Button>
                            </Flex>
                        </Box>
                        <Box pl={6}>
                        {data && data.steps &&
                            data.steps.map((step: {
                                    name: string;
                                    modifiers: [];
                                    step: string;
                                    type: string;
                                    allowedChoices: number,
                                    _id: string
                                }, index: React.Key) =>

                                    <React.Fragment key={step._id}>
                                        {step.type === "INSTRUCTION" &&

                                            <InstructionModifierGroupCard
                                                key={index}
                                                step={parseInt(step.step)}
                                                stepType={step.type}
                                                modifierItems={step.modifiers}
                                                allowedChoices={step.allowedChoices}
                                                modifierStep={step._id}
                                                modifierGroupId={data._id}
                                                refetch={refetch}/>

                                        }

                                        {step.type === "PRODUCT" &&

                                            <ProductModifierGroupCard
                                                key={index}
                                                step={parseInt(step.step)}
                                                stepType={step.type}
                                                modifierItems={step.modifiers}
                                                allowedChoices={step.allowedChoices}
                                                modifierStep={step._id}
                                                modifierGroupId={data._id}
                                                orderModes={orderModes}
                                                taxRates={taxRates}
                                                refetch={refetch}/>

                                        }


                                        {index < data.steps.length - 1 && (
                                            <Flex direction="column" alignItems="center">
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                mx="2"
                                                height="55px"
                                                borderLeftColor="black"
                                                borderLeftStyle="dashed"
                                                borderLeftWidth="4px"
                                            >
                                                <Box
                                                    position="relative"
                                                    bottom="-50%"
                                                    left="-8px"
                                                    backgroundColor="black"
                                                    width="12px"
                                                    height="12px"
                                                    transform="rotate(45deg)"
                                                />
                                            </Box>
                                            </Flex>
                                        )}

                                    </React.Fragment>


                            )
                        }

                            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>New Step</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <VStack spacing={4} align="stretch">
                                            <Button
                                                as={Box}
                                                w="100%"
                                                p={4}
                                                textAlign="left"
                                                bg="#E5EDFB"
                                                borderRadius="md"
                                                _hover={{ bg: "#d0e0f8" }}
                                                height="auto"
                                                whiteSpace="normal"
                                                onClick={() => addProductStep()}
                                            >
                                                <HStack justifyContent="space-between" alignItems="flex-start">
                                                    <VStack align="flex-start" spacing={1} flex="1">
                                                        <Text fontSize="lg" fontWeight="bold">
                                                            Add Product Step
                                                        </Text>
                                                        <Text fontSize="md" my={2} fontWeight="300">
                                                            Introduce an additional chargeable product step.
                                                        </Text>
                                                        <Text fontSize="sm" fontWeight="300">
                                                            Add supplementary products, like side dishes or sauces, that customers can opt for, increasing versatility and potential revenue per order.
                                                        </Text>
                                                    </VStack>
                                                    <Circle
                                                        size="25px"
                                                        bg="black"
                                                        color="white"
                                                        flexShrink={0}
                                                    >
                                                        <AddIcon />
                                                    </Circle>
                                                </HStack>
                                            </Button>
                                            <Button
                                                as={Box}
                                                w="100%"
                                                p={4}
                                                textAlign="left"
                                                bg="#FAEBDD"
                                                borderRadius="md"
                                                _hover={{ bg: "#f5dbc7" }}
                                                height="auto"
                                                whiteSpace="normal"
                                                onClick={() => addInstructionStep()}
                                            >
                                                <HStack justifyContent="space-between" alignItems="flex-start">
                                                    <VStack align="flex-start" spacing={1} flex="1">
                                                        <Text fontSize="lg" fontWeight="bold" color="#C05621">
                                                            Add Instruction Step
                                                        </Text>
                                                        <Text fontSize="md" my={2} fontWeight="300">
                                                            Add a non-billable instruction step to your flow.
                                                        </Text>
                                                        <Text fontSize="sm" fontWeight="300">
                                                            This allows you to include specific preparation or customization details, such as cooking preferences for steak, without affecting the product's price.
                                                        </Text>
                                                    </VStack>
                                                    <Circle
                                                        size="25px"
                                                        bg="#995200"
                                                        color="#FBF1E5"
                                                        flexShrink={0}
                                                    >
                                                        <AddIcon />
                                                    </Circle>
                                                </HStack>
                                            </Button>
                                        </VStack>
                                    </ModalBody>

                                    <ModalFooter>
                                        <Button colorScheme="blue" onClick={onClose}>
                                            Close
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>

                        </Box>

                        <Flex width="100%" justifyContent="flex-end" maxW={"875px"} minW={"875px"}>
                            <Button colorScheme="green" onClick={() => exit()}>
                                Save and exit
                            </Button>
                        </Flex>
                    </SimpleGrid>
                    <VSeparator mx='30px' h='100%'/>
                </Flex>

            </Grid>
        </PaddedFlex>
    );
}
