// components/table.tsx
import React from 'react';
import { Box } from '@chakra-ui/react';
import { useDrag } from 'react-dnd';
import { TableShape } from './types';

interface TableProps {
    id: string;
    positionX: number;
    positionY: number;
    shape: TableShape;
    tableNumber: string;
    moveTable: (id: string, positionX: number, positionY: number) => void;
}

const getShapeStyle = (shape: TableShape): React.CSSProperties => {
    const baseStyle: React.CSSProperties = {
        position: 'absolute',
        border: '1px solid white',
        borderRadius: '10px',
        backgroundColor: '#01011E',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '12px'
    };

    switch (shape) {
        case TableShape.SQUARE:
            return { ...baseStyle, width: '60px', height: '60px' };
        case TableShape.CIRCLE:
            return { ...baseStyle, width: '60px', height: '60px', borderRadius: '50%' };
        case TableShape.DIAMOND:
            return {
                ...baseStyle,
                width: '60px',
                height: '60px',
                transform: 'rotate(45deg)',
            };
        case TableShape.RECTANGLE:
            return { ...baseStyle, width: '90px', height: '60px' };
        default:
            return baseStyle;
    }
};

const Table: React.FC<TableProps> = ({ id, positionX, positionY, shape, tableNumber }) => {
    const [{ isDragging }, drag] = useDrag({
        type: 'TABLE',
        item: { id, positionX, positionY, shape, tableNumber },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const style: React.CSSProperties = {
        ...getShapeStyle(shape),
        left: positionX,
        top: positionY,
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
    };

    const textStyle: React.CSSProperties = shape === TableShape.DIAMOND
        ? { transform: 'rotate(-45deg)' }
        : {};

    return (
        <Box
            ref={drag}
            style={style}
        >
            <Box style={textStyle}>
                Table {tableNumber}
            </Box>
        </Box>
    );
};

export default Table;
