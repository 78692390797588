import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {gql, useLazyQuery} from "@apollo/client";
import {posClient} from "../apolloClients/posClient";
import {useApp} from "@realm/react";

const GET_TERMINALS = gql`
    query Query($apiKey: String!) {
        getDojoTerminals(apiKey: $apiKey) {
            id
            properties {
                tid
            }
            status
        }
    }
`;

const useManageDojoSettings = () => {
    const app = useApp();
    const {token} = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    let mongo: { db: (arg0: string) => { (): any; new(): any; collection: { (arg0: string): any; new(): any; }; }; };

    const {user} = useContext(UserContext);

    const updateUser = () => {
        if (user) {
            mongo = user.mongoClient("mongodb-atlas");
        }
    };

    updateUser();


    const toObjectId = (idString: string): {} => {
        return {'$oid': idString};
    };

    const setDojoApiKey = async (apiKey: string) => {

        if (!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const siteCollection = mongo.db("posAccounts").collection("Site");
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        let siteSettingId;
        const currentSiteId = app.currentUser.customData.currentSite.toString();


        const site = await siteCollection.findOne({_id: toObjectId(currentSiteId)});


        siteSettingId = site.setting;

        try {
            await siteSettingCollection.updateOne(
                {_id: {$oid: siteSettingId.toString()}},
                {$set: {'dojoSettings.apiKey': apiKey}}
            );
        } catch (err) {
            console.error(err);

        }
    };

    const getDojoApiKey = async () => {

        if (!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const siteCollection = mongo.db("posAccounts").collection("Site");
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        let siteSettingId;
        const currentSiteId = app.currentUser.customData.currentSite.toString();
        const site = await siteCollection.findOne({_id: toObjectId(currentSiteId)});

        siteSettingId = site.setting;

        try {
            const siteSetting = await siteSettingCollection.findOne({_id: {$oid: siteSettingId.toString()}});
            return siteSetting?.dojoSettings?.apiKey || "";
        } catch (err) {
            console.error(err);

        }

    };


    const executeGetTerminalsAndSaveToken = async (apiKey: string) => {
        try {
        const {data, loading, error} = await posClient.query({
            query: GET_TERMINALS,
            variables: {apiKey},
            context: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            },
        });

        if (!loading && !error) {
           setDojoApiKey(apiKey);
        } else {
            setError(error);
        }

        return data.getDojoTerminals;

        } catch (error) {
            throw error;
        }

    };

    const setDojoTerminalMap = async (terminal: string, dojoTid: string) => {

        if (!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const siteCollection = mongo.db("posAccounts").collection("Site");
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        let siteSettingId;
        const currentSiteId = app.currentUser.customData.currentSite.toString();

        const site = await siteCollection.findOne({_id: toObjectId(currentSiteId)});

        siteSettingId = site.setting;

        try {

            let updatedResult = await siteSettingCollection.updateOne(
                {_id: {$oid: siteSettingId.toString()}, "dojoSettings.terminalMap.terminal": toObjectId(terminal)},
                {$set: {"dojoSettings.terminalMap.$.dojoTid": dojoTid}}
            );

            if (updatedResult.matchedCount === 0) {
                await siteSettingCollection.updateOne(
                    {_id: {$oid: siteSettingId.toString()}},
                    {$push: {"dojoSettings.terminalMap": {terminal:  toObjectId(terminal), dojoTid: dojoTid}}}
                );
            }
        } catch (err) {
            console.error(err);
        }
    };

    const setDojoTerminalNames = async (dojoTid: string, name: string) => {

        if (!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const siteCollection = mongo.db("posAccounts").collection("Site");
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        let siteSettingId;
        const currentSiteId = app.currentUser.customData.currentSite.toString();

        const site = await siteCollection.findOne({_id: toObjectId(currentSiteId)});

        siteSettingId = site.setting;

        try {
            let updatedResult = await siteSettingCollection.updateOne(
                {_id: {$oid: siteSettingId.toString()}, "dojoSettings.terminalName.dojoTid": dojoTid},
                {$set: {"dojoSettings.terminalName.$.name": name}}
            );

            if (updatedResult.matchedCount === 0) {
                await siteSettingCollection.updateOne(
                    {_id: {$oid: siteSettingId.toString()}},
                    {$push: {"dojoSettings.terminalName": {dojoTid: dojoTid, name: name}}}
                );
            }
        } catch (err) {
            console.error(err);
        }
    };

    const getDojoTerminalNames = async () => {
        if (!user) {
            setTimeout(updateUser, 2000);
            return [];
        }

        const siteCollection = mongo.db("posAccounts").collection("Site");
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        let siteSettingId;
        const currentSiteId = app.currentUser.customData.currentSite.toString();
        const site = await siteCollection.findOne({ _id: toObjectId(currentSiteId) });

        siteSettingId = site.setting;

        try {
            const siteSetting = await siteSettingCollection.findOne({ _id: { $oid: siteSettingId.toString() } });
            return siteSetting?.dojoSettings?.terminalName || [];
        } catch (err) {
            console.error(err);
            return [];
        }
    };

    const getDojoTerminalMappings = async () => {
        if (!user) {
            setTimeout(updateUser, 2000);
            return [];
        }

        const siteCollection = mongo.db("posAccounts").collection("Site");
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        let siteSettingId;
        const currentSiteId = app.currentUser.customData.currentSite.toString();
        const site = await siteCollection.findOne({ _id: toObjectId(currentSiteId) });

        siteSettingId = site.setting;

        try {
            const siteSetting = await siteSettingCollection.findOne({ _id: { $oid: siteSettingId.toString() } });
            const terminalMappings = siteSetting?.dojoSettings?.terminalMap || [];

            // Convert ObjectIds to strings
            const mappingsWithStrings = terminalMappings.map((mapping: { terminal: any, dojoTid: string }) => ({
                terminal: mapping.terminal.toString(),
                dojoTid: mapping.dojoTid,
            }));

            return mappingsWithStrings;
        } catch (err) {
            console.error(err);
            return [];
        }
    };

    return {
        executeGetTerminalsAndSaveToken,
        getDojoApiKey,
        setDojoTerminalMap,
        setDojoTerminalNames,
        getDojoTerminalNames,
        getDojoTerminalMappings,
        loading,
        error,
    };
};

export default useManageDojoSettings;
