import { Flex, Spinner, Text } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { DndProvider } from 'react-dnd-multi-backend';
import useGetTableAreas from "../../../api/tables/getTableAreas";
import TableAreaList from "./components/tableAreaList";
import TableAreaEditor from "./components/tableAreaEditor";
import { ObjectId } from "bson";
import { useAddTableArea } from "../../../api/tables/useAddTableArea";

interface TableArea {
    _id: ObjectId;
    name: string;
    siteId: string;
}

export default function Tables() {
    const { tableAreas, loading, error } = useGetTableAreas();
    const [selectedTableAreaId, setSelectedTableAreaId] = useState<string | null>(null);
    const { addTableArea } = useAddTableArea();

    useEffect(() => {
        if (tableAreas.length > 0) {
            setSelectedTableAreaId(tableAreas[0]._id.toString());
        }
    }, [tableAreas]);

    useEffect(() => {
        console.log("TableAreas", tableAreas);
    }, [tableAreas]);

    const handleSelectTableArea = (id: string) => {
        setSelectedTableAreaId(id);
    };

    const handleAddNew = async (name: string) => {
        const newTableArea = await addTableArea(name);
        if (newTableArea) {
            tableAreas.push(newTableArea);
            setSelectedTableAreaId(newTableArea._id.toString());  // Optionally select the newly added area
        }
    };

    const selectedTableArea = tableAreas.find(area => area._id.toString() === selectedTableAreaId);

    return (
        <DndProvider options={HTML5toTouch}>
            <Flex direction="column" pt={{ sm: '125px', lg: '75px' }} height="100vh">
                {loading && <Spinner />}
                {error && <div>Error: {error.message}</div>}
                <Flex w="100%" h="100%">
                    <Flex direction="column" width="300px" p={4}>
                        <TableAreaList
                            tableAreas={tableAreas}
                            selectedTableAreaId={selectedTableAreaId}
                            onSelect={handleSelectTableArea}
                            onAddNew={handleAddNew}
                        />
                    </Flex>
                    <Flex align="flex-start" justify="flex-start" flex="1">
                        {selectedTableArea && (
                            <TableAreaEditor
                                selectedTableArea={selectedTableArea || null}
                            />
                        )}
                        {!selectedTableArea && (
                            <Text>Create or select a table area</Text>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </DndProvider>
    );
}
