import MiniStatistics from "../../../../../components/card/MiniStatistics";
import IconBox from "../../../../../components/icons/IconBox";
import {Avatar, Flex, FormLabel, Icon, Select, SimpleGrid, Spinner, useColorModeValue} from "@chakra-ui/react";
import {MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy} from "react-icons/md";
import Usa from "../../../../../assets/img/dashboards/usa.png";
import React from "react";
import useGetGrossSales from "../../../../../api/reports/getTodaysGrossSales";
import { formatCurrency } from "helpers/accountCurrency";
import useGetWeekGrossSales from "../../../../../api/reports/getThisWeeksGrossSales";
import useGetTodaysTransactions from "../../../../../api/reports/getTodaysTransactions";

export default function StatsBar() {
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

    const [{data, isLoading, isError}] = useGetGrossSales()
    const [{data: weekData, isLoading :weekIsLoading, isError: weekIsError, }] = useGetWeekGrossSales()
    const [{data: daysTransactions}] = useGetTodaysTransactions()

    return (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }} gap='20px' mb='20px'>
            <MiniStatistics
                name='Gross Sales Today'
                value={
                    formatCurrency(daysTransactions?.todayGross)
                }
                growth={formatCurrency(Math.abs(daysTransactions?.yesterdayGross))}
                growthValue = {daysTransactions?.todayGross- daysTransactions?.yesterdayGross}
                isLoading={isLoading}
                growthType='Yesterday'
            />
            <MiniStatistics
                name='Gross Sales This Week'
                value={
                    formatCurrency(daysTransactions?.thisWeekGross)
                }
                growth={formatCurrency(Math.abs(daysTransactions?.lastWeekGross))}
                growthValue = {daysTransactions?.thisWeekGross- daysTransactions?.lastWeekGross}

                isLoading={isLoading}
                growthType='Last Week'
            />
            <MiniStatistics

                name='Transactions Today'
                value={daysTransactions.today}
                growth={daysTransactions.yesterday}
                growthValue = {daysTransactions.today - daysTransactions.yesterday}
                growthType='Yesterday'
            />

            <MiniStatistics

                name='Average Transaction Value'
                value={ formatCurrency((daysTransactions.todayGross / daysTransactions.today).toFixed(0))}
                growth={ formatCurrency((daysTransactions.yesterdayGross / daysTransactions.yesterday).toFixed(0))}
                growthValue = {(daysTransactions.todayGross / daysTransactions.today) - (daysTransactions.yesterdayGross / daysTransactions.yesterday)}
            />
        </SimpleGrid>
    )
}
