// @ts-nocheck
// Chakra imports
import {
    Avatar,
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    Icon,
    Input,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Switch,
    Radio,
    RadioGroup,
    HStack,
    PinInput,
    PinInputField,
    Select,
    FormErrorMessage,
    useColorModeValue
} from "@chakra-ui/react";
// Custom components
import React, {useContext, useEffect, useRef, useState} from "react";
import {BsCircleFill} from "react-icons/bs";

import { Controller } from 'react-hook-form';

import {GiFoodTruck, GiCoffeeCup, GiCoffeePot} from "react-icons/gi";
import {RiBeerFill, RiRestaurant2Fill} from "react-icons/ri";
import {CiShop} from "react-icons/ci";

import GooglePlacesAutocomplete, {geocodeByPlaceId} from "react-google-places-autocomplete";
import sanityClient from "sanity";
import {useForm} from "react-hook-form";
import {UserContext} from "../../contexts/UserContext";
import {useNavigate} from "react-router-dom";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";

const options = {
    componentRestrictions: {country: "gb"}
};

function Wizard() {

    const { register, handleSubmit,control, formState: { errors }, watch, getValues, setError, clearErrors, setValue } = useForm();

    const textColor = useColorModeValue("gray.700", "white");
    const bgPrevButton = useColorModeValue("gray.100", "gray.100");
    const iconColor = useColorModeValue("gray.300", "gray.700");
    const [activeBullets, setActiveBullets] = useState({
        about: true,
        account: false,
        address: false
    });


    const aboutTab = useRef();
    const accountTab = useRef();
    const addressTab = useRef();

    const [selectedOption, setSelectedOption] = useState('other');
    const [businessAddress, setBusinessAddress] = useState(null);
    const [businessAddressError, setBusinessAddressError] = useState(false);

    const [wizardData, setWizardData] = useState(null);

    const { user, refreshUserData } = useContext(UserContext);

    const [ submitting, setSubmitting ] = useState(false);

    const { logout } = useKindeAuth();

    const isVatRegistered = watch('vat-registered');

    const navigate = useNavigate();


    const onSubmit = async (data) => {
        let place = await geocodeByPlaceId(businessAddress.value.place_id)
        handleAddress(place)
        setSubmitting(true)
        let formData = getValues()
        formData.businessType = selectedOption;
        await user.callFunction("wizardRegistration", formData);
        sessionStorage.setItem('wizardComplete', JSON.stringify(true));
        const retryFetchUserData = async (attempt = 1) => {
            await refreshUserData();

            if (user.customData?.currentSite) {

                if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.reactNativeHandler) {
                    window.webkit.messageHandlers.reactNativeHandler.postMessage({ action: 'wizardComplete' });
                }

                navigate('/admin/dashboard');
            } else {
                const delay = Math.pow(3, attempt) * 1000;
                if (attempt <= 3) {
                    setTimeout(() => retryFetchUserData(attempt + 1), delay);
                } else {

                    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.reactNativeHandler) {
                        window.webkit.messageHandlers.reactNativeHandler.postMessage({ action: 'wizardComplete' });
                    }

                    window.location.reload();
                }
            }
        };

        setTimeout(() => retryFetchUserData(), 1000);
    }

    const addressComponentMap = {
        "street_number": "streetNumber",
        "route": "street",
        "postal_town": "town",
        "postal_code": "postCode",
        "country": "country",
    };

    function handleAddress(addressData) {
        const outputAddress = extractAddressComponents(addressData);
        for(const key in outputAddress){
            setValue(addressComponentMap[key], outputAddress[key]);
        }
    }

    function extractAddressComponents(addressData) {
        const outputAddress = {};
        const requiredComponents = ["country", "postal_code", "postal_town", "route", "street_number"];

        addressData[0]["address_components"].forEach(component => {
            const componentType = component["types"][0];
            if (requiredComponents.includes(componentType)) {
                outputAddress[componentType] = component["long_name"];
            }
        });
        return outputAddress;
    }

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "wizard"]{
                title,
                subHeading,
                stepOneTitle,
                stepOneSubHeading,
                 }`
            )
            .then((data) => setWizardData(data[0]))
            .catch(console.error);
    }, []);

    if (user === null) {
        return <div>Loading...</div>;
    }

    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.reactNativeHandler) {
        window.webkit.messageHandlers.reactNativeHandler.postMessage({ action: 'wizardStarted' });
    }

    // @ts-ignore
    return (
        <Flex
            direction="column"
            minH="100vh"
            align="center"
            pt={{sm: "125px", lg: "75px"}}
        >
            <Flex
                direction="column"
                textAlign="center"
                mb={{sm: "25px", md: "45px"}}
            >
                <Text
                    color={textColor}
                    fontSize={{sm: "2xl", md: "3xl", lg: "4xl"}}
                    fontWeight="bold"
                    mb="8px"
                >
                    {wizardData?.title ? wizardData?.title : "Welcome to Seamless"}
                </Text>
                <Text
                    color="gray.400"
                    fontWeight="normal"
                    fontSize={{sm: "sm", md: "lg"}}
                >
                    {wizardData?.subHeading ? wizardData?.subHeading : "We need some information to be able to configure your account."}
                </Text>


            </Flex>
            <Tabs variant="unstyled" mt="24px" display="flex" flexDirection="column">
                <TabList
                    display="flex"
                    align="center"
                    alignSelf="center"
                    justifySelf="center"
                >
                    <Tab
                        ref={aboutTab}
                        _focus="none"
                        w={{sm: "120px", md: "250px", lg: "300px"}}
                        onClick={() =>
                            setActiveBullets({
                                about: true,
                                account: false,
                                address: false
                            })
                        }
                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                            _before={{
                                content: "''",
                                width: {sm: "120px", md: "250px", lg: "300px"},
                                height: "3px",
                                bg: activeBullets.account ? textColor : "gray.200",
                                left: {sm: "12px", md: "26px"},
                                top: {sm: activeBullets.about ? "6px" : "4px", md: null},
                                position: "absolute",
                                bottom: activeBullets.about ? "40px" : "38px",
                                zIndex: -1,
                                transition: "all .3s ease"
                            }}
                        >
                            <Icon
                                as={BsCircleFill}
                                color={activeBullets.about ? textColor : "gray.300"}
                                w={activeBullets.about ? "16px" : "12px"}
                                h={activeBullets.about ? "16px" : "12px"}
                                mb="8px"
                            />
                            <Text
                                color={activeBullets.about ? {textColor} : "gray.300"}
                                fontWeight={activeBullets.about ? "bold" : "normal"}
                                display={{sm: "none", md: "block"}}
                                fontSize="sm"
                            >
                                About your business
                            </Text>
                        </Flex>
                    </Tab>
                    <Tab
                        ref={accountTab}
                        _focus="none"
                        w={{sm: "120px", md: "250px", lg: "300px"}}
                        onClick={() =>
                            setActiveBullets({
                                about: true,
                                account: true,
                                address: false
                            })
                        }
                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                            _before={{
                                content: "''",
                                width: {sm: "120px", md: "250px", lg: "300px"},
                                height: "3px",
                                bg: activeBullets.address ? textColor : "gray.200",
                                left: {sm: "12px", md: "28px"},
                                top: {sm: activeBullets.account ? "6px" : "4px", md: null},
                                position: "absolute",
                                bottom: activeBullets.account ? "40px" : "38px",
                                zIndex: -1,
                                transition: "all .3s ease"
                            }}
                        >
                            <Icon
                                as={BsCircleFill}
                                color={activeBullets.account ? textColor : "gray.300"}
                                w={activeBullets.account ? "16px" : "12px"}
                                h={activeBullets.account ? "16px" : "12px"}
                                mb="8px"
                            />
                            <Text
                                color={activeBullets.account ? {textColor} : "gray.300"}
                                fontWeight={activeBullets.account ? "bold" : "normal"}
                                transition="all .3s ease"
                                fontSize="sm"
                                _hover={{color: textColor}}
                                display={{sm: "none", md: "block"}}
                            >
                                Account
                            </Text>
                        </Flex>
                    </Tab>
                    <Tab
                        ref={addressTab}
                        _focus="none"
                        w={{sm: "120px", md: "250px", lg: "300px"}}
                        onClick={() =>
                            setActiveBullets({
                                about: true,
                                account: true,
                                address: true
                            })
                        }
                    >
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                            position="relative"
                            _before={{
                                content: "''",
                                width: {sm: "120px", md: "250px", lg: "300px"},
                                height: "3px",
                                // bg: activeBullets.profile ? textColor : "gray.200",
                                left: {sm: "12px", md: "32px"},
                                top: {sm: activeBullets.address ? "6px" : "4px", md: null},
                                position: "absolute",
                                bottom: activeBullets.address ? "40px" : "38px",
                                zIndex: -1,
                                transition: "all .3s ease"
                            }}
                        >
                            <Icon
                                as={BsCircleFill}
                                color={activeBullets.address ? textColor : "gray.300"}
                                w={activeBullets.address ? "16px" : "12px"}
                                h={activeBullets.address ? "16px" : "12px"}
                                mb="8px"
                            />
                            <Text
                                color={activeBullets.address ? {textColor} : "gray.300"}
                                fontWeight={activeBullets.address ? "bold" : "normal"}
                                transition="all .3s ease"
                                fontSize="sm"
                                _hover={{color: textColor}}
                                display={{sm: "none", md: "block"}}
                            >
                                Address
                            </Text>
                        </Flex>
                    </Tab>
                </TabList>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TabPanels mt="24px" maxW={{md: "90%", lg: "100%"}} mx="auto">
                        <TabPanel w={{sm: "330px", md: "700px", lg: "850px"}} mx="auto">
                            <Box>
                                <Flex mb="40px">
                                    <Flex
                                        direction="column"
                                        align="center"
                                        justify="center"
                                        textAlign="center"
                                        w="80%"
                                        mx="auto"
                                    >
                                        <Text
                                            color={textColor}
                                            fontSize="xl"
                                            fontWeight="bold"
                                            mb="4px"
                                        >
                                            {wizardData?.stepOneTitle}
                                        </Text>
                                        <Text color="gray.400" fontWeight="normal" fontSize="md">
                                            {wizardData?.stepOneSubHeading}

                                        </Text>

                                    </Flex>
                                </Flex>
                                <Box>
                                    <Flex direction="column" w="100%">
                                        <Flex
                                            direction={{sm: "column", md: "row"}}
                                            w="100%"
                                            mb="24px"
                                        >
                                            <Box
                                                position="relative"
                                                minW={{sm: "110px", xl: "150px"}}
                                                h={{sm: "110px", xl: "150px"}}
                                                mx={{sm: "auto", md: "40px", xl: "85px"}}
                                                mb={{sm: "25px"}}
                                            >
                                                <Avatar w="100%" h="100%" borderRadius="12px"/>
                                            </Box>
                                            <Stack direction="column" spacing="20px" w="100%">
                                                <FormControl isInvalid={errors?.businessName}>
                                                    <FormLabel
                                                        color={textColor}
                                                        fontSize="md"
                                                        fontWeight="bold"
                                                    >
                                                        Business Name
                                                    </FormLabel>
                                                    <Input
                                                        borderRadius="15px"
                                                        placeholder="eg. Bob's coffee shop"
                                                        fontSize="md"
                                                        {...register("businessName", { required: 'Business Name is required' })}
                                                    />
                                                    <Text color="gray.400" fontWeight="normal" fontSize="md" mt={2}>
                                                        We'll add this to your receipts and reports
                                                    </Text>
                                                    <FormErrorMessage>{errors.businessName && errors.businessName.message}</FormErrorMessage>
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel
                                                        color={textColor}
                                                        fontSize="md"
                                                        fontWeight="bold"
                                                    >
                                                        Business Country
                                                    </FormLabel>
                                                    <Input
                                                        borderRadius="15px"
                                                        value="United Kingdom"
                                                        fontSize="md"
                                                        isDisabled={true}
                                                    />
                                                    <Text color="gray.400" fontWeight="normal" fontSize="md" mt={2}>
                                                        We're only available in the UK for now. We'll use this to set
                                                        your
                                                        currency
                                                    </Text>
                                                </FormControl>
                                                <FormControl isInvalid={businessAddressError}>
                                                    <FormLabel
                                                        color={textColor}
                                                        fontSize="md"
                                                        fontWeight="bold"
                                                    >
                                                        Business Address
                                                    </FormLabel>

                                                    <GooglePlacesAutocomplete
                                                        apiKey="AIzaSyBuOc2_dl6zQJBF0wd-hT6e-W3QJpikRZs"
                                                        autocompletionRequest={options} debounce="500"
                                                        selectProps={{
                                                            value: businessAddress,
                                                            placeholder: "Start by typing the postcode",
                                                            onChange: setBusinessAddress,
                                                            type: "region"
                                                        }}
                                                    />
                                                    <FormErrorMessage>Please enter a business address</FormErrorMessage>
                                                </FormControl>
                                            </Stack>
                                        </Flex>
                                        <Button
                                            variant="no-hover"
                                            bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                                            alignSelf="flex-end"
                                            mt="24px"
                                            w={{sm: "75px", lg: "100px"}}
                                            h="35px"
                                            onClick={() => {
                                                const businessName = getValues("businessName");
                                                let isValid = true;

                                                if (!businessName) {
                                                    setError('businessName', {
                                                        type: 'manual',
                                                        message: 'Business Name is required'
                                                    });
                                                    isValid = false;
                                                } else {
                                                    clearErrors()
                                                }

                                                if (!businessAddress) {
                                                    setBusinessAddressError(true);
                                                    isValid = false;
                                                } else {
                                                    setBusinessAddressError(false); // Don't forget to clear the error when address is valid
                                                }

                                                if(isValid){
                                                    accountTab.current.click();
                                                }
                                            }}
                                        >
                                            <Text fontSize="xs" color="#fff" fontWeight="bold">
                                                NEXT
                                            </Text>
                                        </Button>
                                    </Flex>
                                </Box>
                            </Box>
                        </TabPanel>
                        <TabPanel w={{sm: "330px", md: "700px", lg: "850px"}} mx="auto">

                            <Flex mb="40px">
                                <Flex
                                    direction="column"
                                    align="center"
                                    justify="center"
                                    textAlign="center"
                                    w="80%"
                                    mx="auto"
                                >

                                    <Text
                                        color={textColor}
                                        fontSize="lg"
                                        fontWeight="bold"
                                        mb="4px"
                                    >
                                        How would you describe your business?
                                    </Text>
                                    <Text color="gray.400" fontWeight="normal" fontSize="md">
                                        We'll use this info to tailor the system settings to your needs
                                    </Text>
                                </Flex>
                            </Flex>
                            <Box>
                                <Flex direction="column" w="100%">

                                    <Stack
                                        direction={{sm: "column", md: "row"}}
                                        spacing={{sm: "20px", lg: "35px"}}
                                        alignSelf="center"
                                        justifySelf="center"
                                        mb="24px"
                                    >
                                        <RadioGroup onChange={setSelectedOption} value={selectedOption}>
                                            <Stack direction={{sm: "column", md: "row"}}
                                                   spacing={{sm: "20px", lg: "35px"}}>
                                                <Flex direction="column" align="center">
                                                    <FormLabel w="100px" h="100px" cursor="pointer" mb="16px">
                                                        <Flex
                                                            w="100%"
                                                            h="100%"
                                                            borderRadius="12px"
                                                            justify="center"
                                                            transition=".5s all ease"
                                                            border="1px solid lightgray"
                                                            align="center"
                                                            bg={selectedOption === 'foodTruck' ? "brand.300" : "#fff"}
                                                            _hover={{opacity: "0.8"}}
                                                        >
                                                            <Radio value="foodTruck" display="none"/>
                                                            <Icon
                                                                as={GiFoodTruck}
                                                                w="54px"
                                                                h="54px"
                                                                color={selectedOption === 'foodTruck' ? "#fff" : iconColor}
                                                            />
                                                        </Flex>
                                                    </FormLabel>
                                                    <Text color={textColor} fontWeight="bold" fontSize="md">
                                                        Food Truck
                                                    </Text>
                                                </Flex>

                                                <Flex direction="column" align="center">
                                                    <FormLabel w="100px" h="100px" cursor="pointer" mb="16px">
                                                        <Flex
                                                            w="100%"
                                                            h="100%"
                                                            borderRadius="12px"
                                                            justify="center"
                                                            transition=".5s all ease"
                                                            border="1px solid lightgray"
                                                            align="center"
                                                            bg={selectedOption === 'coffeeShop' ? "brand.300" : "#fff"}
                                                            _hover={{opacity: "0.8"}}
                                                        >
                                                            <Radio value="coffeeShop" display="none"/>
                                                            <Icon
                                                                as={GiCoffeeCup}
                                                                w="54px"
                                                                h="54px"
                                                                color={selectedOption === 'coffeeShop' ? "#fff" : iconColor}
                                                            />
                                                        </Flex>
                                                    </FormLabel>
                                                    <Text color={textColor} fontWeight="bold" fontSize="md">
                                                        Coffee Shop
                                                    </Text>
                                                </Flex>

                                                <Flex direction="column" align="center">
                                                    <FormLabel w="100px" h="100px" cursor="pointer" mb="16px">
                                                        <Flex
                                                            w="100%"
                                                            h="100%"
                                                            borderRadius="12px"
                                                            justify="center"
                                                            transition=".5s all ease"
                                                            border="1px solid lightgray"
                                                            align="center"
                                                            bg={selectedOption === 'cafe' ? "brand.300" : "#fff"}
                                                            _hover={{opacity: "0.8"}}
                                                        >
                                                            <Radio value="cafe" display="none"/>
                                                            <Icon
                                                                as={GiCoffeePot}
                                                                w="54px"
                                                                h="54px"
                                                                color={selectedOption === 'cafe' ? "#fff" : iconColor}
                                                            />
                                                        </Flex>
                                                    </FormLabel>
                                                    <Text color={textColor} fontWeight="bold" fontSize="md">
                                                        Cafe
                                                    </Text>
                                                </Flex>


                                                <Flex direction="column" align="center">
                                                    <FormLabel w="100px" h="100px" cursor="pointer" mb="16px">
                                                        <Flex
                                                            w="100%"
                                                            h="100%"
                                                            borderRadius="12px"
                                                            justify="center"
                                                            transition=".5s all ease"
                                                            border="1px solid lightgray"
                                                            align="center"
                                                            bg={selectedOption === 'bar' ? "brand.300" : "#fff"}
                                                            _hover={{opacity: "0.8"}}
                                                        >
                                                            <Radio value="bar" display="none"/>
                                                            <Icon
                                                                as={RiBeerFill}
                                                                w="54px"
                                                                h="54px"
                                                                color={selectedOption === 'bar' ? "#fff" : iconColor}
                                                            />
                                                        </Flex>
                                                    </FormLabel>
                                                    <Text color={textColor} fontWeight="bold" fontSize="md">
                                                        Pub/Bar
                                                    </Text>
                                                </Flex>


                                                <Flex direction="column" align="center">
                                                    <FormLabel w="100px" h="100px" cursor="pointer" mb="16px">
                                                        <Flex
                                                            w="100%"
                                                            h="100%"
                                                            borderRadius="12px"
                                                            justify="center"
                                                            transition=".5s all ease"
                                                            border="1px solid lightgray"
                                                            align="center"
                                                            bg={selectedOption === 'restaurant' ? "brand.300" : "#fff"}
                                                            _hover={{opacity: "0.8"}}
                                                        >
                                                            <Radio value="restaurant" display="none"/>
                                                            <Icon
                                                                as={RiRestaurant2Fill}
                                                                w="54px"
                                                                h="54px"
                                                                color={selectedOption === 'restaurant' ? "#fff" : iconColor}
                                                            />
                                                        </Flex>
                                                    </FormLabel>
                                                    <Text color={textColor} fontWeight="bold" fontSize="md">
                                                        Restaurant
                                                    </Text>
                                                </Flex>


                                                <Flex direction="column" align="center">
                                                    <FormLabel w="100px" h="100px" cursor="pointer" mb="16px">
                                                        <Flex
                                                            w="100%"
                                                            h="100%"
                                                            borderRadius="12px"
                                                            justify="center"
                                                            transition=".5s all ease"
                                                            border="1px solid lightgray"
                                                            align="center"
                                                            bg={selectedOption === 'other' ? "brand.300" : "#fff"}
                                                            _hover={{opacity: "0.8"}}
                                                        >
                                                            <Radio value="other" display="none"/>
                                                            <Icon
                                                                as={CiShop}
                                                                w="54px"
                                                                h="54px"
                                                                color={selectedOption === 'other' ? "#fff" : iconColor}
                                                            />
                                                        </Flex>
                                                    </FormLabel>
                                                    <Text color={textColor} fontWeight="bold" fontSize="md">
                                                        Other
                                                    </Text>
                                                </Flex>


                                            </Stack>
                                        </RadioGroup>

                                    </Stack>


                                </Flex>
                            </Box>

                            <Box>

                                <Flex
                                    direction="column"
                                    align="center"
                                    justify="center"
                                    textAlign="center"
                                    w="80%"
                                    mx="auto"
                                >
                                <FormControl isInvalid={errors?.rolloverHour} mb={10}>
                                    <FormLabel color="gray.800" fontSize="md" fontWeight="bold">
                                        Rollover Time
                                    </FormLabel>

                                    <Text color="gray.400" fontWeight="normal" fontSize="md" mt={2}>
                                        Rollover time is the specific hour you set to indicate the end of your business day, which may extend into the next calendar day, e.g. a pub may trade until 1am
                                    </Text>

                                    <Controller
                                        name="rolloverHour"
                                        control={control}
                                        defaultValue="0" // initial selected value
                                        rules={{ required: 'Rollover is required' }}
                                        render={({ field: { ref, ...rest } }) => (
                                            <Select {...rest} placeholder="Select Rollover" defaultValue="0" >
                                                <option value="0">00:00 (Midnight)</option>
                                                <option value="1">01:00</option>
                                                <option value="2">02:00</option>
                                                <option value="3">03:00</option>
                                                <option value="4">04:00</option>
                                                <option value="5">05:00</option>
                                                <option value="6">06:00</option>
                                                <option value="7">07:00</option>
                                            </Select>
                                        )}
                                    />
                                    <FormErrorMessage>
                                        {errors.rolloverHour && errors.rolloverHour.message}
                                    </FormErrorMessage>
                                </FormControl>
                                </Flex>

                                <Flex mb={"40px"}>

                                    <Flex
                                        direction="column"
                                        align="center"
                                        justify="center"
                                        textAlign="center"
                                        w="80%"
                                        mx="auto"
                                    >

                                        <FormControl display='flex' alignItems='center' mb={"10px"}>
                                            <FormLabel htmlFor='email-alerts' mb='0'>
                                                Is your business VAT registered?
                                            </FormLabel>
                                            <Switch size='lg' id='vat-registered' {...register('vat-registered')}/>
                                        </FormControl>
                                        {isVatRegistered && (
                                            <FormControl>
                                        <Input
                                            borderRadius="15px"
                                            placeholder="eg. GB123456789"
                                            fontSize="md"
                                            {...register("vatNumber")}
                                        />
                                        <Text color="gray.400" fontWeight="normal" fontSize="md" mt={2}>
                                            We'll add this to your receipts and create a 20% VAT rate
                                        </Text>
                                        </FormControl>
                                    )}

                                        <FormControl display='flex' alignItems='center' mb={"10px"} mt={5}>
                                            <FormLabel htmlFor='email-alerts' mb='0'>
                                                Do you offer take-away?
                                            </FormLabel>
                                            <Switch size='lg' id='takeaway' {...register("takeAway")} />
                                        </FormControl>

                                        <Text color="gray.400" fontWeight="normal" fontSize="md" mt={2}>
                                            We'll create Sit in and Take Out order modes
                                        </Text>

                                    </Flex>

                                </Flex>

                            </Box>

                            <Flex justify="space-between">
                                <Button
                                    variant="no-hover"
                                    bg={bgPrevButton}
                                    alignSelf="flex-end"
                                    mt="24px"
                                    w={{sm: "75px", lg: "100px"}}
                                    h="35px"
                                    onClick={() => aboutTab.current.click()}
                                >
                                    <Text fontSize="xs" color="gray.700" fontWeight="bold">
                                        PREV
                                    </Text>
                                </Button>
                                <Button
                                    variant="no-hover"
                                    bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                                    alignSelf="flex-end"
                                    mt="24px"
                                    w={{sm: "75px", lg: "100px"}}
                                    h="35px"
                                    onClick={() => addressTab.current.click()}
                                >
                                    <Text fontSize="xs" color="#fff" fontWeight="bold">
                                        NEXT
                                    </Text>
                                </Button>
                            </Flex>

                        </TabPanel>
                        <TabPanel w={{sm: "330px", md: "700px", lg: "850px"}} mx="auto">
                            <Box>
                                <Flex mb="40px">
                                    <Flex
                                        direction="column"
                                        align="center"
                                        justify="center"
                                        textAlign="center"
                                        w="80%"
                                        mx="auto"
                                    >
                                        <Text
                                            color={textColor}
                                            fontSize="lg"
                                            fontWeight="bold"
                                            mb="4px"
                                        >
                                            Finally we need to create a user so you can log in to the POS system.
                                        </Text>

                                    </Flex>
                                </Flex>
                                <Box>
                                    <Flex direction="column" w="100%">
                                        <Stack direction="column" spacing="20px">
                                            <FormControl isInvalid={errors.userPin}>
                                                <FormLabel
                                                    color={textColor}
                                                    fontWeight="bold"
                                                    fontSize="lg"
                                                >
                                                    Enter a memorable pin
                                                </FormLabel>
                                                <HStack>
                                                    <Controller
                                                        render={( {field} ) => (
                                                    <PinInput {...field} size='lg'>
                                                        <PinInputField/>
                                                        <PinInputField/>
                                                        <PinInputField/>
                                                        <PinInputField/>
                                                    </PinInput>
                                                        )}
                                                        control={control}
                                                        name="userPin"
                                                        rules={{
                                                            required: 'PIN is required',
                                                            pattern: {
                                                                value: /^\d{4}$/, // regular expression for exactly 4 digits
                                                                message: 'PIN must be 4 digits'
                                                            }
                                                        }}
                                                    />
                                                </HStack>
                                                <FormErrorMessage>{errors.userPin && errors.userPin.message}</FormErrorMessage>
                                                <Text color="gray.400" fontWeight="normal" fontSize="md" mt={2}>
                                                    You will need to use this pin to log in to the POS app
                                                </Text>
                                            </FormControl>
                                            <FormControl isInvalid={errors.userName}>
                                                <FormLabel
                                                    color={textColor}
                                                    fontWeight="bold"
                                                    fontSize="lg"
                                                >
                                                    What should we call you?
                                                </FormLabel>
                                                <Input
                                                    borderRadius="15px"
                                                    placeholder="eg. Bob"
                                                    fontSize="lg"
                                                    {...register("userName", { required: 'User name is required' })}
                                                />

                                                <FormErrorMessage>{errors.userName && errors.userName.message}</FormErrorMessage>
                                            </FormControl>
                                            <Grid
                                                templateColumns={{sm: "1fr 1fr", lg: "2fr 1fr 1fr"}}
                                                gap="30px"
                                            >

                                            </Grid>
                                        </Stack>
                                        <Flex justify="space-between">
                                            <Button
                                                variant="no-hover"
                                                bg={bgPrevButton}
                                                alignSelf="flex-end"
                                                mt="24px"
                                                w={{sm: "75px", lg: "100px"}}
                                                h="35px"
                                                onClick={() => accountTab.current.click()}
                                            >
                                                <Text fontSize="xs" color="gray.700" fontWeight="bold">
                                                    PREV
                                                </Text>
                                            </Button>
                                            <Button
                                                variant="no-hover"
                                                bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                                                alignSelf="flex-end"
                                                mt="24px"
                                                w={{sm: "125px", lg: "150px"}}
                                                h="55px"
                                                onClick={() => handleSubmit(onSubmit)()}
                                                isLoading={submitting}
                                            >
                                                <Text fontSize="xs" color="#fff" fontWeight="bold">
                                                    GET STARTED
                                                </Text>
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Box>
                            </Box>
                        </TabPanel>
                    </TabPanels>
                </form>
            </Tabs>

            <Flex alignItems='center'>
                <Stack
                    direction={"column"}
                    alignSelf="center"
                    justifySelf="center"
                    alignItems='center'
                    mt={20}
                >
                <Text>If you need to log out for any reason, click here. Your progress will not be saved</Text>
                    <Flex width={"20%"}>
                <Button onClick={() => logout()} colorScheme={"red"}>Log Out</Button>
                    </Flex>
                </Stack>
            </Flex>


        </Flex>
    );
}

export default Wizard;
