import {useEffect, useState} from 'react';

import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {ApolloClient, ApolloLink, gql, HttpLink, InMemoryCache} from "@apollo/client";
import {Credentials} from "realm";
import {useApp} from "@realm/react";
import {formatCurrency} from "../../helpers/accountCurrency"

const useGetProductSalesStats = (productId: string) => {
    const {getToken} = useKindeAuth();
    const app = useApp();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const client = new ApolloClient({
        uri: "https://eu-west-1.aws.realm.mongodb.com/api/client/v2.0/app/application-0-trznh/graphql",
        cache: new InMemoryCache(),
    });

    useEffect(() => {
        const getProductSalesStats = async (productId: string) => {

            setIsLoading(true);
            setIsError(false);

            let aT;

            if (app.currentUser === null) {
                try {
                    console.log("exchanging token");

                    const token = await getToken();
                    const user = await app.logIn(Credentials.jwt(token));
                    aT = user.accessToken;

                } catch (e) {

                }
            } else {
                console.log("user exists");
                aT = app.currentUser.accessToken;
            }

            console.log(aT);

            try {

                let response = await client
                    .query({
                        query: gql`query OrderItems($query: OrderItemQueryInput) {
                                      orderItems(query: $query) {
                                        price
                                      }
                                    }`,
                        context: {headers: {Authorization: "Bearer " + aT}},
                        variables: {
                            query: {
                                product: {
                                    _id: productId
                                }
                            }
                        },
                    })

                console.log("sales stats");
                console.log(response.data);

                let sum = (response.data.orderItems.reduce((accum: any, item: {
                    price: any;
                }) => accum + parseInt(item.price), 0));

                let avgCalc = (sum / response.data.orderItems.length).toFixed(0);

                let avg = formatCurrency(avgCalc)



                let ret: {} = {count: response.data.orderItems.length, total: formatCurrency(sum), avg};

                // @ts-ignore
                setData(ret);
                setIsLoading(false);
            } catch (e) {
                setIsError(true);
            } finally {
                setIsLoading(false);
            }
        };

        getProductSalesStats(productId);

    }, [getToken]);

    return [{data, isLoading, isError}] as const;
};

export default useGetProductSalesStats;
