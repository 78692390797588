import {useContext} from 'react';
import {UserContext} from '../../contexts/UserContext';
import {useQuery, gql} from '@apollo/client';
import {reportsClient} from '../apolloClients/reportsClient';

const GET_SALES_BY_DAY = gql`
    query Query($quickDate: String, $startDate: String, $endDate: String) {
        salesDataPerDay(quickDate: $quickDate, startDate: $startDate, endDate: $endDate) {
            salesData {
                date
                grossSales
                netSales
                transactions
                tax
            }
            totalGrossSales
            totalNetSales
        }
    }
`;

export const useGetSalesByDay = (quickDate : String = "this month", startDate : String = null, endDate : String = null) => {
    const {token} = useContext(UserContext);
    const shouldSkip = !token;

    const {data, loading: apolloLoading, error, refetch} = useQuery(GET_SALES_BY_DAY,
        {
            variables: {
                quickDate: quickDate,
                startDate: startDate,
                endDate: endDate
            },
            context: {
                headers: {
                    Authorization: "Bearer " + token,
                },
            },
            skip: shouldSkip,
            client: reportsClient,  // use the imported client
        });

    const isLoading = apolloLoading || shouldSkip;

    return [{
        data: data?.salesDataPerDay,
        isLoading,
        isError: !!error,
        refetch,
    }] as const;
};


