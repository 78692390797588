import {Box, Table, Thead, Tbody, Tr, Th, Td, Spinner, Flex, Text, Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter} from "@chakra-ui/react";
import {ReactChild, ReactFragment, ReactPortal, Key, useState, useRef} from "react";
import useGetTaxRates from "../../../../api/productLibrary/getTaxRates";
import AddTaxRateButton from "./AddTaxRate";
import {DeleteIcon} from "@chakra-ui/icons";
import useDeleteOneTaxRate from "../../../../api/productLibrary/deleteTaxRate";

export default function TaxRateTable() {
    const [{data, isLoading, refetch}] = useGetTaxRates();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const cancelRef = useRef();
    const [selectedRate, setSelectedRate] = useState(null);
    const {deleteTaxRate,  isLoading: isDeleteLoading, isError: isDeletError } = useDeleteOneTaxRate();
    const onDelete = async () => {
        setIsDialogOpen(false);
        try {
            const deleteResult = await deleteTaxRate(selectedRate._id);
            console.log(deleteResult);
            setSelectedRate(null);
            await refetch(); // If needed to refresh data such as reload list of tax rates
        } catch (e) {
            // Handle error
            console.error(e);
        }
    };

    const onCloseDialog = () => setIsDialogOpen(false);

    if (isLoading) {
        return <Spinner /* spinner settings */ />
    }

    return (
        <Box overflowX="auto" ml={'20px'} mr={'20px'}>
            <Flex align={{lg: 'center'}} justify={{base: 'space-between'}} w='100%' px='20px' mb='20px'>
                <Text fontSize='xl' fontWeight='600' lineHeight='100%'>Tax Rates</Text>
                <AddTaxRateButton onTaxRateCreated={refetch}/>
            </Flex>

            <Table width={'100%'} size="md" p={'40px'} variant='striped'>
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th isNumeric>Rate[%]</Th>
                        <Th>Edit</Th>
                        <Th>Delete</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {data != null ? data.map((rate: {
                        _id: string;
                        name: boolean | ReactChild | ReactFragment | ReactPortal;
                        rate: number;
                    }, i: Key) => (
                        <Tr key={i}>
                            <Td>{rate.name}</Td>
                            <Td isNumeric>{(rate.rate / 100).toFixed(2)}</Td>
                            <Td>Edit</Td>
                            <Td>
                                <Button onClick={() => {setSelectedRate(rate); setIsDialogOpen(true);}} leftIcon={<DeleteIcon />} variant="ghost">Delete</Button>
                            </Td>
                        </Tr>
                    )) : <Tr><Td>No Data</Td></Tr>}
                </Tbody>
            </Table>
            <>
                <AlertDialog
                    isOpen={isDialogOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onCloseDialog}
                >
                    <AlertDialogOverlay />
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Tax Rate
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to delete {selectedRate && selectedRate.name}? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseDialog}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={onDelete} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog></>
        </Box>
    );
}
