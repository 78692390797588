import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    Switch,
    Text,
    Textarea,
    Tooltip, Select, Flex
} from "@chakra-ui/react";
import React, {useState, useMemo, useEffect} from "react";
import useManageSiteSettings from "../../../../api/site/useManageSiteSettings";
import {InfoOutlineIcon} from "@chakra-ui/icons";

const getTimeOptions = () => {
    const options = [];
    for (let i = 0; i < 24; i += 1) {
        const hour = i < 10 ? `0${i}`: `${i}`;
        const localTime = `${hour}:00`;
        // Convert local HH:mm formatted time to the equivalent UTC time
        const date = new Date(`1970-01-01T${localTime}:00`);  // Local time
        const UTCTime = `${date.getUTCHours().toString().padStart(2, '0')}:00`;  // UTC time
        options.push({value: UTCTime, label: localTime});
    }
    return options;
};

export default function EmailReportSettings() {
    const {setEmailReportSettings, getEmailReportSettings} = useManageSiteSettings();
    const [defaultHour, setDefaultHour] = useState("00:00");
    const timeOptions = useMemo(getTimeOptions, []);

    useEffect(() => {
        const fetchData = async () => {
            const emailReportSettings = await getEmailReportSettings();
            if (emailReportSettings.time) {
                setDefaultHour(emailReportSettings.time);
            }
        };
        fetchData();
    }, []);

    const handleEmailReportChange = async (selected: boolean) => {
        //setLargeOrderNumber(selected);
        if (selected === false) {
            await setEmailReportSettings("");
        } else {
            await setEmailReportSettings(defaultHour);
        }
    };

    const handleTimeChange = async (value: string) => {
        setDefaultHour(value);
        await setEmailReportSettings(value);
    };

    return (
        <Box overflowX="auto" ml={'20px'} mr={'20px'}>
            <Flex align={{lg: 'center'}} justify={{base: 'space-between'}} w='100%' px='20px' mb='20px'>
                <Text fontSize='xl' fontWeight='600' lineHeight='100%'>Email Report Settings</Text>
            </Flex>
            <Table width={'100%'} size="md" p={'40px'} variant='striped'>
                <Tbody>
                    <Tr>
                        <Td>Send email report
                            <Tooltip label="Sends an email report for the previous days transactions at a time you choose" placement="right" ml="2">
                                <InfoOutlineIcon ml={1} />
                            </Tooltip>
                        </Td>
                        <Td><Switch id='manual-enable'  onChange={(e) => handleEmailReportChange(e.target.checked)}  /></Td>
                    </Tr>
                    <Tr>
                        <Td>Report Time
                        </Td>
                        <Td>
                            <Select placeholder="Select option" value={defaultHour} onChange={(e) => handleTimeChange(e.target.value)}>
                                {timeOptions.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </Select>
                            <Text fontSize={'xs'} mt={2}>Choose the time to send the report. The report will always be sent for the previous trading period, we suggest setting this to your rollover time.</Text>
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
        </Box>
    );
}
