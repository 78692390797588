import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
const colorPalette = ["#0165FF", "#00A698", "#19A43B", "#DC1932", "#892FC6", "#DB7500", "#DC188C", "#2F2F47"];

// Pulse animation
const pulse = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
`;

const SwatchContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

interface SwatchBoxProps {
    color: string;
    isSelected?: boolean;
}

const SwatchBox = styled.div<SwatchBoxProps>`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${props => props.color};
    cursor: pointer;
    position: relative;
    margin: 5px;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

    ${props => props.isSelected && css`
        animation: ${pulse} 0.6s ease-in-out forwards;
        box-shadow: 0 0 4px 2px ${props.color};
    `}
`;

const SelectedIndicator = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.9);
`;

interface CustomColorPickerProps {
    defaultColor?: string;
    onChangeComplete?: (color: any) => void;
}

const CustomColorPicker: React.FC<CustomColorPickerProps> = ({ defaultColor, onChangeComplete }) => {
    const [selected, setSelected] = useState(defaultColor || '');

    const handleColorClick = (color: string) => {
        setSelected(color);
        if(onChangeComplete) {
            onChangeComplete(color);
        }
    };

    return (
        <SwatchContainer>
            {colorPalette.map(color => (
                <SwatchBox
                    key={color}
                    color={color}
                    onClick={() => handleColorClick(color)}
                    isSelected={color === selected}
                >
                    {color === selected && <SelectedIndicator />}
                </SwatchBox>
            ))}
        </SwatchContainer>
    );
};

export default CustomColorPicker;
