import React, {useState} from "react";
import {
    Button,
    FormControl,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalCloseButton,
    useToast,
    FormLabel,
    Select,
} from "@chakra-ui/react";
import {AddIcon} from "@chakra-ui/icons";
import useCreateDiscount from "../../../../../api/productLibrary/createDiscount";

interface AddDiscountModalProps {
    refetch?: any;
    title?: string;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
}

export default function AddDiscountModal({ refetch, title, isOpen, onOpen, onClose }: AddDiscountModalProps) {
    const { addDiscount, loading, error } = useCreateDiscount();
    const toast = useToast();

    const [type, setType] = useState('percentage');
    const [value, setValue] = useState(0);
    const [name, setName] = useState('');

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        if (!type || !name || value < 1 || value > 100) {
            toast({
                title: "Validation Error",
                description: "Type, Name are required and Value must be between 1 and 100",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            await addDiscount(type, value, name);

            toast({
                title: "Discount Created",
                description: "You have successfully created a new discount",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            onClose();
            refetch();
            setType('percentage');
            setValue(0);
            setName('');
        } catch (err) {
            toast({
                title: "Error",
                description: "An error occurred while creating the discount",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            <Button colorScheme='green' rightIcon={<AddIcon/>} onClick={onOpen}>
                {title || "Add New Discount"}
            </Button>

            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Create a new discount</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody pb={6}>
                        <FormControl>
                            <FormLabel>Discount Type</FormLabel>
                            <Select placeholder="Select option"
                                    value={type}
                                    onChange={(e)=>setType(e.target.value)}>
                                <option value="percentage">Percentage</option>
                            </Select>
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Discount Value</FormLabel>
                            <Input type="number"
                                   placeholder="Value"
                                   value={value}
                                   onChange={(e)=>setValue(Number(e.target.value))} />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Discount Name</FormLabel>
                            <Input placeholder="Name"
                                   value={name}
                                   onChange={(e)=>setName(e.target.value)} />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} isLoading={loading} onClick={handleSubmit}>
                            Create
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
