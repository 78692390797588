// Chakra imports
import { Button, Icon, Text, useColorModeValue, Spacer } from '@chakra-ui/react';

import {useGetTodaysSalesByUser} from 'api/reports/getSalesByUser';

// Custom components
import Card from 'components/card/Card';
import Transfer from 'components/dataDisplay/Transfer';
// Assets
import avatar1 from 'assets/img/avatars/avatar1.png';
import avatar2 from 'assets/img/avatars/avatar2.png';
import avatar3 from 'assets/img/avatars/avatar3.png';
import avatar4 from 'assets/img/avatars/avatar4.png';
import { BsArrowRight } from 'react-icons/bs';
import {useEffect} from "react";
import {formatCurrency} from "../../../../../helpers/accountCurrency";
import SalesByUserRow from "./SalesByUserRow";

export default function SalesByUser(props: { [x: string]: any }) {
	const { ...rest } = props;

	const [{data}] = useGetTodaysSalesByUser();

	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const brandColor = useColorModeValue('brand.500', 'white');
	return (
		<Card
			justifyContent='center'
			flexDirection='column'
			w='100%'
			mb={{ base: '20px', lg: '0px' }}
			pb='20px'
			{...rest}>
			<Text color={textColor} fontSize='lg' fontWeight='700' lineHeight='100%' mb='26px'>
				Sales By User Today
			</Text>
			{data?.map((item: {
				transactions: number;
				grossSales: number;
				userName: string; }, index: any) => (
			<SalesByUserRow mb='20px' name={item.userName} transactions={item.transactions} sum={formatCurrency(item.grossSales)} avatar={item.userName} />
			))}
<Spacer></Spacer>

		</Card>
	);
}
