
// Chakra imports
import {Flex, Spinner} from '@chakra-ui/react';
import Card from 'components/card/Card';

import useGetModifierGroups from "../../../../api/productLibrary/getModifierGroups";
import ModifierGroupTable from "./components/ModifierGroupTable";

export default function ListModifierGroups() {
    const [{data, isLoading, isError}] = useGetModifierGroups();
    return (
        <Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
            <Card px='0px'>
                {isLoading &&
                    <Spinner/>
                }
                {!isLoading &&
                    <ModifierGroupTable tableData={data} />
                }
            </Card>
        </Flex>
    );
}
