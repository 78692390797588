// Chakra imports
import {
	Flex,
	FormControl,
	FormLabel,
	SimpleGrid,
	Text,
	useColorModeValue,
	Select,
	Skeleton,
	Tooltip,
	LightMode, Button,Box
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';

import {useEffect, useState} from "react";
import CustomColorPicker from "../../productList/components/CustomCirclePicker";


import {useFormContext, Controller} from 'react-hook-form';
import useManageProduct from "../../../../../api/productLibrary/manageProduct";

// @ts-ignore
export default function Info({ data, refetch }) {

	const isDataLoaded = !!data;

	console.log(data)

	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';

	const {
		register,
		formState: { errors },
		reset,
		setValue,
		control
	} = useFormContext();

	const {restoreProduct} = useManageProduct();

	useEffect(() => {
		if (data) {

			setValue('name', data.name)
			setValue('kitchenName', data?.kitchenName ? data?.kitchenName : data.name);
			setValue('customerName', data?.customerName ? data?.customerName : data.name);
			setValue('btnColour', data?.btnColour);
		}
	}, [data]);

	const restore = async () => {
		await restoreProduct(data._id);
		refetch();
	}


	return (
		<FormControl>
			<Card mb={{base: '0px', xl: '20px'}}>
				<Flex direction='column' mb='40px' ms='10px'>
					<Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
						Product Information
					</Text>
					{data?.deletedOn ? (
						<Flex direction="row" alignItems="center" justifyContent="space-between">
							<Text color="red">This product is deleted and unavailable for sale</Text>
							<Button colorScheme="teal" variant="solid" onClick={()=> restore()}>
								Restore
							</Button>
						</Flex>
					) : null
					}

				</Flex>
				<SimpleGrid columns={{sm: 1, md: 2}} spacing={{base: '20px', xl: '20px'}}>
					<Flex direction='column'>
						<Skeleton isLoaded={!!data?.name}>
							<InputField  mb='25px' me='30px' id='productName' label='Product Name' placeholder='eg. Cola'  {... register("name", {
								required: "Product Name is required",
							})} />
						</Skeleton>
						<Skeleton isLoaded={!!data?.name}>
							<InputField mb='25px' me='30px' id='weight' label='Kitchen Name'
										placeholder='This prints on kitchen tickets' toolTipText ={'This name is shown on kitchen tickets'}  {... register("kitchenName", {
								required: "Kitchen Name is required",
							})}/>
						</Skeleton>
						<Skeleton isLoaded={!!data?.name}>
							<InputField mb='25px' id='color' label='Customer Name'
										placeholder='This is what customers will see, e.g. on receipts'
										{... register("customerName", {
											required: "Customer Name is required",
										})}/>
						</Skeleton>
					</Flex>
					<Flex direction='column'>
					<Skeleton isLoaded={!!data?.name}>
						<div>
							<FormLabel>Button Colour</FormLabel>
							<Controller
								name="btnColour"
								control={control}
								defaultValue={data?.btnColour}
								render={({ field: { onChange, value } }) => (
									<CustomColorPicker
										defaultColor={value}
										onChangeComplete={(color) =>{onChange(color)}}
									/>
								)}
							/>
						</div>
					</Skeleton>

					</Flex>
				</SimpleGrid>
			</Card>
		</FormControl>
	);
}
