import { useContext } from "react";
import { useApp } from "@realm/react";
import { UserContext } from "../../contexts/UserContext";
import { useQuery, gql } from "@apollo/client";
import { posClient } from "../apolloClients/posClient";

const GET_BILLING = gql`
    query GetSubscriptionBySiteId($siteId: String!) {
        getSubscriptionBySiteId(siteId: $siteId) {
            cancel_at
            current_period_end
            customer
            default_payment_method
            discount {
                id
                percent_off
            }
            id
            latest_invoice
            plan {
                active
                quantity
                amount
            }
            trial_end
        }
    }
`;

const useGetBillingInfo = () => {
    const app = useApp();
    const { user, token } = useContext(UserContext);
    const siteId = app.currentUser?.customData?.currentSite;

    const shouldSkip = !user?.accessToken || !siteId;

    const { data, loading, error, refetch } = useQuery(GET_BILLING, {
        variables: { siteId },
        context: {
            headers: {
                Authorization: `Bearer ${token}`
            }
        },
        skip: shouldSkip,
        client: posClient,
    });

    const billingInfo = data?.getSubscriptionBySiteId;

    return [{
        data: billingInfo,
        isLoading: loading || shouldSkip,
        isError: !!error,
        refetch,
    }] as const;
};

export default useGetBillingInfo;
