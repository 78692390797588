import {gql, useApolloClient, useMutation, useQuery} from "@apollo/client";
import {useApp} from "@realm/react";
import {useContext, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {ObjectId} from "bson"
import Currency from 'currency.js';
import {analytics} from "../../index";
import {set} from "react-hook-form";

const CREATE_PRODUCT_MUTATION = gql`
    mutation InsertOneProduct($data: ProductInsertInput!) {
        insertOneProduct(data: $data) {
            _id
        }
    }
`;

const UPDATE_LAYOUT_MUTATION = gql`
    mutation UpdateOneLayout($query: LayoutQueryInput!, $set: LayoutUpdateInput!) {
        updateOneLayout(query: $query, set: $set) {
            _id
        }
    }
`;

const GET_CATEGORY_BY_ID = gql`
    query GetCategoryById($_id: ObjectId!) {
        category(query: { _id: $_id }) {
            _id,
            name,
            autoLayout
            autoLayoutId {
                _id
                layoutItems {
                    product,
                    order,
                    type
                }
            },
            products {
                _id
            }
        }
    }
`;

const UPDATE_CATEGORY_MUTATION = gql`
    mutation UpdateOneCategory($query: CategoryQueryInput!, $set: CategoryUpdateInput!) {
        updateOneCategory(query: $query, set: $set) {
            _id
        }
    }
`;



export const useCreateProduct = () => {

    const app = useApp();

    const { user } = useContext(UserContext);

    const client = useApolloClient();

    const [isLoading, setIsLoading] = useState(false);

    const [updateOneLayout] = useMutation(UPDATE_LAYOUT_MUTATION, {
        context: {
            headers: {
                Authorization: "Bearer " + user?.accessToken,
            }
        }
    });


    const [insertOneProduct, { data, loading, error }] = useMutation(CREATE_PRODUCT_MUTATION,{
        context: {
            headers: {
                Authorization: "Bearer " + user?.accessToken,
            }
        },
        fetchPolicy: 'no-cache',  // here
    });

    const [updateOneCategory] = useMutation(UPDATE_CATEGORY_MUTATION, {
        context: {
            headers: {
                Authorization: "Bearer " + user?.accessToken,
            },
        },
    });


    const onCreateProduct = async (formValues: any) => {

       setIsLoading(true);

        const priceDetails = [];
        for (const orderModeId in formValues.pricingData) {
            let taxRateId = formValues.pricingData[orderModeId].taxRate;

            if(taxRateId === 'no-tax'){
                taxRateId = null;
            }

            // Check if the product has no variant assigned
            if (!formValues.variantGroup) {

                const currencyPrice = Currency(formValues.pricingData[orderModeId].price);
                const priceInCents = Math.round(currencyPrice.multiply(100).value);

                priceDetails.push({
                    orderMode: orderModeId,
                    price: priceInCents.toString(),
                    taxRate:taxRateId,
                    variant: null,
                    _id: String(new ObjectId().toString())
                });
            }

            // If the product has a variant assigned
            else {
                for (const variantId in formValues.pricingData[orderModeId]) {
                    if (variantId !== "price" && variantId !== "taxRate") {
                        const currencyPrice = Currency(formValues.pricingData[orderModeId][variantId].price);
                        const priceInCents = Math.round(currencyPrice.multiply(100).value);
                        priceDetails.push({
                            orderMode: orderModeId,
                            price: priceInCents.toString(),
                            taxRate: taxRateId,
                            variant: variantId,
                            _id: String(new ObjectId().toString())
                        });
                    }
                }
            }
        }

        // Transform form data to match ProductInsertInput type
        const productData = {
            name: formValues.name,
            customerName: formValues.customerName,
            kitchenName: formValues.kitchenName,
            btnColour: formValues.btnColour,
            variantGroups: formValues.variantGroup ? { link: formValues.variantGroup } : null,
            modifierGroup: formValues.modifierGroup ? { link: formValues.modifierGroup } : null,
            priceDetails,
            price: "100",
            owner_id: user.id,
            siteId: app.currentUser.customData.currentSite.toString()
        };


        try {  const { data: { insertOneProduct: product } } = await insertOneProduct({ variables: { data: productData } });

           console.log(product);
            console.log(productData);

            const { data: categoryData } = await client.query({
                query: GET_CATEGORY_BY_ID,
                variables: { _id: formValues.category },
                context: {
                    headers: {
                        Authorization: "Bearer " + user?.accessToken,
                    }
                },
            });

            const category = categoryData?.category;

            const productIds = category.products.map((product: { _id: any; }) => product._id);

            try {
                await analytics.track('Product Created', {
                    name: productData.name,
                    siteId: app.currentUser.customData.currentSite.toString()
                })
            } catch {
                console.log('Error tracking event');
            }


            if (product && category) {
                // Update the category with the new product:
                await updateOneCategory({
                    variables: {
                        query: { _id: formValues.category },
                        set: { products: { link: [...productIds, product._id] } },
                    },
                });
            }


            if (category && category.autoLayout && category.autoLayoutId && category.autoLayoutId._id) {
                const layoutId = category.autoLayoutId._id;

                const newLayoutItem = { order: 1, product: product._id, type: "product" };

                // Set the updated LayoutItems
                const updatedLayoutItems = category.autoLayoutId.layoutItems ? [...category.autoLayoutId.layoutItems, newLayoutItem] : [newLayoutItem];

                const layoutItemsForMutation = updatedLayoutItems.map(({ __typename, ...item }) => item);
                // Call the updateOneLayout mutation
                await updateOneLayout({
                    variables: {
                        query: { _id: layoutId },
                        set: { layoutItems: layoutItemsForMutation },
                    },
                });
            }

            setIsLoading(false);

        } catch (e) {
            setIsLoading(false);
            console.log('Error with InsertOneProduct:', e);
            // @ts-ignore
            console.log('Graphql Errors:', e.graphQLErrors); console.log('Network Error:', e.networkError);
        }

    };

    return { onCreateProduct, data, loading, error, isLoading };
};
