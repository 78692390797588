// useGetTodaysTransactions.ts
import {useContext} from 'react';
import {UserContext} from '../../contexts/UserContext';
import {useQuery, gql} from '@apollo/client';
import {reportsClient} from '../apolloClients/reportsClient';

const GET_TODAYS_USER_SALES = gql`
    query Query($quickDate: String) {
        salesDataPerUser(quickDate: $quickDate) {
            grossSales
            netSales
            userId
            transactions
            userName
        }
    }
`;

const GET_USER_SALES = gql`
    query Query($startDate: String, $endDate: String) {
        salesDataPerUser(startDate: $startDate, endDate: $endDate) {
            grossSales
            netSales
            userId
            transactions
            userName
            tax
        }
    }
`;

export const useGetTodaysSalesByUser = () => {
    const {token} = useContext(UserContext);
    const shouldSkip = !token;

    const {data, loading: apolloLoading, error, refetch} = useQuery(GET_TODAYS_USER_SALES,
        {
            variables: {
                quickDate: "today"
            },
            context: {
                headers: {
                    Authorization: "Bearer " + token,
                },
            },
            skip: shouldSkip,
            client: reportsClient,  // use the imported client
        });

    const isLoading = apolloLoading || shouldSkip;

    return [{
        data: data?.salesDataPerUser,
        isLoading,
        isError: !!error,
        refetch,
    }] as const;
};

export const useGetSalesByUser = (startDate: string, endDate: string) => {
    const {token} = useContext(UserContext);
    const shouldSkip = !token;

    const {data, loading: apolloLoading, error, refetch} = useQuery(GET_USER_SALES,
        {
            variables: {
                startDate: startDate,
                endDate: endDate
            },
            context: {
                headers: {
                    Authorization: "Bearer " + token,
                },
            },
            skip: shouldSkip,
            client: reportsClient,  // use the imported client
        });

    const isLoading = apolloLoading || shouldSkip;

    return [{
        data: data?.salesDataPerUser,
        isLoading,
        isError: !!error,
        refetch,
    }] as const;
};

