import { gql, useMutation } from '@apollo/client';
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

interface UpdateCategoryInput {
    _id: string;
    name: string;
}

const UPDATE_CATEGORY = gql`
    mutation UpdateCategory($_id: ObjectId!, $updateCategory: CategoryUpdateInput!) {
        updateOneCategory(query: { _id: $_id }, set: $updateCategory) {
            _id,
            name,
            autoLayout
            autoLayoutId {
                _id
                layoutItems {
                    type
                    product
                    order
                }
            }
        }
    }
`;

const useUpdateCategory = () => {

    const { user } = useContext(UserContext);

    const [updateCategoryMutation, { loading: isLoading, error: isError }] = useMutation(
        UPDATE_CATEGORY,
        {
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            }
        }
    );

    const updateCategoryById = async (updateData: UpdateCategoryInput) => {
        await updateCategoryMutation({
            variables: {
                _id: updateData._id,
                updateCategory: {
                    name: updateData.name,
                }
            }
        });
    }

    return { updateCategoryById, isLoading, isError };
};

export default useUpdateCategory;
