import { gql, useQuery} from "@apollo/client";
import {useContext, useEffect} from "react";
import {useApp} from "@realm/react";
import {UserContext} from "../../contexts/UserContext"

const GET_LAYOUTS = gql`
    query Layouts($query: LayoutQueryInput) {
        layouts(query: $query) {
            _id
            name
            btnColour
            layoutItems {
                type
                order
            }
            category {
                name
            }
            icon
        }
    }
`;

const useGetLayouts = () => {
    const app = useApp();
    const { user } = useContext(UserContext);
    const shouldSkip = !user?.accessToken;

    useEffect(() => {
        console.log("user change");
        if(user){
            refetch();
        }
    }, [user]);

    const { data, loading, error, refetch } = useQuery(GET_LAYOUTS,
        {
            variables: {
                query: {
                    owner_id: app.currentUser?.id,
                }
            },
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            },
            skip: shouldSkip,
        }
    );

    const layouts = data?.layouts;

    return [{
        data: layouts,
        isLoading: loading,
        isError: !!error,
        refetch,
    }] as const;
};

export default useGetLayouts;
