import { useApp } from "@realm/react";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { ObjectId } from "bson";

export type PrintRoute_categories = {
    category: ObjectId;
    position: number;
};

export type PrintRoute = {
    _id: ObjectId;
    category: PrintRoute_categories[];
    name?: string;
    printer?: string;
    siteId?: string;
    copies?: number;
};

const useGetPrintRoutes = () => {
    const app = useApp();
    const { user } = useContext(UserContext);
    const [printRoutes, setPrintRoutes] = useState<PrintRoute[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const dataFetched = useRef(false);

    useEffect(() => {
        if (dataFetched.current) return;

        const fetchPrintRoutes = async () => {
            setLoading(true);
            if (!user) {
                setLoading(false);
                return;
            }

            try {
                const mongo = user.mongoClient("mongodb-atlas");
                const printRouteCollection = mongo.db("pos").collection("PrintRoute");
                const currentSiteId = app.currentUser.customData.currentSite.toString();
                const fetchedPrintRoutes = await printRouteCollection.find({ siteId: currentSiteId });
                setPrintRoutes(fetchedPrintRoutes);
                dataFetched.current = true; // Mark data as fetched
            } catch (err) {
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };

        fetchPrintRoutes();
    }, [user, app]);

    return { printRoutes, loading, error };
};

export default useGetPrintRoutes;
