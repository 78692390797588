import {gql, useQuery} from "@apollo/client";
import {useContext, useEffect} from "react";
import {useApp} from "@realm/react";
import {UserContext} from "../../contexts/UserContext"

const GET_VARIANT_GROUPS = gql`
    query VariantGroups($query: VariantGroupQueryInput) {
        variantGroups(query: $query) {
            _id
            name
            owner_id
            variants {
                _id
                name
            }
        }
    }
`;

const useGetVariantGroups = () => {
    const app = useApp();
    const { user } = useContext(UserContext);
    const shouldSkip = !user?.accessToken;


    useEffect(() => {
        console.log("user change");
        if(user){
            refetch();
        }
    }, [user]);

    const { data, loading, error, refetch } = useQuery(GET_VARIANT_GROUPS,
        {
            variables: {
                query: {
                    owner_id: app.currentUser?.id,
                }
            },
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            },
            skip: shouldSkip,
        }
    );

    const variantGroups = data?.variantGroups;

    return [{
        data: variantGroups,
        isLoading: loading,
        isError: !!error,
        refetch,
    }] as const;
};

export default useGetVariantGroups;
