import { Button, Input, Modal, Divider, ModalContent, ModalHeader, ModalBody, ModalFooter, Stack, Text } from "@chakra-ui/react";
import {ChangeEvent, useEffect, useState} from "react";
import useCreateVariantGroup from "../../../../../api/productLibrary/createVariantGroup";

// @ts-ignore
function VariantGroupModal({isOpen, onClose, data, refetch}) {
    const [groupName, setGroupName] = useState("");
    const [variants, setVariants] = useState([]);

    const { createVariantGroup } = useCreateVariantGroup();

    const handleClose = () => {
        onClose();
        setGroupName("");
        setVariants([]);
    }

    const addVariant = () => {
        setVariants([...variants, ""]);
    }

    const removeVariant = (index: any) => {
        if (variants) {
            const newVariants = [...variants];
            newVariants.splice(index, 1);
            setVariants(newVariants);
        }
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>, index: any) => {
        const newVariants = [...variants];
        newVariants[index] = e.target.value;
        setVariants(newVariants);
    }

    const handleSubmit = async () => {

        console.log(variants)

        //@ts-ignore
        const initialVariantIds = data?.variants.map(variant => variant._id);
        const currentVariantIds = variants.map(variant => variant._id);

        //@ts-ignore
        const deletedVariantIds = initialVariantIds?.filter(id => !currentVariantIds.includes(id));

        //@ts-ignore
        deletedVariantIds?.forEach(id => {
            console.log("DELETE ", id)
        });

        const newVariantGroup = {
            name: groupName,
            variants: variants.map((variantName) => ({name: variantName})),
        };

        try {
            await createVariantGroup(newVariantGroup);
            await refetch();
            onClose();  // If successful, close the modal
        } catch (error) {
            console.error(error);  // Handle your error appropriately
        }


    }

    useEffect(() => {
        if (data) {
            setGroupName(data.name);
            // Now we keep the entire variant object
            setVariants(data.variants);
        } else {
            setGroupName("");
            setVariants([]);
        }
    }, [data]);

    return (
            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalContent>
                    <ModalHeader>Portion Group</ModalHeader>
                    <ModalBody>

                        <Input placeholder="Group Name" value={groupName} onChange={(e) => setGroupName(e.target.value)} mb={3} />
                        <Divider mb={3}/>
                        {variants && variants?.map((variant, index) => (
                            <Stack direction="row" mb={2} key={index}>
                                <Input value={variant.name} placeholder="Portion Name" onChange={(e) => handleInputChange(e, index)} />
                                <Button colorScheme="red" onClick={() => removeVariant(index)}>-</Button>
                            </Stack>
                        ))
                        }
                        <Button onClick={addVariant} colorScheme="brand">Add new portion</Button>
                        <Text mt={3}>Remember to add the default portion, e.g. Regular</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} isDisabled={variants.length === 0 || groupName === '' || variants.includes('')} onClick={handleSubmit}>Save</Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

    )
}

export default VariantGroupModal;
