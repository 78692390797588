import { gql, useMutation } from '@apollo/client';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useApp } from "@realm/react";
import {useContext} from "react";
import {UserContext} from "../../contexts/UserContext";

interface PosUserInsertInput {
    _id?: string;
    level?: string;
    name?: string;
    owner_id?: string;
    pin?: string;
    siteId?: string;
    colour?: string;
}

const ADD_POS_USER = gql`
    mutation AddPosUser($newPosUser: PosUserInsertInput!) {
        insertOnePosUser(data: $newPosUser) {
            _id,
            level,
            name,
            owner_id,
            pin,
            siteId,
            colour
        }
    }
`;

const useCreatePosUser = () => {
    const app = useApp();

    const { user } = useContext(UserContext);

    const [addPosUser, { loading: isLoading, error: isError }] = useMutation(
        ADD_POS_USER,
        {
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            }
        }
    );

    const createPosUser = async (newPosUser: PosUserInsertInput) => {
        await addPosUser({
            variables: {
                newPosUser: {
                    ...newPosUser,
                    owner_id: app.currentUser.id,
                    siteId: app.currentUser.customData.currentSite.toString(),
                }
            }
        });
    }

    return { createPosUser, isLoading, isError };
};

export default useCreatePosUser;
