// hooks/useSaveTables.ts
import { useCallback, useContext } from 'react';
import { useApp } from '@realm/react';
import { UserContext } from '../../contexts/UserContext';
import { ObjectId, Double } from 'bson';
import {TableShape} from "../../views/admin/tables/components/types";
import {BSON} from "realm";
import Decimal128 = BSON.Decimal128;

export interface TablePosition {
    id: string;
    positionX: number;
    positionY: number;
    shape: TableShape;
    tableNumber: string;
}

export const useSaveTables = () => {
    const app = useApp();
    const { user } = useContext(UserContext);

    const saveTables = useCallback(async (tables: TablePosition[], tableArea: string) => {
        if (!user || !app.currentUser) {
            console.error("User is not authenticated");
            return;
        }

        const siteId = app.currentUser.customData.currentSite.toString();

        try {
            const mongo = user.mongoClient('mongodb-atlas');
            const tableCollection = mongo.db('pos').collection('Table');
            console.log(tables);
            for (const table of tables) {
                const filter = { _id: {$oid: table.id} };
                const update = {
                    $set: {
                        tableNumber: table.tableNumber,
                        positionX: { $numberDouble: table.positionX.toFixed(1) }, // Ensure positionX is a double
                        positionY: { $numberDouble: table.positionY.toFixed(1) }, // Ensure positionY is a double
                        shape: table.shape,
                        siteId: siteId,
                        tableArea: {$oid: tableArea},
                    },
                };
                await tableCollection.updateOne(filter, update, { upsert: true });
            }

            console.log("Tables saved successfully");
        } catch (error) {
            console.error("Failed to save tables: ", error);
        }
    }, [user, app]);

    return { saveTables };
};
