import {ApolloClient, InMemoryCache, useApolloClient} from '@apollo/client';
import {gql} from "@apollo/client";
import { useKindeAuth} from "@kinde-oss/kinde-auth-react";
import { useApp} from "@realm/react";
import { useState } from 'react';

interface TaxRateInput {
    name: string;
    rate: number;
}

export const useCreateTaxRate = () => {
    const { getToken } = useKindeAuth();
    const app = useApp();

    const client = new ApolloClient({
        uri: "https://eu-west-1.aws.realm.mongodb.com/api/client/v2.0/app/application-0-trznh/graphql",
        cache: new InMemoryCache(),
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const createTaxRate = async (newTaxRate: TaxRateInput) => {
        setIsLoading(true);
        setIsError(false);

        const currentUserToken = app.currentUser?.accessToken;
        const token = currentUserToken ? currentUserToken : await getToken();

        try {
            await client.mutate({
                mutation: gql`
                    mutation AddTaxRate($newTaxRate: TaxRateInsertInput!) {
                        insertOneTaxRate(data: $newTaxRate) {
                            _id,
                            name,
                            rate,
                            owner_id,
                            siteId
                        }
                    }
                `,
                variables: { newTaxRate: {...newTaxRate, owner_id: app.currentUser.id, siteId: app.currentUser.customData.currentSite.toString()} },
                context: { headers: {Authorization: `Bearer ${token}`}},
            });

            // might need to refresh the list of tax rates here
            setIsLoading(false);
        } catch (e) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };

    return [createTaxRate, { isLoading, isError }] as const;
};
