import {ApolloClient, ApolloLink, gql, HttpLink, InMemoryCache, useQuery} from "@apollo/client";
import {useContext, useMemo} from "react";
import {UserContext} from "../../contexts/UserContext";
import Dinero from "dinero.js";

const GET_PRODUCT_BY_ID = gql`
    query GetProductById($_id: ObjectId!) {
        product(query: { _id: $_id }) {
            _id,
            name,
            kitchenName,
            customerName,
            price,
            btnColour,
            deletedOn,
            modifierGroup {
                _id
                name
            }
            category {
                name,
                _id
            },
            priceDetails {
                orderMode,
                price,
                taxRate,
                variant,
                costPrice,
                _id
            },
            variantGroups {
                _id,
                name,
                variants {
                    _id,
                    name
                }
            }
        }
    }
`;

const useGetProduct = (_id: string) => {
    const { user } = useContext(UserContext);

    const shouldSkip = !user?.accessToken || !_id;

    const { data, loading, error, refetch } = useQuery(GET_PRODUCT_BY_ID,
        {
            variables: { _id },
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            },
            skip: shouldSkip
        });

    const convertToDinero = (product: any) => {
        if (product) {
            let productDataCopy = { ...product };

            productDataCopy.priceDetails = productDataCopy.priceDetails.map((priceDetail: { price: number; costPrice: number }) => {
                return {
                    ...priceDetail, // Spread the rest of the object
                    price: Dinero({ amount: Number(priceDetail.price), currency: "GBP" }),
                    costPrice: Dinero({ amount: Number(priceDetail.costPrice), currency: "GBP" }),
                };
            });
            return productDataCopy;
        }
        return null;
    };

    const convertedData = useMemo(() => convertToDinero(data?.product), [data?.product]);

    const refetchAndConvert = async()=> {
        const refetchedData = await refetch();
        return convertToDinero(refetchedData.data?.product);
    }

    return [{
        data: convertedData,
        isLoading: loading,
        isError: !!error,
        refetch: refetchAndConvert,
    }] as const;
};

export default useGetProduct;
