import { gql, useMutation } from '@apollo/client';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useApp } from "@realm/react";
import {useContext} from "react";
import {UserContext} from "../../contexts/UserContext";

interface PosUserUpdateInput {
    level?: string;
    name?: string;
    pin?: string;
    colour?: string;
}

const UPDATE_POS_USER = gql`
    mutation updatePosUser($_id: ObjectId!, $updatePosUser: PosUserUpdateInput!) {
        updateOnePosUser(query: { _id: $_id }, set: $updatePosUser) {
            _id,
            level,
            name,
            pin,
            colour
        }
    }
`;

const useUpdatePosUser = () => {
    const app = useApp();
    const { user } = useContext(UserContext);

    const [updatePosUserMutation, { loading: isLoading, error: isError }] = useMutation(
        UPDATE_POS_USER,
        {
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            }
        }
    );

    const updatePosUser = async (_id: string, updatePosUser: PosUserUpdateInput) => {
        await updatePosUserMutation({
            variables: {
                _id,
                updatePosUser,
            }
        });
    }

    return { updatePosUser, isLoading, isError };
};

export default useUpdatePosUser;
