/* eslint-disable */

import {
    Badge,
    Button,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Icon,
    Image,
    Stack,
    Spacer,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    FormControl,
    FormLabel,
    Input,
    ModalFooter, useDisclosure, Box
} from '@chakra-ui/react';
// Custom components
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import * as React from 'react';
// Assets
import { SearchBar } from "components/navbar/searchBar/SearchBar";

import { Link } from "react-router-dom";

import {
    PaginationState,
    createColumnHelper,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
} from '@tanstack/react-table'
import {AddIcon, TriangleDownIcon, TriangleUpIcon} from "@chakra-ui/icons";
import {useNavigate} from "react-router-dom";
import CustomCirclePicker from "../../productList/components/CustomCirclePicker";
import useCreateLayout from "../../../../../api/productLibrary/createLayout";

import baguette from "../../../../../assets/layoutIcons/baguette.svg";
import bbq from "../../../../../assets/layoutIcons/bbq.svg";
import beer from "../../../../../assets/layoutIcons/beer.svg";
import broccoli from "../../../../../assets/layoutIcons/broccoli.svg";
import burger from "../../../../../assets/layoutIcons/burger.svg";
import cake from "../../../../../assets/layoutIcons/cake.svg";
import champagne_glass from "../../../../../assets/layoutIcons/champagne_glass.svg";
import cheese from "../../../../../assets/layoutIcons/cheese.svg";
import cocktail_glass from "../../../../../assets/layoutIcons/cocktail_glass.svg";
import cookie from "../../../../../assets/layoutIcons/cookie.svg";
import croissant from "../../../../../assets/layoutIcons/croissant.svg";
import coffee from "../../../../../assets/layoutIcons/coffee.svg";
import cup_to_go from "../../../../../assets/layoutIcons/cup_to_go.svg";
import dish from "../../../../../assets/layoutIcons/dish.svg";
import donut from "../../../../../assets/layoutIcons/donut.svg";
import fish from "../../../../../assets/layoutIcons/fish.svg";
import french_fries from "../../../../../assets/layoutIcons/french_fries.svg";
import fried_egg from "../../../../../assets/layoutIcons/fried_egg.svg";
import gyro from "../../../../../assets/layoutIcons/gyro.svg";
import hot_dog from "../../../../../assets/layoutIcons/hot_dog.svg";
import ice_cream from "../../../../../assets/layoutIcons/ice_cream.svg";
import juice from "../../../../../assets/layoutIcons/juice.svg";
import meat from "../../../../../assets/layoutIcons/meat.svg";
import noodles from "../../../../../assets/layoutIcons/noodles.svg";
import pizza from "../../../../../assets/layoutIcons/pizza.svg";
import strawberry from "../../../../../assets/layoutIcons/strawberry.svg";
import taco from "../../../../../assets/layoutIcons/taco.svg";
import tea from "../../../../../assets/layoutIcons/tea.svg";
import wine_glass from "../../../../../assets/layoutIcons/wine_glass.svg";
import AddLayoutModal from "./AddLayoutModal";
import {useEffect, useState} from "react";

const icons = [
    { src: baguette, alt: 'Baguette', name: 'baguette', tags: ['bread', 'bakery', 'food'] },
    { src: bbq, alt: 'BBQ', name: 'bbq', tags: ['grill', 'meat', 'barbecue', 'food'] },
    { src: beer, alt: 'Beer', name: 'beer', tags: ['alcohol', 'drink', 'brew'] },
    { src: broccoli, alt: 'Broccoli', name: 'broccoli', tags: ['vegetable', 'vegan', 'food', 'healthy'] },
    { src: burger, alt: 'Burger', name: 'burger', tags: ['fast food', 'meat', 'food', 'fries'] },
    { src: cake, alt: 'Cake', name: 'cake', tags: ['dessert', 'sweet', 'birthday'] },
    { src: champagne_glass, alt: 'Champagne Glass', name: 'champagne_glass', tags: ['drink', 'alcohol', 'celebration', 'drinks'] },
    { src: cheese, alt: 'Cheese', name: 'cheese', tags: ['dairy', 'food', 'burger'] },
    { src: cocktail_glass, alt: 'Cocktail Glass', name: 'cocktail_glass', tags: ['drink', 'alcohol', 'party', 'drinks', 'cocktail', 'cocktails'] },
    { src: cookie, alt: 'Cookie', name: 'cookie', tags: ['dessert', 'sweet', 'biscuit', 'desserts'] },
    { src: croissant, alt: 'Croissant', name: 'croissant', tags: ['bread', 'bakery', 'food'] },
    { src: coffee, alt: 'Coffee', name: 'coffee',tags: ['drink', 'dark', 'cup', 'coffees'] },
    { src: cup_to_go, alt: 'Cup to go', name: 'cup_to_go', tags: ['drink', 'coffee', 'takeaway', 'coffees'] },
    { src: dish, alt: 'Dish', name: 'dish', tags: ['plate', 'food', 'meal'] },
    { src: donut, alt: 'Donut', name: 'donut', tags: ['dessert', 'sweet', 'baked'] },
    { src: fish, alt: 'Fish', name: 'fish', tags: ['seafood', 'food', 'fish', 'sea'] },
    { src: french_fries, alt: 'French Fries', name: 'french_fries', tags: ['fast food', 'potato', 'food', 'fries'] },
    { src: fried_egg, alt: 'Fried Egg', name: 'fried_egg', tags: ['breakfast', 'food', 'egg'] },
    { src: gyro, alt: 'Gyro', name: 'gyro', tags: ['meat', 'sandwich', 'food'] },
    { src: hot_dog, alt: 'Hot Dog', name: 'hot_dog', tags: ['fast food', 'meat', 'food'] },
    { src: ice_cream, name:"ice_cream", alt: 'Ice Cream', tags: ['dessert', 'sweet', 'cold'] },
    { src: juice, name:"juice", alt: 'Juice', tags: ['drink', 'fruit', 'refreshing', 'drinks', 'soft', 'softs'] },
    { src: meat, name:"meat", alt: 'Meat', tags: ['protein', 'food', 'bbq', 'meat', 'hot'] },
    { src: noodles, name:"noodles", alt: 'Noodles', tags: ['asian', 'food', 'dinner', 'pasta'] },
    { src: pizza, name:"pizza", alt: 'Pizza', tags: ['fast food', 'italian', 'food', 'pizza', 'pizzas'] },
    { src: strawberry, name:"strawberry", alt: 'Strawberry', tags: ['fruit', 'food', 'berry', 'dessert', 'desserts'] },
    { src: taco, name:"taco", alt: 'Taco', tags: ['mexican', 'food', 'fast food', 'food'] },
    { src: tea, name:"tea", alt: 'Tea', tags: ['drink', 'hot', 'cup'] },
    { src: wine_glass, name:"wine_glass", alt: 'Wine Glass', tags: ['drink', 'alcohol', 'wine', 'drinks', 'wines'] }
];

const getIconSrcByName = (iconName: string) => {
    const icon = icons.find(icon => icon.name === iconName);
    if(icon) return icon.src;

    return null; // return null or default icon if icon was not found.
}


type Product = {
    _id: string;
    name: string;
    price: string;
    btnColour: string;
    category: {
        name: string;
    }
    icon: string;
};

export default function LayoutTable(props: { tableData: any, refetch: any }) {
    const { tableData, refetch } = props;
    const textColor = useColorModeValue("navy.700", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const brandColor = useColorModeValue("brand.500", "brand.400");
    const [selectedLayout, setSelectedLayout] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const navigate = useNavigate();

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
        []
    )

    const handleEdit = (layout: any) => {
        setSelectedLayout(layout);
    };

    useEffect(() => {
        if (selectedLayout) {
            onOpen();
        }
    }, [selectedLayout]);

    React.useEffect(() => {
        setData(tableData);
    }, [tableData]);

    let defaultData = tableData;
    const [globalFilter, setGlobalFilter] = React.useState('')
    const columnHelper = createColumnHelper<Product>();
    // @ts-ignore
    const columns = [
        columnHelper.accessor('name', {
            id: 'name',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '14px', lg: '16px' }}
                    color='blue.700'>
                    LAYOUT NAME
                </Text>
            ),
            cell: (info: any) => (
                <Text color={textColor} fontSize='xl' fontWeight='500'>
                    {info.getValue()}
                </Text>
            )
        }),
        columnHelper.accessor('category.name', {
            id: 'category',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '14px', lg: '16px' }}
                    color='blue.700'>
                    CONNECTED CATEGORY
                </Text>
            ),
            cell: (info) => (
                <Text color={textColor} fontSize='xl' fontWeight='500'>
                    {info.getValue()?? "--"}
                </Text>
            )
        }),
        columnHelper.accessor('icon', {
            id: 'icon',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '14px', lg: '16px' }}
                    color='blue.700'>
                    ICON
                </Text>
            ),
            cell: (info) => (
                <Box backgroundColor={'brand.200'}
                     w="50px"
                     h="50px"
                     borderRadius='md'
                     display="flex"
                     alignItems="center"
                     justifyContent="center">
                <Image src={getIconSrcByName(info.getValue())} alt={info.getValue()} boxSize="40px" />
                </Box>
            )
        }),
        columnHelper.accessor('_id', {
            id: 'edit',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '14px', lg: '16px' }}
                    color='blue.700'>
                    EDIT NAME/ICON
                </Text>
            ),
            cell: (info) => (
                <Text color={textColor} fontSize='xl' fontWeight='500'>
                    <Text onClick={() => handleEdit({name: info.row.original.name, icon: info.row.original.icon, _id: info.row.original._id})}>EDIT</Text>
                </Text>
            ),
        }),
        columnHelper.accessor('_id', {
            id: 'edit',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '14px', lg: '16px' }}
                    color='blue.700'>
                    EDIT BUTTON LAYOUT
                </Text>
            ),
            cell: (info) => (
                <Text color={textColor} fontSize='xl' fontWeight='500'>
                    <Link to={"/admin/library/layouts/" + info.getValue()}>EDIT LAYOUT</Link>
                </Text>
            ),
        }),
    ];
    const [ data, setData ] = React.useState(() => [ ...defaultData ]);
    const [{ pageIndex, pageSize }, setPagination] =
        React.useState<PaginationState>({
            pageIndex: 0,
            pageSize: 25,
        })

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )
    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
            globalFilter,
            pagination,
        },
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })
    const createPages = (count: number) => {
        let arrPageCount = [];

        for (let i = 1; i <= count; i++) {
            arrPageCount.push(i);
        }

        return arrPageCount;
    };

    React.useEffect(() => {
        if (table.getState().columnFilters[0]?.id === 'name') {
            if (table.getState().sorting[0]?.id !== 'name') {
                table.setSorting([{ id: 'name', desc: false }])
            }
        }
    }, [table.getState().columnFilters[0]?.id])

    return (
        <Flex
            direction='column'
            w='100%'
            overflowX={{ sm: "scroll", lg: "hidden" }}>
            <Flex
                align={{ sm: "flex-start", lg: "flex-start" }}
                justify={{ sm: "flex-start", lg: "flex-start" }}
                w='100%'
                px='22px'
                mb='36px'>
                <DebouncedInput
                    value={globalFilter ?? ''}
                    onChange={value => setGlobalFilter(String(value))}
                    className="p-2 font-lg shadow border border-block"
                    placeholder="Search..."
                />
                <Spacer />
               <AddLayoutModal initialLayout={selectedLayout} isOpen={isOpen} onOpen={onOpen} onClose={onClose} refetch={refetch}/>
            </Flex>
            <Table variant='simple' color='gray.500' mb='24px'>
                <Thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                return (
                                    <Th
                                        pe='10px'
                                        borderColor={borderColor} key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder ? null : (
                                            <Flex
                                                {...{
                                                    className: header.column.getCanSort()
                                                        ? 'cursor-pointer select-none'
                                                        : '',
                                                    onClick: header.column.getToggleSortingHandler(),
                                                }}

                                                align='center'
                                                fontSize={{ sm: "10px", lg: "12px" }}
                                                color='blue.700'>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {{
                                                    asc:  <TriangleUpIcon aria-label="sorted ascending" />,
                                                    desc:  <TriangleDownIcon aria-label="sorted descending" />,
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </Flex>
                                        )}
                                    </Th>
                                )
                            })}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                            <Tr px="20px" key={row.id}>
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <Td key={cell.id}
                                            fontSize={{ sm: "14px" }}
                                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                            borderColor={borderColor}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </Td>
                                    )
                                })}
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
            <Flex w="100%" justify="space-between" px="20px" pt="10px" pb="5px">
                {/* SET ROW NUMBER */}
                <Text
                    fontSize='sm'
                    color='gray.500'
                    fontWeight='normal'
                    mb={{ sm: "24px", md: "0px" }}>
                    Showing {pageSize * pageIndex + 1} to{" "}
                    {pageSize * (pageIndex + 1) <= tableData.length
                        ? pageSize * (pageIndex + 1)
                        : tableData.length}{" "}
                    of {tableData.length} entries
                </Text>
                {/* PAGINATION BUTTONS */}
                <div className="flex items-center gap-2">
                    <Stack direction='row' alignSelf='flex-end' spacing='4px' ms='auto'>
                        <Button
                            variant='no-effects'
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                            transition='all .5s ease'
                            w='40px'
                            h='40px'
                            borderRadius='50%'
                            bg='transparent'
                            border='1px solid'
                            borderColor={useColorModeValue("gray.200", "white")}
                            display={
                                pageSize === 5 ? "none" : table.getCanPreviousPage() ? "flex" : "none"
                            }
                            _hover={{
                                bg: "whiteAlpha.100",
                                opacity: "0.7",
                            }}>
                            <Icon as={MdChevronLeft} w='16px' h='16px' color={textColor} />
                        </Button>
                        {createPages(table.getPageCount()).map((pageNumber, index) => {
                            return (
                                <Button
                                    variant='no-effects'
                                    transition='all .5s ease'
                                    onClick={() => table.setPageIndex(pageNumber - 1)}
                                    w='40px'
                                    h='40px'
                                    borderRadius='50%'
                                    bg={
                                        pageNumber === pageIndex + 1 ? brandColor : "transparent"
                                    }
                                    border={
                                        pageNumber === pageIndex + 1
                                            ? "none"
                                            : "1px solid lightgray"
                                    }
                                    _hover={
                                        pageNumber === pageIndex + 1
                                            ? {
                                                opacity: "0.7",
                                            }
                                            : {
                                                bg: "whiteAlpha.100",
                                            }
                                    }
                                    key={index}>
                                    <Text
                                        fontSize='sm'
                                        color={pageNumber === pageIndex + 1 ? "#fff" : textColor}>
                                        {pageNumber}
                                    </Text>
                                </Button>
                            );
                        })}
                        <Button
                            variant='no-effects'
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                            transition='all .5s ease'
                            w='40px'
                            h='40px'
                            borderRadius='50%'
                            bg='transparent'
                            border='1px solid'
                            borderColor={useColorModeValue("gray.200", "white")}
                            display={pageSize === 5 ? "none" : table.getCanNextPage() ? "flex" : "none"}
                            _hover={{
                                bg: "whiteAlpha.100",
                                opacity: "0.7",
                            }}>
                            <Icon as={MdChevronRight} w='16px' h='16px' color={textColor} />
                        </Button>
                    </Stack>
                </div>

            </Flex>



        </Flex>


    )
}
// A debounced input react component
function DebouncedInput({
                            value: initialValue,
                            onChange,
                            debounce = 500,
                            ...props
                        }: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <SearchBar
            {...props}
            value={value}
            onChange={(e:any) => setValue(e.target.value)}
            h='44px'
            w={{ lg: "390px" }}
            borderRadius='16px'
        />
    )
}
