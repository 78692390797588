import {useApp} from "@realm/react";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {ObjectId} from "bson"

const useManageSiteSettings = () => {
    const app = useApp();
    const { user } = useContext(UserContext);
    let mongo: { db: (arg0: string) => { (): any; new(): any; collection: { (arg0: string): any; new(): any; }; }; };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Helper function to convert string to ObjectId
    const toObjectId = (idString: string): {} => {
        return { '$oid': idString };
    };

    const updateUser = () => {
        if (user) {
            mongo = user.mongoClient("mongodb-atlas");
        }
    };

    // Initial fetch
    updateUser();


  const getOrCreateSiteSettings = async () => {
        const siteCollection = mongo.db("posAccounts").collection("Site");
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        let siteSettingId;
        const currentSiteId = app.currentUser.customData.currentSite.toString();

        console.log("current site is", currentSiteId)

        const site = await siteCollection.findOne({_id: toObjectId(currentSiteId)});

        console.log("current site is", site)

        if (site) {
            if (site.setting) {
                // If SiteSetting reference exists, just use it.
                siteSettingId = site.setting;
            } else {
                // If siteSetting doesn't exist, create a new one
                const newSiteSetting = {_id:{ '$oid' : new ObjectId().toString()}, owner_id: user.id};
                const insertedSiteSetting = await siteSettingCollection.insertOne(newSiteSetting);

                siteSettingId = insertedSiteSetting.insertedId;

                // Link the new SiteSetting to the Site
                await siteCollection.updateOne(
                    {_id: toObjectId(currentSiteId)},
                    {$set: {setting: {'$oid' : siteSettingId.toString() }}}
                );
            }
        } else {
            throw new Error('Current site not found');
        }

        return siteSettingId;
    };

    const getOrCreateSiteMetadata = async () => {
        const siteCollection = mongo.db("posAccounts").collection("Site");
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteMetadata");

        let siteSettingId;
        const currentSiteId = app.currentUser.customData.currentSite.toString();

        console.log("current site is", currentSiteId)

        const site = await siteCollection.findOne({_id: toObjectId(currentSiteId)});

        console.log("current site is", site)

        if (site) {
            if (site.metadata) {
                // If SiteSetting reference exists, just use it.
                siteSettingId = site.metadata;
            } else {
                // If siteSetting doesn't exist, create a new one
                const newSiteSetting = {_id:{ '$oid' : new ObjectId().toString()}, owner_id: user.id};
                const insertedSiteSetting = await siteSettingCollection.insertOne(newSiteSetting);

                siteSettingId = insertedSiteSetting.insertedId;

                await siteCollection.updateOne(
                    {_id: toObjectId(currentSiteId)},
                    {$set: {metadata: {'$oid' : siteSettingId.toString() }}}
                );
            }
        } else {
            throw new Error('Current site not found');
        }

        return siteSettingId;
    };

    const getDefaultCard = async () => {
        setLoading(true);
        if(!user) {
            setTimeout(updateUser, 2000);
            return '';
        }

        const siteSettingId = await getOrCreateSiteSettings();
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        try {
            const siteSetting = await siteSettingCollection.findOne({ _id: {$oid: siteSettingId.toString()} });
            return siteSetting
                ? siteSetting.defaultCardType || ''
                : '';
        } catch (error) {
            console.error(`Failed to fetch card settings: ${error}`);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const getEnabledCards = async () => {
        setLoading(true);
        if(!user) {
            setTimeout(updateUser, 2000);
            return [];
        }

        const siteSettingId = await getOrCreateSiteSettings();
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        try {
            const siteSetting = await siteSettingCollection.findOne({ _id: {$oid: siteSettingId.toString()} });
            console.log("SITE SETTING", siteSetting)
            return siteSetting
                ? siteSetting.enabledCardTypes || []
                : [];
        } catch (error) {
            console.error(`Failed to fetch card settings: ${error}`);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const setDefaultCard = async (defaultCard: string) => {
        setLoading(true);
        setError(null);

        if(!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const siteSettingId = await getOrCreateSiteSettings();

        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        try {
            await siteSettingCollection.updateOne(
                { _id:  {$oid: siteSettingId.toString()} },
                { $set: { 'defaultCardType': defaultCard } }
            );
        } catch (err) {
            console.error(err);
            setError(err);
        }

        setLoading(false);
    };

    const setEnabledCards = async (enabledCards: string[]) => {
        setLoading(true);
        setError(null);

        if(!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        const siteSettingId = await getOrCreateSiteSettings();

        try {
            await siteSettingCollection.updateOne(
                { _id:  {$oid: siteSettingId.toString()} },
                { $set: { 'enabledCardTypes': enabledCards } }
            );
        } catch (err) {
            console.error(err);
            setError(err);
        }

        setLoading(false);
    };


    const setZettleTipping = async (enabled: boolean, type: string) => {
        setLoading(true);
        setError(null);

        if(!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const siteSettingId = await getOrCreateSiteSettings();

        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        try {
            await siteSettingCollection.updateOne(
                { _id:  {$oid: siteSettingId.toString()} },
                { $set: { 'zettleSettings.tippingEnabled': enabled,
                        'zettleSettings.tippingType': type  } }
            );
        } catch (err) {
            console.error(err);
            setError(err);
        }

        setLoading(false);
    };

    const getZettleTipping = async (): Promise<{tippingEnabled: boolean, tippingType: string} | undefined> => {
        setLoading(true);
        if(!user) {
            setTimeout(updateUser, 2000);
            return null;
        }

        const siteSettingId = await getOrCreateSiteSettings();
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        try {
            const siteSettings = await siteSettingCollection.findOne(
                { _id:  {$oid: siteSettingId.toString()} },
            );

            const { tippingEnabled, tippingType } = siteSettings?.zettleSettings || { tippingEnabled: false, tippingType: null };

            return { tippingEnabled, tippingType };
        } catch (err) {
            console.error(err);
            setError(err);
        }
    };

    const setReceiptSettings = async (header: string, footer: string, largeOrderNumber: boolean = false, alwaysPrint: boolean = false) => {
        setLoading(true);
        setError(null);

        if(!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const siteSettingId = await getOrCreateSiteSettings();

        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        let updates: any = {};

        if(header !== null){
            updates['receiptSettings.header'] = header;
        }
        if(footer !== null){
            updates['receiptSettings.footer'] = footer;
        }

        updates['receiptSettings.largeOrderNumber'] = largeOrderNumber;

        updates['receiptSettings.alwaysPrint'] = alwaysPrint;

        try {

            if(Object.keys(updates).length > 0) {
                await siteSettingCollection.updateOne(
                    {_id: {$oid: siteSettingId.toString()}},
                    {$set: updates}
                );
            }
        } catch (err) {
            console.error(err);
            setError(err);
        }

        setLoading(false);
    };

    const getReceiptSettings = async (): Promise<{header: string, footer: string, largeOrderNumber: boolean, alwaysPrint: boolean} | undefined> => {
        setLoading(true);
        if(!user) {
            setTimeout(updateUser, 2000);
            return null;
        }

        const siteSettingId = await getOrCreateSiteSettings();
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        try {
            const siteSettings = await siteSettingCollection.findOne(
                { _id:  {$oid: siteSettingId.toString()} },
            );

            const { header, footer, largeOrderNumber, alwaysPrint } = siteSettings.receiptSettings || { header: "", footer: "", largeOrderNumber: false, alwaysPrint: false };

            return { header, footer, largeOrderNumber, alwaysPrint };

        } catch (err) {
            console.error(err);
            setError(err);
        }
    };

    const getEmailReportSettings = async (): Promise<{time: string} | undefined> => {
        setLoading(true);
        if(!user) {
            setTimeout(updateUser, 2000);
            return null;
        }

        const siteSettingId = await getOrCreateSiteMetadata();
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteMetadata");

        try {
            const siteSettings = await siteSettingCollection.findOne(
                { _id:  {$oid: siteSettingId.toString()} },
            );

            const { time } = siteSettings.emailReportSettings || { time: "" };

            return { time };

        } catch (err) {
            console.error(err);
            setError(err);
        }
    };

    const setEmailReportSettings = async (time: string) => {
        setLoading(true);
        setError(null);

        if(!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const siteMetadataId = await getOrCreateSiteMetadata();

        const siteSettingCollection = mongo.db("posAccounts").collection("SiteMetadata");

        let updates: any = {};

        updates['emailReportSettings.reportTime'] = time;

        try {

            if(Object.keys(updates).length > 0) {
                await siteSettingCollection.updateOne(
                    {_id: {$oid: siteMetadataId.toString()}},
                    {$set: updates}
                );
            }
        } catch (err) {
            console.error(err);
            setError(err);
        }

        setLoading(false);
    };


    return {
        setDefaultCard,
        setEnabledCards,
        getEnabledCards,
        getDefaultCard,
        setZettleTipping,
        getZettleTipping,
        setReceiptSettings,
        getReceiptSettings,
        setEmailReportSettings,
        getEmailReportSettings,
        loading,
        error,
    };
};

export default useManageSiteSettings;



