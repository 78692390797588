import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {gql, useLazyQuery} from "@apollo/client";
import {posClient} from "../apolloClients/posClient";
import {useApp} from "@realm/react";


const useManageSumupSettings = () => {
    const app = useApp();
    const {token} = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    let mongo: { db: (arg0: string) => { (): any; new(): any; collection: { (arg0: string): any; new(): any; }; }; };

    const {user} = useContext(UserContext);

    const updateUser = () => {
        if (user) {
            mongo = user.mongoClient("mongodb-atlas");
        }
    };

    updateUser();

    const toObjectId = (idString: string): {} => {
        return {'$oid': idString};
    };

    const setSumupTokens = async (authToken: string, refreshToken: string, authTokenExpiry: number) => {

        if (!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const siteCollection = mongo.db("posAccounts").collection("Site");
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        let siteSettingId;
        const currentSiteId = app.currentUser.customData.currentSite.toString();


        const site = await siteCollection.findOne({_id: toObjectId(currentSiteId)});

        const authExpiryDate = authTokenExpiry ? new Date(Date.now() + authTokenExpiry * 1000) : null;

        siteSettingId = site.setting;

        try {
            await siteSettingCollection.updateOne(
                {_id: {$oid: siteSettingId.toString()}},
                {$set: {
                        'sumupSettings.authToken': authToken,
                        'sumupSettings.refreshToken': refreshToken
                        }}
            );
        } catch (err) {
            console.error(err);

        }
    };

    const setSumupReaders = async (
        merchantCode: string, readers: { id: string, name?: string }[]
    ) => {
        if (!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const siteCollection = mongo.db("posAccounts").collection("Site");
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        const currentSiteId = app.currentUser.customData.currentSite.toString();
        const site = await siteCollection.findOne({ _id: toObjectId(currentSiteId) });

        const siteSettingId = site?.setting;

        if (!siteSettingId) {
            console.error('Site setting ID not found.');
            return;
        }

        try {
            const existingSettings = await siteSettingCollection.findOne({ _id: { $oid: siteSettingId.toString() } });
            const existingReaders = existingSettings?.sumupSettings?.readers || [];

            // Merge or add new readers
            const updatedReaders = readers.map(newReader => {
                const existingReaderIndex = existingReaders.findIndex((reader: { id: string; }) => reader.id === newReader.id);
                if (existingReaderIndex > -1) {
                    // Update existing reader
                    existingReaders[existingReaderIndex] = { ...existingReaders[existingReaderIndex], ...newReader };
                    return existingReaders[existingReaderIndex];
                }
                // Add new reader
                return newReader;
            });

            await siteSettingCollection.updateOne(
                { _id: { $oid: siteSettingId.toString() } },
                { $set: { 'sumupSettings.readers': updatedReaders, 'sumupSettings.merchantCode': merchantCode } }
            );

        } catch (err) {
            console.error(err);
        }
    };

    const getDojoApiKey = async () => {

        if (!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const siteCollection = mongo.db("posAccounts").collection("Site");
        const siteSettingCollection = mongo.db("posAccounts").collection("SiteSetting");

        let siteSettingId;
        const currentSiteId = app.currentUser.customData.currentSite.toString();
        const site = await siteCollection.findOne({_id: toObjectId(currentSiteId)});

        siteSettingId = site.setting;

        try {
            const siteSetting = await siteSettingCollection.findOne({_id: {$oid: siteSettingId.toString()}});
            return siteSetting?.dojoSettings?.apiKey || "";
        } catch (err) {
            console.error(err);

        }

    };


    return {
        setSumupTokens,
        setSumupReaders,
        loading,
        error,
    };
};

export default useManageSumupSettings;
