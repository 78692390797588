import React from 'react';
import exportToCsv from "../helpers/ExportToCsv";
import { Button, Icon } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';
import { Row } from '@tanstack/react-table';

interface CSVExportProps {
    table: any;
    fileName: string;
    dateFrom: Date;
    dateTo: Date;
}

const CSVExport: React.FC<CSVExportProps> = ({ table, fileName, dateFrom, dateTo }) => {
    const handleExportToCsv = (): void => {
        const headers = table
            .getHeaderGroups()
            .map((x: { headers: any; }) => x.headers)
            .flat();

        console.log(headers);

        let rows = table.getCoreRowModel().rows;

        exportToCsv(fileName, headers, rows, [
            " ",
            fileName,
            `${format(dateFrom, 'dd/MM/yyyy HH:mm')} - ${format(dateTo, 'dd/MM/yyyy HH:mm')}`,
            `Exported on: ${format(new Date(), 'dd/MM/yyyy')}`,
            'seamlesspos.co.uk'
        ]);
    };

    return (
        <Button colorScheme="brand" leftIcon={<DownloadIcon />} onClick={handleExportToCsv}>
            CSV
        </Button>
    );
}

export default CSVExport;
