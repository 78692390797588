// Chakra imports
import {Flex, Stat, StatLabel, StatNumber, useColorModeValue, Text, Skeleton, Spinner, StatArrow} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import {MdArrowDropDown, MdOutlineArrowDropDown, MdOutlineArrowDropUp} from "react-icons/md";

export default function Default(props: {
	startContent?: JSX.Element;
	endContent?: JSX.Element;
	name: string;
	growth?: string | number;
	value: string | number;
	growthValue?: number;
	growthType?: string;
	isLoading?: boolean;  // Add isLoading prop
}) {
	const { startContent, endContent, name, growth, value, growthValue, isLoading, growthType } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.900';

	const growthIsNegative = growthValue && typeof growthValue === 'number' && growthValue < 0;

	//console.log("value", value);
	//	console.log("growth is negative", growthIsNegative);
	return (
		<Card py='15px' bg={'#e8eaff'}>
			<Flex my='auto' h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
				{startContent}
				<Stat my='auto' ms={startContent ? '18px' : '0px'}>
					<StatLabel
						lineHeight='100%'
						color={textColorSecondary}
						fontSize={{
							base: 'sm'
						}}>
						{name}
					</StatLabel>

					{isLoading ? (
						<Spinner />
					) : (
						<StatNumber
							color={textColor}
							fontSize={{
								base: '2xl'
							}}>
							{value}
							{growthValue ? (

							<StatArrow type={growthIsNegative ? 'decrease' : 'increase'} ml={1}/>
						) : null }
						</StatNumber>

					)}

					{growthValue ? (
						<Flex flexGrow={1} align='left'>
							<Text color={growthIsNegative ? 'red.500' : 'green.500'} fontSize='xs' fontWeight='700' me='5px'>
									{growth}
							</Text>
							<Text color='secondaryGray.600' fontSize='xs' fontWeight='400'>
								{growthType}
							</Text>
						</Flex>
					) : null}
				</Stat>
				<Flex ms='auto' w='max-content'>
					{endContent}
				</Flex>
			</Flex>
		</Card>
	);
}
