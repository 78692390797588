import {ApolloClient, ApolloLink, gql, HttpLink, InMemoryCache, useQuery} from "@apollo/client";
import {useContext} from "react";
import {UserContext} from "../../contexts/UserContext";
// Import useQuery from Apollo Client, it wraps around client.query method and provides additional features like refetch.

const GET_ORDER_MODES = gql`
    query {
        orderModes {
            _id,
            name,
        }
    }
`;

const useGetOrderModes = () => {
    const { user } = useContext(UserContext);

    const shouldSkip = !user?.accessToken;

    const { data, loading, error, refetch } = useQuery(GET_ORDER_MODES,
        {
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            },
            skip: shouldSkip,
        });

    const taxRates = data?.orderModes;

    return [{
        data: taxRates,
        isLoading: loading,
        isError: !!error,
        refetch,
    }] as const;
};

export default useGetOrderModes;
