import './assets/css/App.css';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import App from './App';
import {KindeProvider} from "@kinde-oss/kinde-auth-react";
import {AppProvider} from "@realm/react";
import {UserProvider} from "contexts/UserProvider"
import { AnalyticsBrowser } from '@segment/analytics-next'


import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://ed035aa54464c2550dd5835bf74f995d@o4506785793441792.ingest.sentry.io/4506785794621440",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),

    Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        colorScheme: "light",
        autoInject: false
    }),

    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

export const analytics = AnalyticsBrowser.load({ writeKey: '2KmduUMXgnOlbMVkGp2nWhbcweP9FEIp' });

const clientId = process.env.REACT_APP_KINDE_CLIENT_ID;
const authDomain = process.env.REACT_APP_KINDE_DOMAIN;

root.render(
    <KindeProvider
        clientId={clientId}
        domain={authDomain}
        logoutUri={window.location.protocol + '//' + window.location.host+"/auth/login?loggedOut=y"}
        redirectUri={window.location.protocol + '//' + window.location.host+"/auth/login"}
        audience={"com.seamlessserve.pos"}
        isDangerouslyUseLocalStorage={true}
    >
        <AppProvider id={'application-0-trznh'} baseUrl={'https://eu-west-1.aws.realm.mongodb.com'}>
            <UserProvider>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </UserProvider>
        </AppProvider>
    </KindeProvider>
);
