import { Box, Button, Input, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import {Key, ReactChild, ReactFragment, ReactPortal, useState} from "react";
import useGetProducts from "../../../../../api/productLibrary/getProducts";

// @ts-ignore
const ModifierProductList = ({onAdd}) => {
    const [search, setSearch] = useState("");

    const [{ data: products}] = useGetProducts();

    const filteredProducts = products?.filter((product: { name: string; }) =>
        product.name.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <Box>
            <Input placeholder="Search" value={search} onChange={e => setSearch(e.target.value)}/>
            <Box maxH="70vh" overflowY="scroll" mt={'20px'}>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Name</Th>
                            <Th isNumeric>Add</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredProducts?.map((product: { _id: Key; name: boolean | ReactChild | ReactFragment | ReactPortal; }) => (
                            <Tr key={product._id}>
                                <Td>{product.name}</Td>
                                <Td isNumeric>
                                    <Button
                                        leftIcon={<AddIcon />}
                                        colorScheme="teal"
                                        variant="solid"
                                        onClick={() => onAdd(product)}
                                    >
                                        Add
                                    </Button>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </Box>
    );
};

export default ModifierProductList
