/* eslint-disable */

import {
    Badge,
    Button,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Icon,
    Stack,
    Spacer,
    Input,
    Switch,  // Add this import
} from '@chakra-ui/react';
// Custom components
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import * as React from 'react';
// Assets
import { SearchBar } from "components/navbar/searchBar/SearchBar";

import {
    PaginationState,
    createColumnHelper,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
} from '@tanstack/react-table'
import {
    AddIcon,
    ArrowUpDownIcon,
    CheckIcon, ChevronDownIcon,
    ChevronUpIcon,
    CloseIcon, EditIcon,
    SmallCloseIcon,
    TriangleDownIcon,
    TriangleUpIcon
} from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSpring } from "react-spring";
import useGetOrderModes from "../../../../../api/productLibrary/getOrderModes";
import { formatCurrency } from "../../../../../helpers/accountCurrency";

type Product = {
    _id: string;
    name: string;
    price: string;
    btnColour: string;
    category: {
        name: string;
    }
    variantGroups: any;
    priceDetails: any;
    deletedOn?: string | null; // Field to indicate deletion status, assuming this is the structure
};

// @ts-ignore
const EditableCell = ({ value: initialValue, row: { index }, column: { id }, updateMyData }) => {
    const [value, setValue] = useState(initialValue);
    const [isEditing, setIsEditing] = useState(false);

    const show = isEditing;
    const animationProps = useSpring({
        width: show ? '100%' : '0%',
        backgroundColor: show ? '#ffffff' : '#f8f8f8',
    });

    const onChange = (e: { target: { value: any; }; }) => {
        setValue(e.target.value);
    };

    const onBlur = () => {
        setIsEditing(false);
        updateMyData(index, id, value);
    };

    const handleClick = () => {
        setIsEditing(true);
    };

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return isEditing ? (
        <Input value={value} onChange={onChange} onBlur={onBlur} autoFocus style={{ maxWidth: 80 }}/>
    ) : (
        <div onClick={handleClick}> <Text color={"navy.700"} fontSize='xl' fontWeight='500'>&pound;{(parseInt(value) / 100).toFixed(2)}</Text></div>
    );
};

export default function ProductTable(props: { tableData: any, orderModes: any, taxRates: any }) {
    const {tableData, orderModes, taxRates} = props;
    const textColor = useColorModeValue("navy.700", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const brandColor = useColorModeValue("brand.500", "brand.400");

    const navigate = useNavigate();


    console.log(taxRates);

    const updateMyData = (rowIndex: number, columnId: string, newValue: any) => {
        setData(old => old.map((row, index) => index === rowIndex ? { ...row, [columnId]: newValue } : row));
    };

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [showDeleted, setShowDeleted] = useState(false); // New state for the switch
    let defaultData = tableData.filter((item: Product) => showDeleted || !item.deletedOn);
    const [globalFilter, setGlobalFilter] = React.useState('');
    const columnHelper = createColumnHelper<Product>();

    const formatPrice = (priceDetail: any) => `£${(parseInt(priceDetail.price) / 100).toFixed(2)}`;

    const getPriceForOrderMode = (priceDetails: any, orderModeId: string, taxRates: any) => {
        const priceDetail = priceDetails?.find((detail: any) => detail.orderMode === orderModeId && !detail.modifierStep);
        if (priceDetail) {
            const priceString = formatPrice(priceDetail);
            let taxRateString = '';
            if (priceDetail.taxRate) {
                const taxRateDetail = taxRates?.find((rate: any) => rate._id === priceDetail.taxRate);
                const taxRateName = taxRateDetail ? taxRateDetail.name : 'Unknown Tax';
                taxRateString = ` Tax: ${taxRateName}`;
            }
            return `${priceString} ${taxRateString}`;
        }
        return "Not Set";
    };

    const createOrderModeColumns = (orderModes: any[], taxRates: any[]) => {
        return orderModes.map(orderMode => ({
            id: `price_${orderMode._id}`,
            header: () => (
                <Text
                    justifyContent="space-between"
                    align="center"
                    fontSize={{ sm: "14px", lg: "18px" }}
                    color="blue.700"
                >
                    PRICE ({orderMode.name})
                </Text>
            ),
            accessorFn: (row: any) => row.priceDetails,
            cell: ({ getValue }: any) => (
                <Text color="navy.700" fontSize='md' fontWeight='500' whiteSpace='pre-wrap'>
                    {getPriceForOrderMode(getValue(), orderMode._id, taxRates)}
                </Text>
            ),
        }));
    };

    const firstColumns = [
        columnHelper.accessor('name', {
            id: 'name',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '14px', lg: '16px' }}
                    color='blue.700'>
                    NAME
                </Text>
            ),
            cell: (info: any) => (
                <Flex alignItems="center">
                    <Text color={textColor} fontSize='md' fontWeight='500'>
                        {info.getValue()}
                    </Text>
                    {info.row.original.deletedOn && (
                        <Badge ml={2} colorScheme="red">Deleted</Badge>
                    )}
                </Flex>
            )
        }),
        columnHelper.accessor('category.name', {
            id: 'category',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '14px', lg: '16px'}}
                    color='blue.700'>
                    CATEGORY
                </Text>
            ),
            cell: (info) => (
                <Text color={textColor} fontSize='md' fontWeight='500'>
                    {info.getValue()}
                </Text>
            )
        })];

    const secondColumns = [
        columnHelper.accessor('btnColour', {
            id: 'btnColour',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '14px', lg: '16px'}}
                    color='blue.700'>
                    COLOUR
                </Text>
            ),
            cell: (info) => (
                <Badge
                    colorScheme={
                        info.getValue() === "Completed" ? "green" : "red"
                    }
                    background={info.getValue()}
                    color={
                        info.getValue() === "Completed" ? "green.500" : "red.500"
                    }
                    fontSize='sm'
                    fontWeight='500'>
                    &nbsp;&nbsp;
                </Badge>
            )
        }),
        columnHelper.accessor('variantGroups', {
            id: 'variantGroups',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '14px', lg: '16px'}}
                    color='blue.700'>
                    PORTIONS
                </Text>
            ), cell: (info) => (

                <Text>{(info.getValue().length ? <CheckIcon/> : "-")}</Text>
            )

        }),
        columnHelper.accessor('_id', {
            id: '_id',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '14px', lg: '16px'}}
                    color='blue.700'>
                    EDIT
                </Text>
            ),
            cell: (info) => (
                <Text color={textColor} fontSize='lg' fontWeight='500'>
                    <Link to={"/admin/library/products/" + info.getValue()}><Button leftIcon={<EditIcon />} colorScheme={"brand"} size={"sm"}>EDIT</Button></Link>
                </Text>
            )
        }),
    ];

    const orderModeColumns = createOrderModeColumns(orderModes, taxRates);

    const columns = [
        ...firstColumns,
        ...orderModeColumns,
        ...secondColumns,
    ];

    const [data, setData] = React.useState(() => [...defaultData]);
    const [{pageIndex, pageSize}, setPagination] =
        React.useState<PaginationState>({
            pageIndex: 0,
            pageSize: 25,
        });

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )
    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
            globalFilter,
            pagination,
        },
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })
    const createPages = (count: number) => {
        let arrPageCount = [];

        for (let i = 1; i <= count; i++) {
            arrPageCount.push(i);
        }

        return arrPageCount;
    };

    React.useEffect(() => {
        if (table.getState().columnFilters[0]?.id === 'name') {
            if (table.getState().sorting[0]?.id !== 'name') {
                table.setSorting([{id: 'name', desc: false}])
            }
        }
    }, [table.getState().columnFilters[0]?.id]);

    // Update data whenever "showDeleted" state changes
    useEffect(() => {
        setData(tableData.filter((item: Product) => showDeleted || !item.deletedOn));
    }, [showDeleted, tableData]);

    return (
        <Flex
            direction='column'
            w='100%'
            overflowX={{sm: "scroll", lg: "hidden"}}>
            <Flex
                align={{ sm: "flex-start", lg: "flex-start" }}
                justify={{ sm: "flex-start", lg: "flex-start" }}
                w='100%'
                px='22px'
                mb='36px'>
                <DebouncedInput
                    value={globalFilter ?? ''}
                    onChange={value => setGlobalFilter(String(value))}
                    className="p-2 font-lg shadow border border-block"
                    placeholder="Search..."
                />
                <Spacer />
                <Button colorScheme='green' rightIcon={<AddIcon />}
                        onClick={() => navigate('/admin/library/new-product')}>Add New</Button>
            </Flex>
            <Flex
                align={{ sm: "flex-start", lg: "flex-start" }}
                justify={{ sm: "flex-start", lg: "flex-start" }}
                w='100%'
                px='22px'
                mb='36px'>
                <Flex alignItems="center">
                    <Text mr='8px'>Show Deleted</Text>
                    <Switch
                        isChecked={showDeleted}
                        onChange={(e) => setShowDeleted(e.target.checked)}
                    />
                </Flex>
            </Flex>
            <Table variant='simple' color='gray.500' mb='24px'>
                <Thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                return (
                                    <Th
                                        pe='10px'
                                        borderColor={borderColor} key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder ? null : (
                                            <Flex
                                                {...{
                                                    className: header.column.getCanSort()
                                                        ? 'cursor-pointer select-none'
                                                        : '',
                                                    onClick: header.column.getToggleSortingHandler(),
                                                }}

                                                align='center'
                                                fontSize={{sm: "10px", lg: "12px"}}
                                                color='blue.700'>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {{
                                                    asc: <ChevronUpIcon aria-label="sorted ascending" ml={1}/>,
                                                    desc: <ChevronDownIcon aria-label="sorted descending" ml={1}/>,
                                                }[header.column.getIsSorted() as string] ?? null}
                                                {header.column.getCanSort() && !header.column.getIsSorted() && <ArrowUpDownIcon aria-label="sortable" ml={1}/>}

                                            </Flex>
                                        )}
                                    </Th>
                                )
                            })}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                            <Tr px="20px" key={row.id}>
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <Td key={cell.id}
                                            fontSize={{sm: "14px"}}
                                            minW={{sm: "100px", md: "150px", lg: "auto"}}
                                            borderColor={borderColor}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </Td>
                                    )
                                })}
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
            <Flex w="100%" justify="space-between" px="20px" pt="10px" pb="5px">
                <Text
                    fontSize='sm'
                    color='gray.500'
                    fontWeight='normal'
                    mb={{sm: "24px", md: "0px"}}>
                    Showing {pageSize * pageIndex + 1} to{" "}
                    {pageSize * (pageIndex + 1) <= data.length
                        ? pageSize * (pageIndex + 1)
                        : data.length}{" "}
                    of {data.length} entries
                </Text>
                <div className="flex items-center gap-2">
                    <Stack direction='row' alignSelf='flex-end' spacing='4px' ms='auto'>
                        <Button
                            variant='no-effects'
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                            transition='all .5s ease'
                            w='40px'
                            h='40px'
                            borderRadius='50%'
                            bg='transparent'
                            border='1px solid'
                            borderColor={useColorModeValue("gray.200", "white")}
                            display={
                                pageSize === 5 ? "none" : table.getCanPreviousPage() ? "flex" : "none"
                            }
                            _hover={{
                                bg: "whiteAlpha.100",
                                opacity: "0.7",
                            }}>
                            <Icon as={MdChevronLeft} w='16px' h='16px' color={textColor}/>
                        </Button>
                        {createPages(table.getPageCount()).map((pageNumber, index) => {
                            return (
                                <Button
                                    variant='no-effects'
                                    transition='all .5s ease'
                                    onClick={() => table.setPageIndex(pageNumber - 1)}
                                    w='40px'
                                    h='40px'
                                    borderRadius='50%'
                                    bg={
                                        pageNumber === pageIndex + 1 ? brandColor : "transparent"
                                    }
                                    border={
                                        pageNumber === pageIndex + 1
                                            ? "none"
                                            : "1px solid lightgray"
                                    }
                                    _hover={
                                        pageNumber === pageIndex + 1
                                            ? {
                                                opacity: "0.7",
                                            }
                                            : {
                                                bg: "whiteAlpha.100",
                                            }
                                    }
                                    key={index}>
                                    <Text
                                        fontSize='sm'
                                        color={pageNumber === pageIndex + 1 ? "#fff" : textColor}>
                                        {pageNumber}
                                    </Text>
                                </Button>
                            );
                        })}
                        <Button
                            variant='no-effects'
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                            transition='all .5s ease'
                            w='40px'
                            h='40px'
                            borderRadius='50%'
                            bg='transparent'
                            border='1px solid'
                            borderColor={useColorModeValue("gray.200", "white")}
                            display={pageSize === 5 ? "none" : table.getCanNextPage() ? "flex" : "none"}
                            _hover={{
                                bg: "whiteAlpha.100",
                                opacity: "0.7",
                            }}>
                            <Icon as={MdChevronRight} w='16px' h='16px' color={textColor}/>
                        </Button>
                    </Stack>
                </div>

            </Flex>
        </Flex>
    )
}

// A debounced input react component
function DebouncedInput({
                            value: initialValue,
                            onChange,
                            debounce = 500,
                            ...props
                        }: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <SearchBar
            {...props}
            value={value}
            onChange={(e: any) => setValue(e.target.value)}
            h='44px'
            w={{lg: "390px"}}
            borderRadius='16px'
        />
    )
}
