import {Flex, SimpleGrid, Spinner} from '@chakra-ui/react';
import Card from 'components/card/Card';
import BillingCard from "./components/billingCard";

export default function Billing() {

    const isLoading = false;

    return (
        <Flex direction='column' pt={{sm: '125px', lg: '75px'}}>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>

                    <BillingCard />

                <Card px='0px'>

                </Card>
            </SimpleGrid>


        </Flex>
    );
}
