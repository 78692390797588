import { gql, useMutation } from '@apollo/client';
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

interface VariantInput {
    name: string;
}

interface VariantGroupInput {
    name: string;
    variants: VariantInput[];
}

const ADD_VARIANT_GROUP = gql`
    mutation AddVariantGroup($newVariantGroup: VariantGroupInsertInput!) {
        insertOneVariantGroup(data: $newVariantGroup) {
            _id,
            name,
            owner_id,
            variants {
                _id,
                name,
                siteId,
                owner_id,
            },
            siteId
        }
    }
`;

const useCreateVariantGroup = () => {
    const { user } = useContext(UserContext);

    const [addVariantGroup, { loading: isLoading, error: isError }] = useMutation(
        ADD_VARIANT_GROUP,
        {
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            }
        }
    );

    const createVariantGroup = async (newVariantGroup: VariantGroupInput) => {
        await addVariantGroup({
            variables: {
                newVariantGroup: {
                    ...newVariantGroup,
                    owner_id: user.id,
                    siteId: user.customData.currentSite.toString(),
                    variants: {
                        create: newVariantGroup.variants.map(variant => ({
                            ...variant,
                            owner_id: user.id,
                            siteId: user.customData.currentSite.toString(),
                        })),
                    }
                }
            }
        });
    }

    return { createVariantGroup, isLoading, isError };
};

export default useCreateVariantGroup;
