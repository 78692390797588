import { Box, Table, Thead, Tbody, Tr, Th, Td, Spinner, Flex, Text, Button, AlertDialog, AlertDialogOverlay,
    AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, useDisclosure } from "@chakra-ui/react";
import { ReactChild, ReactFragment, ReactPortal, Key, useState, useRef, useEffect } from "react";
import useGetTaxRates from "../../../../api/productLibrary/getTaxRates";
import AddTaxRateButton from "./AddTaxRate";
import useGetOrderModes from "../../../../api/productLibrary/getOrderModes";
import AddOrderModeButton from "./AddOrderMode";
import useCreateOrderMode from "../../../../api/productLibrary/createOrderMode";

export default function OrderModeTable() {
    const [{data, isLoading: isLoadingGet, refetch}] = useGetOrderModes();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();
    const { createOrderMode, isLoading, isError } = useCreateOrderMode();
    const [isTakeOutModeExists, setTakeOutModeExists] = useState(false)

    const handleConfirm = async () => {
        await createOrderMode({name:"Take Out"});
        await refetch()
        onClose();
    };

    const handleButtonState = () => {
        const orderModeExists = data?.some((orderMode: { name: string; }) => orderMode.name === "Take Out");
        setTakeOutModeExists(orderModeExists);
    };

    useEffect(() => {
        handleButtonState();
    },[data]);

    if (isLoading) {
        return <Spinner /* spinner settings */ />
    }

    return (
        <Box overflowX="auto" ml={'20px'} mr={'20px'}>
            <Flex align={{lg: 'center'}} justify={{base: 'space-between'}} w='100%' px='20px' mb='20px'>
                <Text fontSize='xl' fontWeight='600' lineHeight='100%'>Order Modes</Text>
                {/* <AddOrderModeButton onOrderModeCreated={refetch}/> */}
            </Flex>

            <Table minW="unset" width="100%" size="md" p={'40px'} variant='striped'>
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {data != null ? data.map((mode: {
                        name: boolean | ReactChild | ReactFragment | ReactPortal;
                    }, i: Key) => (
                        <Tr key={i}>
                            <Td>{mode.name}</Td>
                        </Tr>
                    )) : <Tr><Td>No Data</Td></Tr>}
                </Tbody>
            </Table>
            <Button mt={3} colorScheme="blue" onClick={onOpen} isDisabled={isTakeOutModeExists}>
                Activate take out mode
            </Button>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Activate Mode
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to enable "Take Out" Mode? Any existing products will need to have Take Out pricing set to be available for sale in this mode.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="blue" onClick={handleConfirm} ml={3}>
                                Confirm
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    );
}
