import { useContext } from "react";
import { useMutation, gql } from '@apollo/client';
import { useApp } from "@realm/react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {UserContext} from "../../contexts/UserContext";
import {analytics} from "../../index";

const ADD_LAYOUT = gql`
    mutation AddLayout($newLayout: LayoutInsertInput!) {
        insertOneLayout(data: $newLayout) {
            _id,
            name,
            owner_id,
            btnColour,
            icon
        }
    }
`;

const ADD_CATEGORY = gql`
    mutation AddCategory($newCategory: CategoryInsertInput!) {
        insertOneCategory(data: $newCategory) {
            _id,
            name,
            owner_id,
            autoLayout,
            autoLayoutId {_id}
        }
    }
`;

interface CategoryInput {
    name: string;
    enableAutoLayout: boolean;
    icon: string;
}

const useCreateCategory = () => {
    const { getToken } = useKindeAuth();
    const app = useApp();
    const { user, refreshUserData } = useContext(UserContext);

    const [addLayout, { loading: isLayoutLoading, error: layoutError }] = useMutation(
        ADD_LAYOUT,
        {
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            }
        }
    );

    const [addCategory, { loading: isCategoryLoading, error: categoryError }] = useMutation(
        ADD_CATEGORY,
        {
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            }
        }
    );

    const isLoading = isLayoutLoading || isCategoryLoading;
    const isError = layoutError || categoryError;

    const createCategory = async (newCategory: CategoryInput) => {
        let layoutId = null;

        if(app.currentUser.customData.currentSite === undefined || app.currentUser.customData.currentSite === null) {
            await refreshUserData();
        }

        if (newCategory.enableAutoLayout) {
            const layoutResult = await addLayout({
                variables: {
                    newLayout: {
                        name: newCategory.name,
                        btnColour: "#f44336",
                        owner_id: app.currentUser.id,
                        siteId: app.currentUser.customData.currentSite.toString(),
                        layoutItems: null,
                        icon: newCategory.icon,
                        enabled: true
                    }
                }
            });

            layoutId = layoutResult.data.insertOneLayout._id;
        }

        const newcat = await addCategory({
            variables: {
                newCategory: {
                    name: newCategory.name,
                    owner_id: app.currentUser.id,
                    siteId: app.currentUser.customData.currentSite.toString(),
                    autoLayout: newCategory.enableAutoLayout,
                    autoLayoutId: newCategory.enableAutoLayout
                        ? { link: layoutId }
                        : undefined,
                }
            }
        });

        const newCategoryId = newcat?.data?.insertOneCategory?._id;

        try {
          await analytics.track('Category Created', {
              name: newCategory.name,
              siteId: app.currentUser.customData.currentSite.toString()
          })
        } catch {
            console.log('Error tracking event');
        }

        return newCategoryId;
    }

    return { createCategory, isLoading, isError };
};

export default useCreateCategory;
