import React, { useEffect, useState } from "react";
import { useDisclosure, Modal, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalOverlay,
    ModalFooter, Button, IconButton, FormLabel, FormControl, Select } from "@chakra-ui/react";
import SimpleCurrencyInputField from "../../../../../components/fields/SimpleCurrencyInputField";

type PricingModalProps = {
    selectedData: any,
    taxRates: any,
    onModalFormSubmit: (e: any) => void,
    handleChange: (e: any) => void,
    formData: any,
    setFormData: React.Dispatch<React.SetStateAction<any>> // add this line
};
const EditPricingModal: React.FC<PricingModalProps> = ({ selectedData, taxRates, onModalFormSubmit, handleChange, formData, setFormData }) => {

    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        if (selectedData) {
            onOpen();
        }

        if(selectedData === null){
            onClose();
        }
    }, [selectedData]);

    useEffect(() => {
        if (taxRates?.length > 0 && !formData?.taxRate) {
            // Set the first tax rate as default if no custom tax rate is set
            const firstTaxRateId = taxRates[0]._id;
            setFormData((prevState: any) => ({ ...prevState, taxRate: firstTaxRateId }));
            handleChange({ target: { name: 'taxRate', value: firstTaxRateId } });
        }
    }, [taxRates, formData?.taxRate, setFormData, handleChange]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            {selectedData && (
                <ModalContent>
                    <form onSubmit={onModalFormSubmit}>
                        <ModalHeader> {`Editing Price - ${selectedData?.variantName || ""} ${selectedData?.productName || ""} ${" > " +selectedData?.orderModeName || ""} `}</ModalHeader>
                        <ModalCloseButton/>
                        <ModalBody>
                            <SimpleCurrencyInputField
                                id="price"
                                label="Price"
                                defaultValue={formData.price?.toUnit ? formData.price.toUnit().toFixed(2) : ""}
                                onValueChange={(value: any) => setFormData((prev: any) => ({
                                    ...prev,
                                    price: value
                                }))}
                                name={"price"} extra={undefined} placeholder={undefined}/>

                            <SimpleCurrencyInputField
                                id="costPrice"
                                label="Cost Price"
                                defaultValue={formData.costPrice?.toUnit ? formData.costPrice.toUnit().toFixed(2) : ""}
                                onValueChange={(value: any) => setFormData((prev: any) => ({
                                    ...prev,
                                    costPrice: value
                                }))}
                                name={"costPrice"} extra={undefined} placeholder={undefined}/>

                            <FormControl>
                                <FormLabel htmlFor="taxRate">Tax Rate</FormLabel>
                                <Select
                                    id="taxRate"
                                    name="taxRate"
                                    placeholder="Select Tax Rate"
                                    onChange={handleChange}
                                    value={formData.taxRate || ''}
                                >
                                    <option key={"NT"} value={"NT"}>
                                        No Tax
                                    </option>
                                    {taxRates.map((tax: { _id: string | number | readonly string[]; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; rate: number; }) => (
                                        <option key={String(tax._id)} value={tax._id}>
                                            {tax.name} - {tax.rate / 100}%
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} type="submit">Save</Button>
                            <Button variant="ghost" onClick={onClose}>Cancel</Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            )}
        </Modal>
    )
}

export default EditPricingModal;
