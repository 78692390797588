import { gql, useMutation } from '@apollo/client';
import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';

interface LayoutItemUpdateInput {
    order: number;
    product?: string;
    type: string;
}

interface UpdateLayoutInput {
    layoutId: string;
    layoutItems: LayoutItemUpdateInput[];
}

const UPDATE_LAYOUT = gql`
    mutation UpdateLayout($layoutId: ObjectId!, $layoutItems: [LayoutItemUpdateInput!]!) {
        updateOneLayout(query: {_id: $layoutId}, set: {layoutItems: $layoutItems}) {
            _id,
            layoutItems {
                order
                type
            },
        }
    }
`;

const useUpdateLayout = () => {
    const { user } = useContext(UserContext);
    const [updateLayout, { data, loading, error }] = useMutation(UPDATE_LAYOUT, {
        context: {
            headers: {
                Authorization: `Bearer ${user?.accessToken}`,
            },
        },
    });

    const saveLayout = async (layout: UpdateLayoutInput) => {
        const reshapedItems = layout.layoutItems.map((item, index) => ({
            order: index,
            type: item.type,
            product: (item.type === "product" && item.product) || null, // provide product ID if type is "product", otherwise null
        }));
        await updateLayout({ variables: { layoutId: layout.layoutId, layoutItems: reshapedItems } });
    };

    return { saveLayout, data, loading, error };
};

export default useUpdateLayout;
