import { Box, Button, Input, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import {Key, ReactChild, ReactFragment, ReactPortal, useState} from "react";

// @ts-ignore
const ProductList = ({products, onAdd, gridItems}) => {
    const [search, setSearch] = useState("");

    const filteredProducts = products?.filter((product: { name: string; }) =>
        product.name.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <Box>
            <Input placeholder="Search" value={search} onChange={e => setSearch(e.target.value)}/>
            <Box maxH="100vh" overflowY="scroll" mt={'20px'}>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Product Name</Th>
                        <Th isNumeric>Add</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {filteredProducts?.map((product: { _id: Key; name: boolean | ReactChild | ReactFragment | ReactPortal; }) => (
                        <Tr key={product._id} m={0}>
                            <Td>{product.name}</Td>
                            <Td isNumeric>
                                <Button
                                    leftIcon={<AddIcon />}
                                    size='sm'
                                    colorScheme="brand"
                                    variant="solid"
                                    isDisabled={gridItems.includes(product._id)}
                                    onClick={() => onAdd(product)}
                                >
                                    Add
                                </Button>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            </Box>
        </Box>
    );
};

export default ProductList
