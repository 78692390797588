import { gql, useMutation } from '@apollo/client';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useApp } from "@realm/react";
import {useContext} from "react";
import {UserContext} from "../../contexts/UserContext";

interface OrderModeInput {
    name: string;
}

const ADD_ORDER_MODE = gql`
  mutation AddOrderMode($newOrderMode: OrderModeInsertInput!) {
    insertOneOrderMode(data: $newOrderMode) {
      _id,
      name,
      owner_id,
        def,
        siteId
    }
  }
`;

const useCreateOrderMode = () => {
    const { getToken } = useKindeAuth();
    const app = useApp();

    const { user } = useContext(UserContext);

    const [addOrderMode, { loading: isLoading, error: isError }] = useMutation(
        ADD_ORDER_MODE,
        {
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            }
        }
    );

    const createOrderMode = async (newOrderMode: OrderModeInput) => {
        await addOrderMode({
            variables: {
                newOrderMode: {
                    ...newOrderMode,
                    def: false,
                    owner_id: app.currentUser.id,
                    siteId: app.currentUser.customData.currentSite.toString(),
                }
            }
        });
    }

    return { createOrderMode, isLoading, isError };
};

export default useCreateOrderMode;
