// useAppNavigation.ts
import {useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {UserContext} from "../contexts/UserContext";

export function useAppNavigation() {
    const navigate = useNavigate();
    const { isAppSession } = useContext(UserContext);

    useEffect(() => {
        if(isAppSession) {
            (window as any).seamlessNavigate = (route: string) => {
                navigate(route);
                window.scrollTo(0, 0);
            };

            return () => {
                (window as any).seamlessNavigate = undefined;
            };
        }

        return () => {};  // Return a cleanup function even if isAppSession is not true
    }, [navigate, isAppSession]);
}
