import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import useGetLayout from '../../../../api/productLibrary/getLayout';
import {useNavigate, useParams} from "react-router-dom";
import {
    Box,
    Flex,
    Text,
    Image,
    Spacer,
    Button,
    useToast,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, useDisclosure
} from "@chakra-ui/react";
import useGetProducts from "../../../../api/productLibrary/getProducts";
import ProductList from "./components/ProductList";
import useUpdateLayout from "../../../../api/productLibrary/updateLayout";
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import Bar from "../../../../assets/svg/Bar.svg";
import PlusIcon from "../../../../assets/svg/plusIcon.svg";
import { getEmptyImage } from 'react-dnd-html5-backend';
import {CloseIcon} from "@chakra-ui/icons";

const ItemTypes = {
    ITEM: 'item',
};

//@ts-ignore
const DraggableItem = ({id, name, color, index, moveItem, width, onRemove}) => {
    const [{isDragging}, drag, preview] = useDrag({
        type: ItemTypes.ITEM,
        item: {id, index},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        previewOptions: {
            anchorX: 0.6,
            anchorY: 0.6,
            captureDraggingState: true,
        },
    });

    const style: React.CSSProperties = {
        backgroundColor: color,
        opacity: isDragging ? 0.5 : 1,
        height: '80px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative', // type-safe way to position button
        justifyContent: 'left',
        color: '#fff',
        cursor: 'move',
        borderRadius: '10px',
    };

    useLayoutEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    return (
        <div ref={drag} style={style}>
            <Image src={Bar} alt="Bar" marginRight={2} marginLeft={3} />
            {name}
            <Spacer/>
            <Image src={PlusIcon} alt="Plus" marginRight={2} boxSize="25px"/>
            <Button
                onClick={() => onRemove(index)}
                colorScheme="yellow"
                size="xs"
                position="absolute"
                top="-2px"
                right="-4px"
                borderRadius="full"
                padding={0}
                minWidth="auto"
                width="20px"
                height="20px"
            >
                <CloseIcon w={2} h={2} />
            </Button>

        </div>
    );
};

//@ts-ignore
const DroppableCell = ({index, moveItem, children, width}) => {
    const [, drop] = useDrop({
        accept: ItemTypes.ITEM,
        //@ts-ignore
        drop: (item) => moveItem(item.index, index),
    });

    return (
        <div ref={drop} style={{height: '80px', width: width, border: '1px dotted gray', marginLeft:'10px', borderRadius:'10px'}}>
            {children}
        </div>
    );
};

const baseWidth = 120;  // base width of cells

const generateGridTemplateColumns = (row: number) => {
    if (row % 2 === 0) {
        return `repeat(4, ${baseWidth}px)`;
    }
    return `repeat(5, ${baseWidth / 5 * 4}px)`;  // adjust 5 cells to match 4 cell width
};

// @ts-ignore
const Row = ({children}) => (
    <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
    }}>
        {children}
    </div>
);

// ...

// Determine how many cells per row
const cellsPerRow = (row: number) => row % 2 === 0 ? 4 : 5;

const LayoutPage = () => {
    const {layoutId} = useParams();
    const [{data, isLoading, isError}] = useGetLayout(layoutId);
    const { saveLayout, data: savedLayoutData, loading, error } = useUpdateLayout();

    const [grid, setGrid] = useState([]);

    const [{ data: productData}] = useGetProducts();

    const toast = useToast();

    const [itemToRemove, setItemToRemove] = useState<number | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef<HTMLButtonElement>(null);

    let navigate= useNavigate();

    const handleRemoveClick = (index: number) => {
        setItemToRemove(index);
        onOpen();
    };

    const confirmRemoveItem = () => {
        if (itemToRemove !== null) {
            const newGrid = [...grid];
            newGrid[itemToRemove] = null; // set the grid slot to null
            setGrid(newGrid);
            setItemToRemove(null);
            onClose();
        }
    };

    const goBack = async () => {
        navigate('/admin/library/layouts')
    }

    const handleSubmit = async () => {
        const newGrid = [...grid]; // Copy the grid array

        let lastProductIndex = newGrid.length - 1;

        // Find the last product in the grid
        while (lastProductIndex >= 0 && (!newGrid[lastProductIndex] || newGrid[lastProductIndex].type === 'placeholder')) {
            lastProductIndex--;
        }

        // Convert all null spaces up until the last product to placeholders
        for (let i = 0; i <= lastProductIndex; i++) {
            if (!newGrid[i]) {
                newGrid[i] = {type: 'placeholder'};
            }
        }

        // Remove anything after the last product
        newGrid.length = lastProductIndex + 1;

        const reshapedNewGrid = newGrid.map((item, index) => {
            const isProductType = item && item._id;
            return {
                order: index,
                type: isProductType ? 'product' : 'placeholder',
                product: isProductType ? item._id : null,
            };
        });


        try {
            await saveLayout({layoutId, layoutItems: reshapedNewGrid});
            toast({
                title: "Layout Saved",
                description: "This layout has been saved successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top-right"
            })
         navigate('/admin/library/layouts')
        } catch {
            console.error('Error while updating layout:', error);
            toast({
                title: "Error saving layout",
                description: "There has been an error saving this layout, please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right"
            })
        }


    };

    const handleAddProduct = (product: any) => {
        const newGrid = [...grid];
        const index = newGrid.findIndex(item => item === null);
        if (index !== -1) {
            newGrid[index] = product;
            setGrid(newGrid);
        }
    };

    const gridItems = grid?.map(item => item?._id);

    useEffect(() => {
        const layouts = data?.layoutItems?.map((item: { linkedProduct: any; }) => item.linkedProduct) || [];
        const initialGrid = Array.from({length: 60}, (_, i) => layouts[i] || null);
        setGrid(initialGrid);
    }, [data]);

    //@ts-ignore
    const moveItem = (fromIndex: number, toIndex: number) => {
        const newGrid = [...grid];
        const itemToMove = newGrid[fromIndex];

        // Remove item from its old position
        newGrid.splice(fromIndex, 1);

        // Insert item at its new position
        newGrid.splice(toIndex, 0, itemToMove);

        // Update the state
        setGrid(newGrid);
    };

    let cellIndex = 0;
    let runningIndex = 0;


    return (
        <Box pt={{base: '80px', md: '80px', xl: '80px'}}>
            <Flex>
                <Flex direction='column' flex="1" mr="10">
                    <ProductList products={productData} onAdd={handleAddProduct} gridItems={gridItems} />
                </Flex>

                <DndProvider options={HTML5toTouch}>
                    <Flex direction='column' flex="3">
                        <Flex direction="row" justify="space-between" pb={5}>
                            <Text>Drag buttons to different positions on the layout</Text>
                            <Spacer/>
                            <Button
                                colorScheme="red"
                                variant="solid"
                                onClick={goBack}
                                mr={2}
                            >
                                Cancel Editing
                            </Button>
                            <Button
                                colorScheme="brand"
                                variant="solid"
                                onClick={handleSubmit}
                            >
                                Save Layout
                            </Button>
                        </Flex>
                        <Flex direction='column' flex="3" style={{backgroundColor: "#01011E", borderRadius: "25px"}}
                              p={5} pt={25}>
                            {Array.from({length: 12}).map((_, i) => {
                                const cellsInRow = cellsPerRow(i);

                                const rowComponent = (
                                    <Row key={`Row-${i}`}>
                                        {grid.slice(runningIndex, runningIndex + cellsInRow).map((item, j) => {
                                            const gridIndex = runningIndex + j;
                                            return (
                                                <DroppableCell key={gridIndex} index={gridIndex} moveItem={moveItem}
                                                               width={`${100 / cellsInRow}%`}>
                                                    {item && (item.type !== 'placeholder') &&
                                                        <DraggableItem id={item._id} name={item.name}
                                                                       color={item.btnColour}
                                                                       index={gridIndex}
                                                                       moveItem={moveItem}
                                                                       width={`${100 / cellsInRow}%`}
                                                                        onRemove={handleRemoveClick}/> }
                                                </DroppableCell>
                                            )
                                        })}
                                    </Row>
                                );

                                runningIndex += cellsInRow;
                                return rowComponent;
                            })}
                        </Flex>

                    </Flex>
                </DndProvider>
            </Flex>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader>Remove Product</AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure you want to remove this product from the layout?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={confirmRemoveItem} ml={3}>
                                Remove
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

        </Box>
    );
};

export default LayoutPage;
