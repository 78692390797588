import { gql, useQuery } from "@apollo/client";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

const GET_MODIFIER_GROUP_BY_ID = gql`
    query GetModifierGroupById($_id: ObjectId!) {
        modifierGroup(query: { _id: $_id }) {
            steps {
                allowedChoices
                modifiers {
                    _id
                    name
                    price
                    product {
                        name
                        _id
                        priceDetails {
                            orderMode
                            price
                            taxRate
                            modifierStep
                            
                        }
                    }
                    type
                    category {
                        name
                        _id
                    }
                }
                step
                _id
                type
            }
            _id
            name
        }
    }
`;

const useGetModifierGroupById = (_id: string) => {
    const { user } = useContext(UserContext);

    const shouldSkip = !user?.accessToken || !_id;

    const { data, loading, error, refetch } = useQuery(GET_MODIFIER_GROUP_BY_ID,
        {
            variables: { _id }, // Change here
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            },
            skip: shouldSkip,
        });

    const modifierGroup = data?.modifierGroup;

    return [{
        data: modifierGroup,
        isLoading: loading,
        isError: !!error,
        refetch,
    }] as const;
};

export default useGetModifierGroupById;
