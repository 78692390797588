// hooks/useFetchTables.ts
import { useCallback, useContext, useState } from 'react';
import { useApp } from '@realm/react';
import { UserContext } from '../../contexts/UserContext';
import { TableShape } from '../../views/admin/tables/components/types';

export interface TablePosition {
    id: string;
    positionX: number;
    positionY: number;
    shape: TableShape;
    tableNumber: string;
}

export const useFetchTables = () => {
    const app = useApp();
    const { user } = useContext(UserContext);
    const [tables, setTables] = useState<TablePosition[]>([]);

    const fetchTables = useCallback(async (tableAreaId: string) => {
        if (!user || !app.currentUser) {
            console.error("User is not authenticated");
            return;
        }

        try {
            const mongo = user.mongoClient('mongodb-atlas');
            const tableCollection = mongo.db('pos').collection('Table');

            const tables = await tableCollection.find({ tableArea: {$oid: tableAreaId} });
            const mappedTables = tables.map((table: { _id: { toString: () => any; }; positionX: any; positionY: any; shape: any; tableNumber: any; }) => ({
                id: table._id.toString(),
                positionX: table.positionX,
                positionY: table.positionY,
                shape: table.shape,
                tableNumber: table.tableNumber,
            }));
            setTables(mappedTables);
        } catch (error) {
            console.error("Failed to fetch tables: ", error);
        }
    }, [user, app]);

    return { tables, fetchTables };
};
