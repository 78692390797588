// Chakra imports
import {
    Flex,
    Box,
    Icon,
    Select,
    Text,
    Spinner,
    useColorModeValue,
} from '@chakra-ui/react';
import LineChart from 'components/charts/LineChart';

// Custom components
import Card from 'components/card/Card';
import {
    lineChartDataOverallRevenue,
    lineChartOptionsOverallRevenue,
} from 'variables/charts';

// Assets

import {useGetSalesByHour} from "../../../../../api/reports/getSalesByHour";
import {useEffect, useState} from "react"
import {formatCurrency} from "../../../../../helpers/accountCurrency";
import HeatmapChart from "../../../../../components/charts/HeatMap";


export default function SalesByHourHeatmap(props: { [x: string]: any }) {
    const { ...rest } = props;
    //eslint-disable-next-line

    const [chartData, setChartData] = useState([]);
    const [chartOptions, setChartOptions] = useState(lineChartOptionsOverallRevenue);
    const [quickDate, setQuickDate] = useState("this week");
    const [shouldRenderChart, setShouldRenderChart] = useState(false);


    const [{ data, isLoading, isError }] = useGetSalesByHour(quickDate);

    useEffect(() => {
        if (data) {
            setChartData(data);
        }
    }, [data]);

    useEffect(() => {
        if (!isLoading) {
            setShouldRenderChart(true);
        } else {
            setShouldRenderChart(false);
        }
    }, [isLoading]);

    // Chakra Color Mode
    const textColor = useColorModeValue('secondaryGray.900', 'white');

    return (
        <Card
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            w="100%"
            mb={{ base: '20px', lg: '0px' }}
            {...rest}
        >
            <Flex justify="space-between" px="20px" pt="5px" w="100%">
                <Flex align="center" w="100%">
                    <Flex flexDirection="column" me="20px">
                        <Text
                            color={textColor}
                            fontSize="34px"
                            fontWeight="700"
                            lineHeight="100%"
                        >
                            Sales By Hour
                        </Text>

                    </Flex>
                    <Flex align="center">
                        {/* <Icon as={RiArrowUpSFill} color="green.500" me="2px" />
            <Text color="green.500" fontSize="sm" fontWeight="700">
              +2.45%
            </Text> */}
                    </Flex>
                </Flex>
                <Select
                    fontSize="sm"
                    variant="subtle"
                    defaultValue="this week"
                    width="unset"
                    fontWeight="700"
                    onChange={(e) => setQuickDate(e.target.value)}
                >
                    <option value="this week">This Week</option>
                    <option value="last week">Last Week</option>
                    <option value="this month">This Month</option>
                    <option value="last month">Last Month</option>
                </Select>
            </Flex>
            <Box minH="420px" mt="auto" w="100%" alignItems="center" justifyContent="center">
                {
                    isLoading ?
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                            position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)"
                        />
                        :
                        (shouldRenderChart &&
                            <HeatmapChart salesDataPerHour={chartData} />
                        )
                }
            </Box>
        </Card>
    );
}
