import React, {useRef, useState} from 'react';
import {
    Box,
    Text,
    Button,
    IconButton,
    Select,
    Stack,
    VStack,
    HStack,
    Circle,
    useColorModeValue,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialog, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure
} from '@chakra-ui/react';
import {CloseIcon} from '@chakra-ui/icons';
import ModifierItem from "./ModifierItem";
import useManageModifierGroups from "../../../../../api/productLibrary/manageModifierGroups";
import ModifierProductList from "./ModifierProductList";


// Define your types
interface OrderMode {
    __typename: string;
    _id: string;
    name: string;
}

interface PriceDetail {
    __typename: string;
    orderMode: string | null;
    price: string;
    taxRate: string;
    modifierStep: null | string;
    orderModeName?: string;
}

interface Product {
    __typename: string;
    name: string;
    _id: string;
    priceDetails: PriceDetail[];
}

interface Modifier {
    __typename: string;
    _id: string;
    name: string;
    price: null | string;
    product: Product;
    type: string;
    category: null | string;
}

interface ModifierItemProps {
    name: string;
    stepId: string;
    modifierId: string;
    refetch: () => void;
    mb: string;
    bg: string;
    hilightColour: string;
    type: string;
    priceDetails: PriceDetail[];
}

const ProductModifierGroupCard = (props: {
    step: number;
    stepType: string;
    modifierItems: [];
    allowedChoices: number;
    modifierStep: string;
    modifierGroupId: string;
    refetch: any;
    orderModes: any;
    taxRates: any;
}) => {
    const {step, stepType, modifierItems, allowedChoices, modifierStep, modifierGroupId, refetch, orderModes, taxRates} = props;
    const options = Array.from({length: 10}, (_, i) => i + 1);

    const [isAlertOpen, setAlertOpen] = useState(false);
    const cancelRef = useRef();
    const onCloseAlert = () => setAlertOpen(false);

    const onDelete = () => {
        deleteStep(modifierStep, modifierGroupId).then(() => refetch());
        setAlertOpen(false);
    };

    const {isOpen: isProductOpen, onOpen: onProductOpen, onClose: onProductClose} = useDisclosure();

    const handleAddProduct = (product: any) => {

        addModifier(modifierStep, {name: product.name, type: "PRODUCT", product: {'$oid': product._id}}).then(() => {
            refetch();
            onProductClose();
        })

    };

    console.log("mod items", modifierItems);
    console.log("modordermode", orderModes);

    const enhanceModifierItems = (
        modifiers: Modifier[],
        orderModes: OrderMode[] | undefined // handle undefined orderModes
    ): Modifier[] => {
        if (!orderModes || orderModes.length === 0) {
            // Return the original modifiers if orderModes are undefined or empty
            return modifiers;
        }

        return modifiers.map((modifier) => {
            if (modifier.product && Array.isArray(modifier.product.priceDetails)) {
                const updatedPriceDetails = modifier.product.priceDetails.map((priceDetail) => {
                    const orderMode = orderModes.find((o) => o._id === priceDetail.orderMode);
                    return {
                        ...priceDetail,
                        orderModeName: orderMode ? orderMode.name : null,
                    };
                });

                return {
                    ...modifier,
                    product: {
                        ...modifier.product,
                        priceDetails: updatedPriceDetails,
                    },
                };
            }

            return modifier;
        });
    };

    const enhancedModifierItems = enhanceModifierItems(modifierItems, orderModes);

    const {addModifier, setAllowedChoices, deleteStep} = useManageModifierGroups();

    const handleSelectChange = (e: { target: { value: any; }; }) => {
        setAllowedChoices(modifierStep, Number(e.target.value));
    };


    return (
        <Box p={4} borderWidth="1px" borderRadius="xl" backgroundColor="#E2E7F0">
            <HStack justifyContent="space-between">
                <HStack spacing={4}>
                    <Circle size="48px" bg="#212266" color="white">
                        <Text fontSize="28px" fontWeight={"700"}>{step}</Text>
                    </Circle>
                    <VStack align="flex-start" spacing={0}>
                        <Text fontSize="lg" fontWeight="bold">Product Step</Text>
                        <Text fontSize="sm">Include an additional chargeable step for extras like sides or
                            sauces.</Text>
                    </VStack>
                </HStack>
                {step !== 1 &&
                    <Button colorScheme="red" variant="outline" size="md" onClick={() => setAlertOpen(true)}>
                        Delete
                    </Button>
                }
            </HStack>
            <VStack align="stretch" mt={4} backgroundColor={"white"} p={2} borderRadius="lg" pt={4}>

                {enhancedModifierItems && enhancedModifierItems.map((modifier: {
                        name: string;
                        _id: string;
                        type: string;
                        product: { name: string, priceDetails: any, _id: any };
                    }, index: React.Key) =>
                        <ModifierItem key={modifier._id}
                                      name={(modifier.type === "PRODUCT") ? modifier.product.name : modifier.name}
                                      stepId={modifierStep} modifierId={modifier._id} refetch={refetch} mb={"10px"}
                                      bg={"#F6F7F9"} hilightColour={"#212266"} type={"product"} priceDetails={modifier.product.priceDetails} orderModes={orderModes} taxRates={taxRates} productId={modifier.product._id} />
                )}

                <Button variant="outline" mt={2} borderStyle={'dashed'} py={'30px'} color={'#3865FF'}
                        borderColor={'#3865FF'} onClick={() => onProductOpen()}>Add Product</Button>
            </VStack>
            <Box mt={4} p={4} bg="white" borderRadius="xl">
                <HStack justifyContent="space-between">
                    <Text>Go to the next step after the user has made</Text>
                    <Select
                        defaultValue={allowedChoices}
                        width="auto"
                        height="36px"
                        borderWidth="0px"
                        borderRadius="md"
                        paddingRight="10px"
                        paddingLeft="8px"
                        background="transparent"
                        color="#3865FF"
                        fontWeight='700'
                        textAlign='right'
                        focusBorderColor="transparent"
                        onChange={handleSelectChange}
                    >
                        <option value="-1">Unlimited choices</option>
                        {options.map((option, index) => (
                            <option key={index} value={option}>{option} {option === 1 ? 'choice' : 'choices'}</option>
                        ))}
                    </Select>
                </HStack>
            </Box>

            <AlertDialog
                leastDestructiveRef={cancelRef}
                isOpen={isAlertOpen}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Step
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You cannot undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={onDelete} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <Modal isOpen={isProductOpen} onClose={onProductClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Add Product</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <ModifierProductList onAdd={handleAddProduct}/>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={() => onProductClose}>
                            Save
                        </Button>
                        <Button onClick={onProductClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </Box>
    );
};

export default ProductModifierGroupCard;
