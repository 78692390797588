import {ApolloClient, ApolloLink, gql, HttpLink, InMemoryCache, useQuery} from "@apollo/client";
import {useContext} from "react";
import {UserContext} from "../../contexts/UserContext";
// Import useQuery from Apollo Client, it wraps around client.query method and provides additional features like refetch.

const GET_TAX_RATES = gql`
    query {
        taxRates {
            _id,
            name,
            rate
        }
    }
`;

const useGetTaxRates = () => {
    const { user } = useContext(UserContext);

    const shouldSkip = !user?.accessToken;

    const { data, loading, error, refetch } = useQuery(GET_TAX_RATES,
        {
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            },
            skip: shouldSkip,
        });

    const taxRates = data?.taxRates;

    return [{
        data: taxRates,
        isLoading: loading,
        isError: !!error,
        refetch,   // Return refetch function.
    }] as const;
};

export default useGetTaxRates;
