// components/tableAreaEditor.tsx
import React, { useState, useEffect } from 'react';
import { Box, Flex, Text, Button, useToast } from '@chakra-ui/react';
import { useDrop } from 'react-dnd';
import Table from './table';
import TableInputModal from './tableInputModal';
import { TableShape, TablePosition, TableArea } from './types';
import { useSaveTables } from '../../../../api/tables/useSaveTables';
import { useFetchTables } from '../../../../api/tables/useFetchTables';
import { ObjectId } from 'bson';

interface TableAreaEditorProps {
    selectedTableArea: TableArea | null;
}

const TableAreaEditor: React.FC<TableAreaEditorProps> = ({ selectedTableArea }) => {
    const [tables, setTables] = useState<TablePosition[]>([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const { saveTables } = useSaveTables();
    const { tables: fetchedTables, fetchTables } = useFetchTables();
    const toast = useToast();

    const TABLE_AREA_WIDTH = 960;
    const TABLE_AREA_HEIGHT = 720;
    const TABLE_SIZE = 70;
    const GRID_SIZE = 40;

    const [, drop] = useDrop({
        accept: 'TABLE',
        drop: (item: TablePosition, monitor) => {
            const delta = monitor.getDifferenceFromInitialOffset();
            if (delta) {
                let positionX = Math.round(item.positionX + delta.x);
                let positionY = Math.round(item.positionY + delta.y);
                let safePosition = handleCollision(item.id, positionX, positionY);
                safePosition = applyBoundaryConstraints(safePosition.x, safePosition.y);
                moveTable(item.id, safePosition.x, safePosition.y);
            }
            return undefined;
        },
    });

    useEffect(() => {
        if (selectedTableArea) {
            fetchTables(selectedTableArea._id.toString());
        }
    }, [selectedTableArea, fetchTables]);

    useEffect(() => {
        if (fetchedTables) {
            setTables(fetchedTables);
        }
    }, [fetchedTables]);

    const snapToGrid = (value: number) => {
        return Math.round(value / GRID_SIZE) * GRID_SIZE;
    };

    const moveTable = (id: string, positionX: number, positionY: number) => {
        const constrainedPosition = applyBoundaryConstraints(positionX, positionY);
        setTables(prevTables =>
            prevTables.map(table =>
                table.id === id
                    ? { ...table, positionX: snapToGrid(constrainedPosition.x), positionY: snapToGrid(constrainedPosition.y) }
                    : table
            )
        );
    };

    const addTables = (shape: TableShape, startNumber: number, count: number) => {
        setTables(prevTables => {
            const newTables = [];
            for (let i = 0; i < count; i++) {
                const tableNumber = (startNumber + i).toString();
                if (prevTables.some(table => table.tableNumber === tableNumber)) {
                    toast({
                        title: `Duplicate Table Number`,
                        description: `Table number ${tableNumber} already exists.`,
                        status: 'warning',
                        duration: 5000,
                        isClosable: true,
                    });
                    continue;
                }
                const positionX = 50 + ((prevTables.length + i) % 10) * 100;
                const positionY = 150 + Math.floor((prevTables.length + i) / 10) * 100;
                let safePosition = handleCollision(new ObjectId().toString(), positionX, positionY);
                safePosition = applyBoundaryConstraints(safePosition.x, safePosition.y);
                newTables.push({
                    id: new ObjectId().toString(),
                    positionX: snapToGrid(safePosition.x),
                    positionY: snapToGrid(safePosition.y),
                    shape,
                    tableNumber,
                });
            }
            return [...prevTables, ...newTables];
        });
    };

    const handleCollision = (id: string, positionX: number, positionY: number) => {
        const SPACING = 10;

        let newPositionX = positionX;
        let newPositionY = positionY;

        let hasCollision = (x: number, y: number) => {
            return tables.some(table => {
                if (table.id !== id) {
                    const isColliding = !(
                        x + TABLE_SIZE + SPACING < table.positionX || // Right side check
                        x > table.positionX + TABLE_SIZE + SPACING || // Left side check
                        y + TABLE_SIZE + SPACING < table.positionY || // Bottom check
                        y > table.positionY + TABLE_SIZE + SPACING    // Top check
                    );
                    return isColliding;
                }
                return false;
            });
        };

        let directions = [
            { x: 0, y: -SPACING },  // Move up
            { x: SPACING, y: 0 },   // Move right
            { x: 0, y: SPACING },   // Move down
            { x: -SPACING, y: 0 }   // Move left
        ];

        for (let i = 1; i <= 10; i++) { // Limit checks to avoid infinite loops
            for (let dir of directions) {
                let candidateX = newPositionX + dir.x * i;
                let candidateY = newPositionY + dir.y * i;
                if (!hasCollision(candidateX, candidateY)) {
                    return { x: candidateX, y: candidateY };
                }
            }
        }

        // If no appropriate position, return original intended position
        return { x: newPositionX, y: newPositionY };
    };

    const applyBoundaryConstraints = (positionX: number, positionY: number) => {
        const minX = 0;
        const minY = 0;
        const maxX = TABLE_AREA_WIDTH - TABLE_SIZE;
        const maxY = TABLE_AREA_HEIGHT - TABLE_SIZE;

        const constrainedX = Math.max(minX, Math.min(maxX, positionX));
        const constrainedY = Math.max(minY, Math.min(maxY, positionY));

        return { x: constrainedX, y: constrainedY };
    };

    const handleSave = () => {
        if (selectedTableArea) {
            saveTables(tables, selectedTableArea._id.toString());
            toast({
                title: `Table area saved`,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

        } else {
            console.error("No table area selected");
        }
    };

    return (
        <Flex
            alignItems="flex-start"
            justifyContent="flex-start"
            w="100%"
            h="100%"
            position="relative"
            px={4}
            ref={drop}
            direction="column"
        >
            {selectedTableArea ? (
                <Text color='white' mb={4}>Editing: {selectedTableArea.name}</Text>
            ) : (
                <Text color='white' mb={4}>Select a table area to start editing</Text>
            )}
            <Flex mb={4}>
                <Button colorScheme={"green"} onClick={() => setModalOpen(true)} mr={4}>Add Tables</Button>
                <Button colorScheme={"green"} onClick={handleSave}>Save</Button>
            </Flex>
            <Box
                w={`${TABLE_AREA_WIDTH}px`}
                h={`${TABLE_AREA_HEIGHT}px`}
                backgroundColor="#01011E"
                position="relative"
                _before={{
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `linear-gradient(#1D1D37 1px, transparent 1px), 
                            linear-gradient(to right, #1D1D37 1px, transparent 1px)`,
                    backgroundSize: `${GRID_SIZE}px ${GRID_SIZE}px`,
                    opacity: 0.6,
                }}
            >
                {tables.map(table => (
                    <Table key={table.id} {...table} moveTable={moveTable} />
                ))}
            </Box>
            <TableInputModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onSave={addTables}
            />
        </Flex>
    );
};

export default TableAreaEditor;
