import { gql, useMutation } from '@apollo/client';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useApp } from "@realm/react";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

interface LayoutInput {
    name: string;
    btnColour: string;
    icon: string;
}

const ADD_LAYOUT = gql`
    mutation AddLayout($newLayout: LayoutInsertInput!) {
        insertOneLayout(data: $newLayout) {
            _id,
            name,
            btnColour,
            owner_id,
            siteId
        }
    }
`;

const useCreateLayout = () => {

    const app = useApp();
    const { user } = useContext(UserContext);

    const [addLayout, { loading: isLoading, error: isError }] = useMutation(
        ADD_LAYOUT,
        {
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            }
        }
    );

    const createLayout = async (newLayout: LayoutInput) => {
        await addLayout({
            variables: {
                newLayout: {
                    ...newLayout,
                    owner_id: app.currentUser.id,
                    siteId: app.currentUser.customData.currentSite.toString(),
                    enabled: true
                }
            }
        });
    }

    return { createLayout, isLoading, isError };
};

export default useCreateLayout;
