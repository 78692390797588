import { useApp } from "@realm/react";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { ObjectId } from "bson";
import {PrintRoute_categories} from "./getPrintRoutes";

export type Printer = {
    _id: ObjectId;
    name?: string;
};

const useGetPrinters = () => {
    const app = useApp();
    const { user } = useContext(UserContext);
    const [printers, setPrinters] = useState<Printer[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const dataFetched = useRef(false);

    useEffect(() => {
        if (dataFetched.current) return;

        const fetchPrinters = async () => {
            setLoading(true);
            if (!user) {
                setLoading(false);
                return;
            }

            try {
                const mongo = user.mongoClient("mongodb-atlas");
                const printRouteCollection = mongo.db("pos").collection("Printer");
                const currentSiteId = app.currentUser.customData.currentSite.toString();
                const fetchedPrinters= await printRouteCollection.find({ siteId: currentSiteId });
                setPrinters(fetchedPrinters);
                dataFetched.current = true; // Mark data as fetched
            } catch (err) {
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };

        fetchPrinters();
    }, [user, app]);

    return { printers, loading, error };
};

export default useGetPrinters;
