import {useContext, useEffect} from 'react';
import {gql, useQuery} from "@apollo/client";
import {UserContext} from "../../contexts/UserContext";

const GET_CATEGORIES = gql`
    query Categories($query: CategoryQueryInput) {
        categories(query: $query) {
            name
            _id,
            autoLayoutId {
                _id,
            }
        }
    }
`;

const useGetCategories = () => {
    const { user } = useContext(UserContext);

    useEffect(() => {

        if(user){
            refetch();
        }
    }, [user]);


    const shouldSkip = !user?.accessToken;

    const { data, loading, error, refetch } = useQuery(GET_CATEGORIES,
        {
            variables: {
                query: {
                    owner_id: user?.id
                }
            },
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                },
            },
            skip: shouldSkip,
        });

    const categories = data?.categories;

    return [{
        data: categories,
        isLoading: loading,
        isError: !!error,
        refetch,
    }] as const;
};

export default useGetCategories;
