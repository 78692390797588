// useGetTodaysTransactions.ts
import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { useQuery, gql } from '@apollo/client';
import { reportsClient } from '../apolloClients/reportsClient';

const GET_TODAYS_TRANSACTIONS = gql`
    query Query {
        salesSummary {
            today {
                totalTransactions
                grossSales
            }
            yesterday {
                totalTransactions
                grossSales
            }
            currentWeek {
                grossSales
            }
            lastWeek {
                grossSales
            }
        }
    }
`;

const useGetTodaysTransactions = () => {
    const { token } = useContext(UserContext);
    const shouldSkip = !token;

    const { data, loading: apolloLoading, error, refetch } = useQuery(GET_TODAYS_TRANSACTIONS,
        {
            context: {
                headers: {
                    Authorization: "Bearer " + token,
                },
            },
            skip: shouldSkip,
            client: reportsClient,  // use the imported client
        });

    const todaysTransactions = data?.salesSummary?.today?.totalTransactions
    const yesterdaysTransactions = data?.salesSummary?.yesterday?.totalTransactions;

    const todaysGross = data?.salesSummary?.today?.grossSales
    const yesterdaysGross = data?.salesSummary?.yesterday?.grossSales;

    const thisWeekGross = data?.salesSummary?.currentWeek?.grossSales
    const lastWeekGross = data?.salesSummary?.lastWeek?.grossSales;

    const isLoading = apolloLoading || shouldSkip;

    return [{
        data: {today: todaysTransactions, yesterday: yesterdaysTransactions, todayGross: todaysGross, yesterdayGross: yesterdaysGross, thisWeekGross, lastWeekGross},
        isLoading,
        isError: !!error,
        refetch,
    }] as const;
};

export default useGetTodaysTransactions;
