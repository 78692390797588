import {useContext} from 'react';
import {UserContext} from '../../contexts/UserContext';
import {useQuery, gql} from '@apollo/client';
import {reportsClient} from '../apolloClients/reportsClient';

const GET_SALES_BY_HOUR = gql`
    query Query($quickDate: String) {
        salesDataPerHour(quickDate: $quickDate) {
            hour
            grossSales
            normalizedGross
        }
    }
`;

export const useGetSalesByHour = (quickDate : String = "this week") => {
    const {token} = useContext(UserContext);
    const shouldSkip = !token;

    const {data, loading: apolloLoading, error, refetch} = useQuery(GET_SALES_BY_HOUR,
        {
            variables: {
                quickDate: quickDate
            },
            context: {
                headers: {
                    Authorization: "Bearer " + token,
                },
            },
            skip: shouldSkip,
            client: reportsClient,  // use the imported client
        });

    const isLoading = apolloLoading || shouldSkip;

    return [{
        data: data?.salesDataPerHour,
        isLoading,
        isError: !!error,
        refetch,
    }] as const;
};


