import { Flex, FormLabel, Text, useColorModeValue, Tooltip } from '@chakra-ui/react';
import React, { useState } from 'react';
import CurrencyInput from "react-currency-input-field";
import { InfoOutlineIcon } from '@chakra-ui/icons';

// @ts-ignore
const SimpleCurrencyInputField = ({ id, name, label, extra, placeholder, type = 'text', defaultValue, onValueChange, ...props }) => {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const [value, setValue] = useState(defaultValue || null);

    const handleValueChange = (value: any) => {
        setValue(value);
        onValueChange(value);
    }

    return (
        <Flex direction='column' maxW={"40%"} mr={5}>
            {(extra || label) && (
                <FormLabel
                    display='flex'
                    ms='10px'
                    htmlFor={id}
                    fontSize='sm'
                    color={textColorPrimary}
                    fontWeight='bold'
                    _hover={{ cursor: 'pointer' }}
                >
                    {label}
                    <Text fontSize='sm' fontWeight='400' ms='2px'>
                        {extra}
                    </Text>
                </FormLabel>
            )}
            <CurrencyInput
                {...props}
                type={type}
                id={id}
                name={name}
                placeholder={placeholder}
                value={value}
                onValueChange={handleValueChange}
                intlConfig={{ locale: "en-GB", currency: "GBP" }}
                style={{
                    fontWeight: '500',
                    height: '44px',
                    maxHeight: '44px',
                    color: textColorPrimary,
                    border: '1px solid',
                    borderColor: '#E0E5F2',
                    borderRadius: '16px',
                    padding: '20px',
                }}
            />
        </Flex>
    );
};

export default SimpleCurrencyInputField;
