// Chakra imports
import { Box, Button, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';

// Assets
import { MdOutlineLocationOn, MdOutlineWbSunny } from 'react-icons/md';
import {IoMdTabletLandscape} from "react-icons/io";
import {DeviceContext} from "../contexts/DeviceContext";
import {useContext, useEffect, useState} from "react";

interface TerminalCardProps {
    terminal: any;
}

export default function TerminalCard({terminal} : TerminalCardProps) {
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.900', 'secondaryGray.400');
    const brandColor = useColorModeValue('brand.500', 'white');
    const cardBg = useColorModeValue(
        'linear-gradient(180deg, #F4F7FE 0%, rgba(244, 247, 254, 0) 86.56%)',
        'linear-gradient(180deg, #1B254B 0%, rgba(27, 37, 75, 0) 86.56%)'
    );

    const [status, setStatus] = useState("Offline");
    const [statusColor, setStatusColor] = useState('amber');

    const {presentDevices} = useContext(DeviceContext);

    useEffect(() => {

        const isDevicePresent = presentDevices.some(device => device.clientId === terminal?._id);

        if(isDevicePresent) {
            setStatus('Online');
            setStatusColor('#00bd00');
        } else {
            setStatus('Offline');
            setStatusColor('#ff9900');
        }

    }, [presentDevices, terminal?._id]);

    // Chakra Color Mode
    return (
        <Card p='30px'>
            <Text fontSize='lg' mb='12px' lineHeight='100%' color={textColor} fontWeight='bold'>
                Terminal {terminal?.terminalNumber}
            </Text>

            <Flex direction='column' p='16px' borderRadius='22px' bg={cardBg}>
                <Flex w='100%'>
                    <Box>
                        <Icon as={IoMdTabletLandscape} color={brandColor} h='58px' w='58px' mb='15px' />
                        <Text color={textColor} fontSize='md'  fontWeight='bold'>
                            {terminal?.deviceType}
                        </Text>
                        <Text color={textColorSecondary} fontSize='md' fontWeight='500'>
                           Device Name : {terminal.deviceName}
                        </Text>
                    </Box>
                    <Flex ml='auto' alignItems='center' maxH={'20px'}>

                        <Text ms='auto' fontSize='md' fontWeight='500'>{status}</Text>
                    <Box width='15px' height='15px' borderRadius='50%' backgroundColor={statusColor} marginLeft='5px' />
                    </Flex>
                </Flex>
            </Flex>
            <Button variant='brand' borderRadius='70px' maxW='128px' ms='auto'>
                View more
            </Button>
        </Card>
    );
}
