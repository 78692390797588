import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    List,
    ListItem,
    Text
} from '@chakra-ui/react';
import React from 'react';
import { ObjectId } from 'bson';

interface AddCategoryModalProps {
    isOpen: boolean;
    onClose: () => void;
    categories: any[];
    existingCategories: ObjectId[];
    addCategory: (category: ObjectId) => void;
}

const AddCategoryModal: React.FC<AddCategoryModalProps> = ({
                                                               isOpen,
                                                               onClose,
                                                               categories,
                                                               existingCategories,
                                                               addCategory,
                                                           }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Category</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <List spacing={3}>
                        {categories.map((category) => {
                            const isExisting = existingCategories.some(catId => catId.toString() === category._id.toString());

                            return (
                                <ListItem key={category._id.toString()} opacity={isExisting ? 0.5 : 1}>
                                    <Text as="span">{category.name}</Text>
                                    {!isExisting && (
                                        <Button
                                            size="xs"
                                            colorScheme="blue"
                                            ml={2}
                                            onClick={() => addCategory(category._id)}
                                        >
                                            Add
                                        </Button>
                                    )}
                                </ListItem>
                            );
                        })}
                    </List>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddCategoryModal;
