// Chakra imports
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Flex,
    IconButton,
    Text,
    Button,
    useColorModeValue,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Tr,
    Td,
    Box,
    Stack,
    Select
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import useManageModifierGroups from "../../../../../api/productLibrary/manageModifierGroups";
import React, { useRef, useState } from "react";
import { useForm, Control, UseFormRegister } from "react-hook-form";
import { formatCurrency } from "../../../../../helpers/accountCurrency";
import CurrencyInputFieldWithProps from "../../../../../components/fields/CurrencyInputFieldWithProps";
import {ObjectId} from "bson";
import useManageProduct from "../../../../../api/productLibrary/manageProduct";
import Dinero from "dinero.js";

interface PriceDetail {
    _id: any;
    __typename: string;
    orderMode: string | null;
    price: string;
    taxRate: string;
    modifierStep: null | string;
    orderModeName?: string;
}

interface ModifierItemProps {
    name: string;
    stepId: string;
    modifierId: string;
    refetch: () => void;
    mb: string;
    bg: string;
    hilightColour: string;
    type: string;
    priceDetails: PriceDetail[];
}

interface OrderMode {
    id: React.Key;
    _id: string;
    name: string;
}

interface TaxRate {
    _id: string;
    name: string;
    rate: number;
}

export default function ModifierItem({ name, modifierId, stepId, refetch, hilightColour, type, priceDetails, orderModes, taxRates, productId, ...rest }: { name: string; modifierId: string; stepId: string, hilightColour: any, refetch: any, [x: string]: any, type: string, priceDetails?: PriceDetail[], orderModes: OrderMode[], taxRates: TaxRate[], productId:string; }) {
    const { deleteModifier } = useManageModifierGroups();
    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { control, register } = useForm();
    const [pricingData, setPricingData] = useState<{ [key: string]: { price: string; taxRate: string; modifierStep: null | string; } }>({});

    const onClose = () => setIsOpen(false);
    const onModalClose = () => setIsModalOpen(false);
    const cancelRef = useRef();

    const onDelete = async () => {
        await deleteModifier(modifierId, stepId);
        refetch();
        onClose();
    }

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const brandBg = useColorModeValue("brand.500", "brand.400");

    console.log(taxRates)

    const { updatePriceDetail, loading, error } = useManageProduct();

    const handleModalSave = async () => {
        const updatedPricingData = { ...pricingData };

        // Iterate over each key in orderModes and set modifierStep
        orderModes.forEach((mode) => {
            const key = mode._id;
            if (updatedPricingData[key]) {
                updatedPricingData[key].modifierStep = stepId;
            }
        });

        // Iterate and update/call updatePriceDetail for each orderMode
        try {
            for (const mode of orderModes) {
                const key = mode._id;
                if (pricingData[key]) {
                    // Remove any non-numeric characters except the decimal point
                    const priceInput = pricingData[key].price.toString().replace(/[^0-9.]/g, '');
                    const parsedPrice = parseFloat(priceInput);

                    if (isNaN(parsedPrice)) {
                        throw new Error(`Invalid price value: ${pricingData[key].price}`);
                    }

                    const amountInCents = Math.round(parsedPrice * 100);

                    const updatedPriceDetail = {
                        _id: new ObjectId().toString(),
                        price: Dinero({ amount: amountInCents, currency: 'GBP' }),
                        costPrice: Dinero({ amount: 0, currency: 'GBP' }),
                        taxRate: pricingData[key].taxRate,
                        modifierStep: stepId,
                        orderMode: key
                    };

                    await updatePriceDetail(productId, updatedPriceDetail);
                }
            }
            refetch();
        } catch (error) {
            console.error("Error updating price details:", error);
        }

        setPricingData(updatedPricingData);
        console.log("pricing data", updatedPricingData);
        onModalClose();
    };

    return (
        <Flex w="100%" px="12px" py="16px" borderRadius="16px" {...rest}>
            <Flex bg={hilightColour} w="6px" borderRadius="16px" me="10px" py="22px" />
            <Flex direction="row" align="center" justifyContent="space-between" w="100%" h="100%">
                <Text
                    textAlign="start"
                    color={textColor}
                    fontSize="md"
                    me="6px"
                    fontWeight="700"
                    py="10px"
                >
                    {name}
                </Text>
                <Flex direction="row" h="100%">
                    {type === 'product' && (
                        <Flex direction="row" h="100%">
                            {Array.isArray(priceDetails) &&
                                (() => {
                                    // Filter for priceDetails where modifierStep matches the stepId
                                    const modifierStepMatches = priceDetails.filter(
                                        (priceDetail) => priceDetail.modifierStep === stepId
                                    );

                                    // If any modifierStep matches are found, use them; otherwise, use orderMode filtered items
                                    const displayDetails = modifierStepMatches.length > 0
                                        ? modifierStepMatches
                                        : priceDetails.filter(
                                            (priceDetail) => priceDetail.orderMode !== null
                                        );

                                    return displayDetails.map((priceDetail, index) => (
                                        <Flex key={index} direction="row" alignItems="center" mb={2}>
                                            <Text fontWeight="bold" mr={2}>
                                                {priceDetail.orderModeName}:
                                            </Text>
                                            <Text mr={1}>{formatCurrency(priceDetail.price)}</Text>
                                        </Flex>
                                    ));
                                })()}
                            <Button ml={2} mr={2} colorScheme='brand' size={"sm"} onClick={() => setIsModalOpen(true)}>Edit Price</Button>
                        </Flex>
                    )}

                    <IconButton
                        size="sm"
                        icon={<CloseIcon />}
                        aria-label={`Remove `}
                        background="transparent"
                        onClick={() => setIsOpen(true)}
                    />

                    <AlertDialog
                        isOpen={isOpen}
                        leastDestructiveRef={cancelRef}
                        onClose={onClose}
                    >
                        <AlertDialogOverlay>
                            <AlertDialogContent>
                                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                    Delete Modifier
                                </AlertDialogHeader>

                                <AlertDialogBody>
                                    Are you sure you want to delete {name}?
                                </AlertDialogBody>

                                <AlertDialogFooter>
                                    <Button ref={cancelRef} onClick={onClose}>
                                        Cancel
                                    </Button>
                                    <Button colorScheme="red" onClick={onDelete} ml={3}>
                                        Delete
                                    </Button>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialogOverlay>
                    </AlertDialog>

                    <Modal isOpen={isModalOpen} onClose={onModalClose} size={"xl"}>
                        <ModalOverlay />
                        <ModalContent maxWidth="600px"> {/* Set the desired width here */}
                            <ModalHeader>Edit Price</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Text mb={2}>This price will only be used for this modifier step.</Text>
                                {orderModes?.map((mode) => {
                                    const key = `${mode._id}`;
                                    return (
                                        <Box key={mode.id} mb={4}>
                                            <Stack direction="row" spacing={4} align="center">
                                                <Text fontWeight="bold" minWidth="120px">
                                                    {mode.name}:
                                                </Text>
                                                <CurrencyInputFieldWithProps
                                                    control={control}
                                                    placeholder="Price"
                                                    {...register(`pricingData.${key}.price`)}
                                                    onChange={(e: { target: { value: any; }; }) => setPricingData({
                                                        ...pricingData,
                                                        [key]: {
                                                            ...pricingData[key],
                                                            price: e.target.value
                                                        }
                                                    })}
                                                    width="120px"
                                                />
                                                <Select
                                                    placeholder="Select tax rate"
                                                    value={pricingData[key]?.taxRate || 'no-tax'}
                                                    {...register(`pricingData.${key}.taxRate`)}
                                                    onChange={(e) => setPricingData({
                                                        ...pricingData,
                                                        [key]: {
                                                            ...pricingData[key],
                                                            taxRate: e.target.value
                                                        }
                                                    })}
                                                    width="160px"
                                                >
                                                    <option key={'nt'} value={'no-tax'}>
                                                        No Tax
                                                    </option>
                                                    {taxRates && taxRates.map((taxRate) => (
                                                        <option key={taxRate._id} value={taxRate._id}>
                                                            {`${taxRate.name} - ${(taxRate.rate / 100)}%`}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </Stack>
                                        </Box>
                                    );
                                })}
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme="blue" mr={3} onClick={onModalClose}>
                                    Close
                                </Button>
                                <Button colorScheme="green" onClick={handleModalSave}>
                                    Save
                                </Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </Flex>
            </Flex>
        </Flex>
    );
}
