import {Flex, SimpleGrid, Spinner} from '@chakra-ui/react';
import Card from 'components/card/Card';
import TaxRateTable from "./components/TaxRateTable";
import OrderModeTable from "./components/OrderModeTable";
import WeekStart from "./components/WeekStart";
import CardMethodTable from "./components/CardMethodTable";
import ReceiptSettings from "./components/ReceiptSettings";
import EmailReportSettings from "./components/EmailReportSettings";


export default function Settings() {

    const isLoading = false;

    return (
        <Flex direction='column' pt={{sm: '125px', lg: '75px'}}>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <Card px='0px'>
                    <TaxRateTable/>
                </Card>
                <Card px='0px'>
                    <OrderModeTable/>
                </Card>

                <Card px='0px'>
                    <CardMethodTable/>
                </Card>

                <Card px='0px'>
                    <ReceiptSettings/>
                </Card>

                <Card px='0px'>
                    <EmailReportSettings/>
                </Card>

                {/*}  <Card px='0px'>
                    <WeekStart/>
                </Card> */}
            </SimpleGrid>


        </Flex>
    );
}
