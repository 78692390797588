
// Chakra imports
import {Box, Flex, Spinner, useDisclosure} from '@chakra-ui/react';
import Card from 'components/card/Card';

import LayoutTable from "./components/LayoutTable";
import useGetLayouts from "../../../../api/productLibrary/getLayouts";
import AddNewCard from "../../../../components/AddNewCard";
import AddDiscountModal from "../discount/components/AddDiscountModal";
import * as React from "react";
import AddLayoutModal from "./components/AddLayoutModal";

export default function ListLayouts() {
    const [{data, isLoading, isError, refetch}] = useGetLayouts();

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
            <Card px='0px'>
                {isLoading &&
                    <Spinner/>
                }

                {!isLoading && (!data || data.length === 0) &&
                    <><Flex justify='center' alignItems='center'>
                        <Flex maxW={'50%'}>
                            <AddNewCard name={"Layout"} pluralName={"Layouts"}
                                        action={onOpen}/>
                        </Flex>
                    </Flex>
                        <Box display={"none"}>
                            <AddLayoutModal refetch={refetch} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
                        </Box>
                       </>
                }


                {data?.length > 0 &&
                    <LayoutTable tableData={data} refetch={refetch} />
                }
            </Card>
        </Flex>
    );
}
