import { useApp } from "@realm/react";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { ObjectId } from "bson";

const useAddCategoryToPrintRoute = () => {
    const app = useApp();
    const { user } = useContext(UserContext);

    const addCategoryToPrintRoute = async (printRouteId: string, categoryId: string) => {
        if (!user) {
            throw new Error("User is not authenticated");
        }

        try {
            const mongo = user.mongoClient("mongodb-atlas");
            const printRouteCollection = mongo.db("pos").collection("PrintRoute");

            // Fetch the existing print route
            const printRoute = await printRouteCollection.findOne({ _id: {$oid:printRouteId} });

            if (!printRoute) {
                throw new Error("Print route not found");
            }

            // Determine the latest position
            const currentPositions = printRoute?.category?.map((cat: { position: number }) => cat.position);
            const maxPosition = currentPositions?.length > 0 ? Math.max(...currentPositions) : 0;

            // Create the new category object
            const newCategory = {
                category: { $oid: categoryId },
                position: maxPosition + 1,
            };

            // Add the new category to the categories array
            await printRouteCollection.updateOne(
                { _id: {$oid:printRouteId} },
                { $push: { category: newCategory } }
            );

        } catch (error) {

            console.log(error);
            throw new Error(`Failed to add category to print route:`);
        }
    };

    return addCategoryToPrintRoute;
};

export default useAddCategoryToPrintRoute;
