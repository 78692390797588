import {useApp} from "@realm/react";
import {useContext, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {ObjectId} from "bson";
import useGetTaxRates from "./getTaxRates";
import Dinero from "dinero.js";


const useManageProduct = () => {
    const app = useApp();
    const { user } = useContext(UserContext);
    let mongo: { db: (arg0: string) => { (): any; new(): any; collection: { (arg0: string): any; new(): any; }; }; };


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Helper function to convert string to ObjectId
    const toObjectId = (idString: string): {} => {
        return { '$oid': idString };
    };

    const updateUser = () => {
        if (user) {
            mongo = user.mongoClient("mongodb-atlas");
        }
    };

    // Initial fetch
    updateUser();

    const updatePriceDetail = async (productId: string, updatedPriceDetail: any) => {
        setLoading(true);
        setError(null);

        if(!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const productCollection = mongo.db("pos").collection("Product");

        try {
            let product = await productCollection.findOne({
                _id: toObjectId(productId),
                "priceDetails._id": toObjectId(updatedPriceDetail._id)
            });

            if (product) {
                let taxRate = null;

                if(updatedPriceDetail.taxRate !== "NT"){
                    taxRate = toObjectId(updatedPriceDetail.taxRate);
                }

                await productCollection.updateOne(
                    {
                        _id: toObjectId(productId),
                        "priceDetails._id": toObjectId(updatedPriceDetail._id)
                    },
                    {
                        $set: {
                            "priceDetails.$.price": Number(updatedPriceDetail.price.getAmount()),
                            "priceDetails.$.costPrice": Number(updatedPriceDetail.costPrice.getAmount()),
                            "priceDetails.$.taxRate": taxRate,
                        }
                    }
                );
            } else {

                //new pricedetail

                let taxRate = null;

                if(updatedPriceDetail.taxRate !== "NT"){
                    taxRate = toObjectId(updatedPriceDetail.taxRate);
                }

                await productCollection.updateOne(
                    { _id: toObjectId(productId) },
                    {
                        $addToSet: {
                            priceDetails: {
                                _id: toObjectId(updatedPriceDetail._id),
                                price: Number(updatedPriceDetail.price.getAmount()),
                                costPrice: Number(updatedPriceDetail.costPrice.getAmount()),
                                taxRate: taxRate,
                                orderMode: toObjectId(updatedPriceDetail.orderMode),
                                variant: updatedPriceDetail.variant ? toObjectId(updatedPriceDetail.variant) : null,
                                ...(updatedPriceDetail.modifierStep && { modifierStep: toObjectId(updatedPriceDetail.modifierStep) })
                            }
                        }
                    }
                );
            }

        } catch (err) {
            console.error(err);
            setError(err);
        }

        setLoading(false);
    };

    const updateProductInfo = async (productId: string, updatedInfo: any) => {
        setLoading(true);
        setError(null);
        if(!user){
            setTimeout(updateUser,2000);
            return;
        }
        const productCollection = mongo.db("pos").collection("Product");
        try {
            await productCollection.updateOne(
                { _id: toObjectId(productId) },
                {
                    $set: {
                        'name': updatedInfo.name,
                        'kitchenName': updatedInfo.kitchenName,
                        'customerName': updatedInfo.customerName,
                        'btnColour': updatedInfo.btnColour,
                    }
                },
            );
        } catch (err) {
            console.error(err);
            setError(err);
        }
        setLoading(false);
    };

    const updateProductModifierGroup = async (productId: string, modifierGroup: string) => {
        setLoading(true);
        setError(null);
        if(!user){
            setTimeout(updateUser,2000);
            return;
        }
        const productCollection = mongo.db("pos").collection("Product");
        try {
            await productCollection.updateOne(
                { _id: toObjectId(productId) },
                {
                    $set: {
                        'modifierGroup': {"$oid":modifierGroup}
                    }
                },
            );
        } catch (err) {
            console.error(err);
            setError(err);
        }
        setLoading(false);
    };

    const updateProductVariantGroup = async (productId: string, variantGroup: string) => {
        setLoading(true);
        setError(null);

        if (!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const productCollection = mongo.db("pos").collection("Product");
        const variantGroupCollection = mongo.db("pos").collection("VariantGroup");

        try {
            // Update variant group in product
            await productCollection.updateOne(
                { _id: toObjectId(productId) },
                {
                    $set: {
                        'variantGroups': [{"$oid": variantGroup}]
                    }
                },
            );


          /*
            // Fetch the variant group by _id
            const fetchedVariantGroup = await variantGroupCollection.findOne({ _id: toObjectId(variantGroup) });

            if (fetchedVariantGroup) {
                // Iterate over each variant and create a placeholder price detail
                for (const variant of fetchedVariantGroup.variants) {

                    console.log(variant)

                    await productCollection.updateOne(
                        { _id: toObjectId(productId) },
                        {
                            $addToSet: {
                                priceDetails: {
                                    _id: toObjectId(new ObjectId().toString()),
                                    price: 0,
                                    costPrice: 0,
                                    taxRate: null,
                                    orderMode: toObjectId("66159dbc4cd289f22a12a7d1"),
                                    variant: toObjectId(variant.toString())
                                }
                            }
                        }
                    );


                }
            }*/

        } catch (err) {
            console.error(err);
            setError(err);
        }

        setLoading(false);
    };

    const markProductAsDeleted = async (productId: string) => {
        setLoading(true);
        setError(null);

        if (!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const productCollection = mongo.db("pos").collection("Product");


        try {
            await productCollection.updateOne(
                {_id: toObjectId(productId)},
                {$set: {'deletedOn':  {"$date": new Date().toISOString()}}},
            );
        } catch (err) {
            console.error(err);
            setError(err);
        }

        setLoading(false);
    };

    const restoreProduct = async (productId: string) => {
        setLoading(true);
        setError(null);

        if (!user) {
            setTimeout(updateUser, 2000);
            return;
        }

        const productCollection = mongo.db("pos").collection("Product");


        try {
            await productCollection.updateOne(
                {_id: toObjectId(productId)},
                {$set: {'deletedOn':  null}},
            );
        } catch (err) {
            console.error(err);
            setError(err);
        }

        setLoading(false);
    };

    return {
        updatePriceDetail,
        updateProductInfo,
        markProductAsDeleted,
        restoreProduct,
        updateProductModifierGroup,
        updateProductVariantGroup,
        loading,
        error,
    };
};

export default useManageProduct;
