// Chakra imports
import {Box, Button, useToast, Text, Flex} from '@chakra-ui/react';
import Card from 'components/card/Card';

import {useContext, useEffect} from "react";
import {UserContext} from "../../../../contexts/UserContext";
import PaddedFlex from "../../../../components/PaddedFlex";
import SeamlessSpinner from "../../../../components/SeamlessSpinner";
import {useNavigate} from "react-router-dom";
import AddNewCard from "../../../../components/AddNewCard";
import useGetVariantGroups from "../../../../api/productLibrary/getVariantGroups";
import PortionsTable from "./components/PortionsTable";

export default function ListPortions() {
    const [{data, isLoading, isError, refetch}] = useGetVariantGroups();
    const {isAppSession} = useContext(UserContext);
    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
            if (isError) {
                toast({
                    title: "Error loading portions.",
                    description: "There was an error while loading portion groups. Please try again later.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        },
        [isError, toast]);

    let padding = {sm: '125px', lg: '75px'};

    if (isAppSession) {
        padding = {sm: '60px', lg: '60px'};
    }

    return (
        <PaddedFlex direction='column' pt={padding}>

            <Card px='0px'>
                {isLoading &&
                    <SeamlessSpinner/>
                }

                {!isLoading &&
                   <PortionsTable tableData={data} isLoading ={isLoading} refetch={refetch} key={data?.length}/>
                }
            </Card>
        </PaddedFlex>
    );
}
