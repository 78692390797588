import { startOfDay, addHours, addDays } from "date-fns";

type RolloverDates = [Date, Date];

export function getRolloverDates(rolloverTime: number, date: Date = new Date()): RolloverDates {
    const startOfGivenDay = startOfDay(date);
    const startOfReportPeriod = addHours(startOfGivenDay, rolloverTime);
    const endOfReportPeriod = addHours(startOfDay(addDays(date, 1)), rolloverTime);

    return [startOfReportPeriod, endOfReportPeriod];
}
