import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { format, parseISO } from 'date-fns';
import {formatCurrency} from "../../../helpers/accountCurrency";

type SalesData = {
    hour: string;
    grossSales: number;
    netSales: number;
    normalizedGross: number;
};

type HeatmapChartProps = {
    salesDataPerHour: SalesData[];
};



const HeatmapChart = (props: HeatmapChartProps) => {

    const { salesDataPerHour } = props;

    const enhancedData = salesDataPerHour.map(item => ({
        ...item,
        dayOfWeek: format(parseISO(item.hour), 'eeee do'),
        hourOfDay: format(parseISO(item.hour), 'H'),
    }));

    const seriesData = Array.from({ length: 24 }, (_, index) => ({
        name: `${Number(index).toLocaleString('en-GB', {minimumIntegerDigits: 2, useGrouping:false})}:00 - 
    ${(index + 1) % 24 === 0 ? '00' : Number(index + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:00`,
        data: enhancedData
            .filter(item => Number(item.hourOfDay) === index)
            .map(item => ({ x: item.dayOfWeek, y: item.normalizedGross, grossSales: item.grossSales })),
    }));

    let datesArray = Array.from(new Set(enhancedData.map(data => data.dayOfWeek)));

    const options: ApexOptions = {
        chart: {
            height: 650,
            type: 'heatmap'
        },
        dataLabels: {
            enabled: false
        },
        colors: ["#4318ff"],
        yaxis: {
            reversed: true,
            labels: {
                formatter: function (val) {
                    return val.toString()
                }
            }
        },
        xaxis: {
            categories: datesArray
        },
        plotOptions: {
            heatmap: {

            }
        },
        grid: {
            show: false,
        },
        tooltip: {
            theme: 'dark',
            y:{
                formatter: function(value: number, { seriesIndex, dataPointIndex }: any) {
                    const dataPoint = seriesData[seriesIndex].data[dataPointIndex];
                    if (dataPoint) {
                        return formatCurrency(dataPoint.grossSales)
                    }
                    return '';
                }
            }
        },
    }


    return (
        // @ts-ignore
        <Chart
            options={options}
            series={seriesData as any}
            type="heatmap"
            width="100%"
            height="100%"
        />
    );
};

export default HeatmapChart;
