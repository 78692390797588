import { useState } from 'react';
import {
    Flex,
    Text,
    useColorModeValue,
    FormControl,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import useGetModifierGroups from "../../../../../api/productLibrary/getModifierGroups";
import useGetVariantGroups from "../../../../../api/productLibrary/getVariantGroups";
import useManageProduct from "../../../../../api/productLibrary/manageProduct";

export default function AddModifierPortion({ productId, refetch, openTour }: { productId: string, refetch: () => void, openTour: () => void }) {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [activeModal, setActiveModal] = useState<'modifier' | 'portion' | null>(null);

    const [modData] = useGetModifierGroups();
    const [varData] = useGetVariantGroups();

    const { updateProductModifierGroup, updateProductVariantGroup } = useManageProduct();

    const handleAddButtonClick = (type: 'modifier' | 'portion') => {
        setActiveModal(type);
        onOpen();
    };

    const handleAdd = async (itemId: string) => {
        if (activeModal === 'modifier') {
            await updateProductModifierGroup(productId, itemId);
        } else if (activeModal === 'portion') {
            await updateProductVariantGroup(productId, itemId);
            setTimeout(openTour, 500);
        }
        refetch();
        onClose();
    };

    return (
        <>
            <Card mb='20px'>
                <Flex direction='column' mb='10px' ms='10px'>
                    <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
                        Modifier Group
                    </Text>

                    <FormControl>
                        <Flex direction='row' gap='4'>
                            <Button colorScheme='blue' size='md' onClick={() => handleAddButtonClick('modifier')}>
                                Add Modifier Group
                            </Button>
                            <Button colorScheme='green' size='md' onClick={() => handleAddButtonClick('portion')}>
                                Add Portion
                            </Button>
                        </Flex>
                    </FormControl>
                </Flex>
            </Card>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{activeModal === 'modifier' ? 'Select Modifier Group' : 'Select Portion'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex direction='column' gap='4'>
                            {(activeModal === 'modifier' ? modData?.data : varData?.data)?.map((item: any) => (
                                <Flex key={item._id} justify='space-between' align='center'>
                                    <Text>{item.name}</Text>
                                    <Button size='sm' colorScheme='brand' onClick={() => handleAdd(item._id)}>
                                        Add
                                    </Button>
                                </Flex>
                            ))}
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
