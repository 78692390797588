import { useRef, useState, useEffect, ChangeEvent, RefObject } from "react";
import "./segmentedStyle.css";

type Segment = {
    value: string;
    label: string;
    ref: RefObject<HTMLInputElement>;
};

type SegmentedControlProps = {
    name: string;
    segments: Segment[];
    callback: (value: string, index: number) => void;
    defaultIndex?: number;
    controlRef: RefObject<HTMLDivElement>;
};

const SegmentedControl = ({
                              name,
                              segments,
                              callback,
                              defaultIndex = 0,
                              controlRef
                          }: SegmentedControlProps) => {
    const [activeIndex, setActiveIndex] = useState(defaultIndex);
    const componentReady = useRef();

    useEffect(() => {
        // @ts-ignore
        componentReady.current = true;
    }, []);

    useEffect(() => {
        const activeSegmentRef = segments[activeIndex].ref;
        const { offsetWidth, offsetLeft } = activeSegmentRef.current;
        const { style } = controlRef.current;

        style.setProperty("--highlight-width", `${offsetWidth}px`);
        style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
    }, [activeIndex, callback, controlRef, segments]);

    useEffect(() => {
        const handleResize = () => {
            const activeSegmentRef = segments[activeIndex].ref;

            if (activeSegmentRef.current !== null) {
                const { offsetWidth, offsetLeft } = activeSegmentRef.current;
                const { style } = controlRef.current;

                style.setProperty("--highlight-width", `${offsetWidth}px`);
                style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
            }
        }

        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        }

        handleResize();
    }, [activeIndex, callback, controlRef, segments]);

    const onInputChange = (value: string, index: number) => {
        setActiveIndex(index);
        callback(value, index);
    };

    return (
        <div className="controls-container" ref={controlRef}>
            <div className={`controls ${componentReady.current ? "ready" : "idle"}`}>
                {segments?.map((item, i) => (
                    <div
                        key={item.value}
                        className={`segment ${i === activeIndex ? "active" : "inactive"}`}
                        ref={item.ref}
                    >
                        <input
                            type="radio"
                            value={item.value}
                            id={item.label}
                            name={name}
                            onChange={() => onInputChange(item.value, i)}
                            checked={i === activeIndex}
                        />
                        <label htmlFor={item.label}>{item.label}</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SegmentedControl;
