import { Flex, FormLabel, Text, useColorModeValue, Tooltip } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import React, {ForwardedRef, useState} from 'react';
import CurrencyInput from "react-currency-input-field";
import { InfoOutlineIcon } from '@chakra-ui/icons';

type InputFieldProps = {
    id?: string;
    name: string;
    label?: string;
    extra?: JSX.Element;
    placeholder?: string;
    control: any;
    type?: string;
    mb?: string;
    defaultValue?: any;
    [x: string]: any;
};

const CurrencyInputFieldWithProps = React.forwardRef<HTMLInputElement, InputFieldProps>((props, ref: ForwardedRef<HTMLInputElement>) => {
    const {
        id,
        name,
        label,
        extra,
        placeholder,
        type = 'text',
        mb,
        defaultValue,
        control,
        ...rest
    } = props;

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    return (
        <Flex direction='column'  maxW={"40%"} mr={5}>
            { (props.toolTipText || extra) && (
            <FormLabel
                display='flex'
                ms='10px'
                htmlFor={id}
                fontSize='sm'
                color={textColorPrimary}
                fontWeight='bold'
                _hover={{ cursor: 'pointer' }}>
                {label}
                {props.toolTipText && (
                    <Flex>
                        &nbsp;
                        <Tooltip label={props.toolTipText} placement="right" ml="2">
                            <InfoOutlineIcon />
                        </Tooltip>
                    </Flex>
                )}
                <Text fontSize='sm' fontWeight='400' ms='2px'>
                    {extra}
                </Text>
            </FormLabel>
            )}

            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue ?? 0}
                render={({ field }) => (
                    <CurrencyInput {...rest}
                                   type={type}
                                   id={id}
                                   placeholder={placeholder}
                                   ref={ref}
                                   defaultValue={defaultValue}
                                   onValueChange={value => field.onChange(value)}
                                   intlConfig={{ locale: "en-GB", currency: "GBP" }}
                                   style={{
                                       fontWeight: '500',
                                       height: '44px',
                                       maxHeight: '44px',
                                       color: textColorPrimary,
                                       border: '1px solid',
                                       borderColor: '#E0E5F2',
                                       borderRadius: '16px',
                                       padding: '20px',
                                   }}

                    />
                )}
            />
        </Flex>
    );
});

export default CurrencyInputFieldWithProps;
