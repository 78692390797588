import {Box, Spinner} from "@chakra-ui/react";

export default function SeamlessSpinner() {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.700"
                size="xl"
            />
        </Box>
    )
}
