import {ApolloClient, ApolloLink, gql, HttpLink, InMemoryCache, useQuery} from "@apollo/client";
import {useContext, useEffect} from "react";
import {UserContext} from "../../contexts/UserContext";
import {DeviceContext} from "../../contexts/DeviceContext";

const GET_GROSS_SALES = gql`
    query Query {
        grossDaySales {
            total
            totalTax
            totalNet
            prevTotal
            percentageChange
        }
    }
`;

const useGetGrossSales = () => {
    const { user } = useContext(UserContext);

    const shouldSkip = !user?.accessToken;

    const DeviceData = useContext(DeviceContext);

    const { data, loading: apolloLoading, error, refetch } = useQuery(GET_GROSS_SALES,
        {
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            },
            skip: shouldSkip,
        });

    useEffect(() => {
        console.log("REFETCHING")
        refetch();
    }, [DeviceData.trigger, refetch]);

    const grossSales = data?.grossDaySales;

    const isLoading = apolloLoading || shouldSkip;

    return [{
        data: grossSales,
        isLoading,
        isError: !!error,
        refetch,
    }] as const;
};

export default useGetGrossSales;
