import React, { useState } from 'react';
import useGetBillingInfo from "../../../../api/billing/useGetBillingInfo";

// Chakra imports
import {
    Flex,
    Box,
    Button,
    Text,
    useColorModeValue
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import {formatCurrency} from "../../../../helpers/accountCurrency";
import useGetBillingPortal from "../../../../api/billing/useGetCustomerPortal";

export default function BillingCard(props: { [x: string]: any }) {
    const { ...rest } = props;

    const [{ data, isLoading, isError, refetch }] = useGetBillingInfo();

    const billingInfo = data || { plan: {}, default_payment_method: {}, customer: {} }; // Safe defaults

    const { getPortalUrl, data: portalUrl, isLoading: portalLoading, isError: portalError } = useGetBillingPortal();

    const textColor = useColorModeValue('secondaryGray.900', 'white');

    const originalTotal = billingInfo.plan.quantity * billingInfo.plan.amount;
    const discountAmount = billingInfo.discount ? (billingInfo.discount.percent_off / 100) * originalTotal : 0;
    const totalWithDiscount = originalTotal - discountAmount;

    const handleManageSubscription = async () => {
        try {
            if (billingInfo.customer) {
                const url = await getPortalUrl(billingInfo.customer);
                window.location.href = url;
            } else {

            }
        } catch (error) {

        }
    };

    return (
        <Card {...rest} p='34px'>
            <Flex justify='space-between' mb='25px' align='center'>
                <Text color={textColor} fontSize='2xl' fontWeight='700' lineHeight='100%'>
                    Terminals
                </Text>
            </Flex>
            {isLoading ? (
                <Text>Loading...</Text>
            ) : isError ? (
                <Text>Error loading billing info</Text>
            ) : (
                <Box>
                    <Text fontSize='lg' fontWeight='bold'>Licensed Terminals: {billingInfo.plan.quantity}</Text>
                    {billingInfo.discount && (
                        <>
                            <Text fontSize='lg' fontWeight='bold' as='del'>Original Cost: {formatCurrency(originalTotal)}</Text>
                            <Text fontSize='lg' fontWeight='bold'>Discount: {billingInfo.discount.percent_off}%</Text>
                        </>
                    )}
                    <Text fontSize='lg' fontWeight='bold'>Total Monthly Cost: {formatCurrency(totalWithDiscount)}</Text>

                    <Button
                        colorScheme="brand"
                        mt={6}
                        onClick={handleManageSubscription}
                        isLoading={portalLoading}
                        isDisabled={portalLoading || !billingInfo.customer}
                    >
                        Manage your subscription
                    </Button>
                </Box>
            )}

            <Flex justify='space-between' w='100%'>

            </Flex>
        </Card>
    );
}
