// types.ts
import { ObjectId } from 'bson';

export enum TableShape {
    SQUARE = 'square',
    RECTANGLE = 'rectangle',
    DIAMOND = 'diamond',
    CIRCLE = 'circle',
}

export interface TablePosition {
    id: string;
    positionX: number;
    positionY: number;
    shape: TableShape;
    tableNumber: string;
}

export interface TableArea {
    _id: ObjectId;
    name: string;
    siteId: string;
}
