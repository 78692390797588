import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";
import { posClient } from "../apolloClients/posClient";
import { UserContext } from "../../contexts/UserContext";

const CREATE_CUSTOMER_PORTAL_SESSION = gql`
    mutation CreateCustomerPortalSession($customerId: String!) {
        createCustomerPortalSession(customerId: $customerId)
    }
`;

const useGetBillingPortal = () => {
    const { user, token } = useContext(UserContext);

    const [createCustomerPortalSession, { data, loading, error }] = useMutation(
        CREATE_CUSTOMER_PORTAL_SESSION,
        {
            context: {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
            client: posClient,
        }
    );

    const getPortalUrl = async (customerId: string) => {
        const { data } = await createCustomerPortalSession({
            variables: { customerId },
        });
        return data?.createCustomerPortalSession;
    };

    return {
        getPortalUrl,
        data: data?.createCustomerPortalSession,
        isLoading: loading,
        isError: !!error,
    };
};

export default useGetBillingPortal;
