import { useApp } from "@realm/react";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { ObjectId } from "bson";

interface TableArea {
    _id: ObjectId;
    name: string;
    siteId: string;
}

const useGetTableAreas = () => {
    const app = useApp();
    const { user } = useContext(UserContext);
    const [tableAreas, setTableAreas] = useState<TableArea[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const dataFetched = useRef(false);

    useEffect(() => {
        if (dataFetched.current) return; // Skip if data already fetched

        const fetchTableAreas = async () => {
            setLoading(true);
            if (!user) {
                setLoading(false);
                return;
            }

            try {
                const mongo = user.mongoClient("mongodb-atlas");
                const tableAreaCollection = mongo.db("pos").collection("TableArea");
                const currentSiteId = app.currentUser.customData.currentSite.toString();
                const fetchedTableAreas = await tableAreaCollection.find({ siteId: currentSiteId });
                setTableAreas(fetchedTableAreas);
                dataFetched.current = true; // Mark data as fetched
            } catch (err) {
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };

        fetchTableAreas();
    }, [user, app]);

    return { tableAreas, loading, error };
};

export default useGetTableAreas;
