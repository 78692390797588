// useGetTodaysTransactions.ts
import {useContext} from 'react';
import {UserContext} from '../../contexts/UserContext';
import {useQuery, gql} from '@apollo/client';
import {reportsClient} from '../apolloClients/reportsClient';

const GET_TODAYS_PRODUCT_SALES = gql`
    query Query($quickDate: String, $limit: Int) {
        salesDataPerProduct(quickDate: $quickDate, limit: $limit) {
            netSales
            totalSales
            totalTax
            transactions
            productName
            productId
        }
    }
`;

const GET_PRODUCT_SALES = gql`
    query Query($quickDate: String) {
        salesDataPerProduct(quickDate: $quickDate) {
            netSales
            totalSales
            totalTax
            transactions
            productName
            productId
        }
    }
`;

export const useGetTodaysSalesByProduct = (limit: number = 5) => {
    const {token} = useContext(UserContext);
    const shouldSkip = !token;

    const {data, loading: apolloLoading, error, refetch} = useQuery(GET_TODAYS_PRODUCT_SALES,
        {
            variables: {
                quickDate: "today",
                limit: limit,
            },
            context: {
                headers: {
                    Authorization: "Bearer " + token,
                },
            },
            skip: shouldSkip,
            client: reportsClient,  // use the imported client
        });

    const isLoading = apolloLoading || shouldSkip;

    return [{
        data: data?.salesDataPerProduct,
        isLoading,
        isError: !!error,
        refetch,
    }] as const;
};

export const useGetSalesByProduct = (startDate: string, endDate: string) => {
    const {token} = useContext(UserContext);
    const shouldSkip = !token;

    const {data, loading: apolloLoading, error, refetch} = useQuery(GET_PRODUCT_SALES,
        {
            variables: {
                startDate: startDate,
                endDate: endDate
            },
            context: {
                headers: {
                    Authorization: "Bearer " + token,
                },
            },
            skip: shouldSkip,
            client: reportsClient,  // use the imported client
        });

    const isLoading = apolloLoading || shouldSkip;

    return [{
        data: data?.salesDataPerProduct,
        isLoading,
        isError: !!error,
        refetch,
    }] as const;
};

