import {
  Avatar,
  Flex,
  FormLabel,
  Grid,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  Box,
  Text,
    Image,
  Link
} from '@chakra-ui/react';
import Balance from 'views/admin/dashboards/default/components/Balance';
import DailyTraffic from 'views/admin/dashboards/default/components/DailyTraffic';
import MostVisitedTable from 'views/admin/dashboards/default/components/MostVisitedTable';
import { VSeparator } from 'components/separator/Separator';
import OverallRevenue from 'views/admin/dashboards/default/components/OverallRevenue';

import YourTransfers from './components/SalesByUser';

import React, { useContext } from "react";
import StatsBar from "./components/StatsBar";
import { UserContext } from "../../../../contexts/UserContext";
import SalesByHourHeatmap from "./components/SalesByHourHeatmap";
import SalesByProduct from "./components/SalesByProduct";
import useGetProducts from "../../../../api/productLibrary/getProducts";

export default function Default() {
  const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  const [{ data: productsData, isLoading }] = useGetProducts(); // Use the hook to get product data
  const isNewAccount = productsData?.length === 0; // Determine if the account is new

  return (
      <Flex
          direction={{ base: 'column', xl: 'row' }}
          pt={{ base: '130px', md: '80px', xl: '80px' }}
      >
        <Flex position="relative" direction="column" width="stretch">
          {isNewAccount && (
              <Box
                  position="absolute"
                  top="-5"
                  left="-5"
                  width={{ base: "unset", md: "100%" }}
                  height="100%"
                  bg="rgba(255, 255, 255, 0.6)"
                  backdropFilter="blur(5px)"
                  zIndex="10"
                  display="flex"
                  flexDirection="column"
                  textAlign="center"
              >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    width="100%"
                    top={{ base: "0%", md: "20%" }}
                    position="relative"
                    p={4}
                >
                <Text fontSize="2xl" fontWeight="bold">
                  Welcome to your Sales Dashboard!
                </Text>
                <Text fontSize="lg">To get started, please create Categories, Products and then make some sales.</Text>
                <Text fontSize="md" mt={2}>Not sure how to start? Reach out at <Link href={"mailto:hello@seamlesspos.co.uk"} color={"brand.500"}>hello@seamlesspos.co.uk</Link> or visit <Link href={"https://help.seamlesspos.co.uk"} color={"brand.500"} target={"_blank"}>help.seamlesspos.co.uk</Link></Text>

                <Text fontSize="md" mt={2} mb={0}>Need the iPad App? Download here</Text>
                <Link href="https://apps.apple.com/gb/app/seamless-pos/id6476807605?itscg=30200&itsct=apps_box_badge&mttnsubad=6476807605" isExternal mt={1} target={"_blank"}>
                  <Image src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/en-us?releaseDate=1730160000"
                         alt="Download on the App Store"
                         width="150px"
                         height="82px"
                         verticalAlign="middle"
                         objectFit="contain" />
                </Link>

              </Box>
              </Box>
          )}

          <StatsBar />

          <Grid
              mb="20px"
              gridTemplateColumns={{ base: 'repeat(2, 1fr)', '2xl': '720fr 350fr' }}
              gap="20px"
              display={{ base: 'block', lg: 'grid' }}
          >
            <Flex gridArea={{ base: '2 / 1 / 3 / 3', '2xl': '1 / 2 / 2 / 3' }}>
            </Flex>
          </Grid>

          <Grid
              mb="20px"
              gridTemplateColumns={{ base: 'repeat(2, 1fr)', '2xl': '720fr 350fr' }}
              gap="20px"
              display={{ base: 'block', lg: 'grid' }}
          >
            <Flex gridArea={{ base: '2 / 1 / 3 / 3', '2xl': '1 / 2 / 2 / 3' }}>
              <YourTransfers />
            </Flex>
            <Flex gridArea={{ base: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}>
              <OverallRevenue />
            </Flex>
          </Grid>
          {!isNewAccount && (
          <Grid
              templateColumns={{ base: 'repeat(2, 1fr)', '2xl': '350fr 720fr' }}
              gap="20px"
              display={{ base: 'block', lg: 'grid' }}
          >
            <Flex gridArea={{ base: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}>
              <SalesByProduct />
            </Flex>
            <Flex gridArea={{ base: '2 / 1 / 3 / 3', '2xl': '1 / 2 / 2 / 3' }}>
              <SalesByHourHeatmap />
            </Flex>
          </Grid>
              )}
        </Flex>
      </Flex>
  );
}
