import { gql, useMutation } from '@apollo/client';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useApp } from "@realm/react";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

const DELETE_ONE_TAX_RATE = gql`
    mutation DeleteOneTaxRate($query: TaxRateQueryInput!) {
        deleteOneTaxRate(query: $query) {
            _id,
            name,
            rate,
            owner_id
        }
    }
`;

const useDeleteOneTaxRate = () => {
    const { getToken } = useKindeAuth();
    const app = useApp();

    const { user } = useContext(UserContext);

    const [deleteOneTaxRate, { loading: isLoading, error: isError }] = useMutation(
        DELETE_ONE_TAX_RATE,
        {
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            }
        }
    );

    const deleteTaxRate = async (taxRateId: string) => {
        await deleteOneTaxRate({
            variables: {
                query: { _id: taxRateId },
            }
        });
    }

    return { deleteTaxRate, isLoading, isError };
};

export default useDeleteOneTaxRate;
