// components/TableInputModal.tsx
import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
} from '@chakra-ui/react';
import { TableShape } from './types';

interface TableInputModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (shape: TableShape, startNumber: number, count: number) => void;
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const TableInputModal: React.FC<TableInputModalProps> = ({ isOpen, onClose, onSave }) => {
    const [shape, setShape] = useState<TableShape>(TableShape.SQUARE);
    const [startNumber, setStartNumber] = useState<number>(1);
    const [count, setCount] = useState<number>(1);

    const handleSave = () => {
        onSave(shape, startNumber, count);
        onClose();
    };

    const handleCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        if (value <= 25) {
            setCount(value);
        } else {
            setCount(25);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Tables</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mb={4}>
                        <FormLabel>Shape</FormLabel>
                        <Select value={shape} onChange={(e) => setShape(e.target.value as TableShape)}>
                            {Object.values(TableShape).map(shape => (
                                <option key={shape} value={shape}>{capitalizeFirstLetter(shape)}</option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel>Starting Table Number</FormLabel>
                        <Input type="number" value={startNumber} onChange={(e) => setStartNumber(Number(e.target.value))} />
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel>Count (max 25)</FormLabel>
                        <Input type="number" value={count} onChange={handleCountChange} />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleSave}>Save</Button>
                    <Button variant="ghost" onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default TableInputModal;
