// Chakra imports
import { Button, Icon, Text, useColorModeValue, Spacer, VStack } from '@chakra-ui/react';
import { useGetTodaysSalesByProduct } from 'api/reports/getProductSales';

// Custom components
import Card from 'components/card/Card';
import { useEffect } from "react";
import { formatCurrency } from "../../../../../helpers/accountCurrency";
import SalesByProductRow from "./SalesByProductRow";
import useGetProducts from "../../../../../api/productLibrary/getProducts";

export default function SalesByProduct(props: { [x: string]: any }) {
    const { ...rest } = props;

    const [{ data }] = useGetTodaysSalesByProduct();
    const [{data: products}] = useGetProducts();

    // Chakra Color Mode
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    return (
        <Card
            justifyContent='left'
            flexDirection='column'
            alignItems='left'
            w='100%'
            mb={{ base: '20px', lg: '0px' }}
            pb='20px'
            {...rest}>
            <Text color={textColor} fontSize='lg' fontWeight='700' lineHeight='100%' mb='26px'>
                Top 5 Products Today
            </Text>
            <VStack spacing='20px' w='full'>
                {data?.map((item: {
                    transactions: number;
                    totalSales: number;
                    productName: string;
                    productId: string;  // Assuming productId exists to match the product
                }, index: any) => {
                const product = products?.find((p: any) => p._id === item.productId);
                return (
                <SalesByProductRow
                key={index}
                    name={item.productName}
                    transactions={item.transactions}
                    sum={formatCurrency(item.totalSales)}
                    color={product?.btnColour}
            />
            );
            })}
        </VStack>
    <Spacer />
</Card>
    );
}
