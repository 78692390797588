import {useApp} from "@realm/react";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {ObjectId} from "bson"

const useGetDiscounts = () => {
    const app = useApp();
    const { user } = useContext(UserContext);
    let mongo: { db: (arg0: string) => { (): any; new(): any; collection: { (arg0: string): any; new(): any; }; }; };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [discounts, setDiscounts] = useState<any[]>([]); // <-- Discounts state

    const toObjectId = (idString: string): {} => {
        return { '$oid': idString };
    };

    const updateUser = () => {
        if (user) {
            mongo = user.mongoClient("mongodb-atlas");
        }
    };

    updateUser();

    const getDiscounts = async () => {
        setLoading(true);
        if(!user) {
            setTimeout(updateUser, 2000);
            setDiscounts([]);
        }

        const discountsCollection = mongo.db("pos").collection("Discount");

        try {
            const data = await discountsCollection.find({});
            setDiscounts(data || []);
            console.log("DISCOUNTS", data)
        } catch (error) {
            console.error(`Failed to discounts: ${error}`);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDiscounts(); // call getDiscounts inside useEffect
    }, [user]);

    const refetch = getDiscounts; // refetch will do the same as getDiscounts

    return {
        discounts, // return discounts state
        getDiscounts,
        loading,
        error,
        refetch
    };
};

export default useGetDiscounts;
