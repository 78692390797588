import {useContext, useEffect} from 'react';
import {gql, useQuery} from "@apollo/client";
import {UserContext} from "../../contexts/UserContext";

const GET_USERS = gql`
    query PosUsers($query: PosUserQueryInput) {
        posUsers(query: $query) {
            name
            _id
            level
            colour
            pin
        }
    }
`;

const useGetUsers = () => {
    const { user } = useContext(UserContext);

    useEffect(() => {

        if(user){
            refetch();
        }
    }, [user]);


    const shouldSkip = !user?.accessToken;

    const { data, loading, error, refetch } = useQuery(GET_USERS,
        {
            variables: {
                query: {
                    owner_id: user?.id
                }
            },
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                },
            },
            skip: shouldSkip,
        });

    const users = data?.posUsers;

    return [{
        data: users,
        isLoading: loading,
        isError: !!error,
        refetch,
    }] as const;
};

export default useGetUsers;
