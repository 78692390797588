
// Chakra imports
import {Box, Flex, Spinner, useDisclosure} from '@chakra-ui/react';
import Card from 'components/card/Card';

import CategoryTable from "./components/CategoryTable";
import useGetCategories from "../../../../api/productLibrary/getCategories";
import useGetProducts from "../../../../api/productLibrary/getProducts";
import AddNewCard from "../../../../components/AddNewCard";
import ProductTable from "../productList/components/ProductTable";
import AddCategoryModal from "./components/AddCategoryModal";
import * as React from "react";

export default function ListCategories() {
	const [{data, isLoading, isError, refetch}] = useGetCategories();
	//const [{data, isLoading, isError}] = useGetProducts();
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
			<Card px='0px'>
				{isLoading &&
					<Spinner/>
				}

				{!isLoading && (!data || data.length === 0) &&
					<><Flex justify='center' alignItems='center'>
						<Flex maxW={'50%'}>
							<AddNewCard name={"Category"} pluralName={"Categories"}
										action={onOpen}/>
						</Flex>
					</Flex>
						<Box display={"none"}>
						<AddCategoryModal refetch={refetch} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
						</Box>
						</>
				}

				{data?.length > 0 &&
					<CategoryTable tableData={data} refetch={refetch}/>
				}
			</Card>
		</Flex>
	);
}
