import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    IconButton,
    FormControl,
    Input,
    Flex, Box, Table, Thead, Tr, Th, Tbody, Td, Alert, AlertIcon, Text, VStack, Tabs, TabPanels, TabPanel, Select
} from "@chakra-ui/react";
import React, {useEffect, useState, useCallback} from "react";
import {CheckIcon} from "@chakra-ui/icons";
import useManageDojoSettings from "../../../../api/site/manageDojoSettings";
import useGetTerminals from "../../../../api/terminals/getTerminals";

interface FriendlyNames {
    [key: string]: string;
}

interface TerminalMapping {
    terminal: string;
    dojoTid: string;
}

export default function DojoSettingsModal() {
    const [isOpen, setIsOpen] = useState(false);
    const [apiKey, setApiKey] = useState("");
    const [tabIndex, setTabIndex] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [terminals, setTerminals] = useState([]);
    const [friendlyNames, setFriendlyNames] = useState<FriendlyNames>({});
    const [terminalMappings, setTerminalMappings] = useState<TerminalMapping[]>([]);

    const onClose = () => setIsOpen(false);
    const onOpen = () => setIsOpen(true);

    const { executeGetTerminalsAndSaveToken, getDojoApiKey, setDojoTerminalMap, setDojoTerminalNames, getDojoTerminalNames, getDojoTerminalMappings } = useManageDojoSettings();
    const [{ data: posTerminals }] = useGetTerminals();

    // New state to safeguard effect from unwanted re-runs
    const [initialDataFetched, setInitialDataFetched] = useState(false);

    useEffect(() => {
        if (!isOpen || initialDataFetched) return;
        let isMounted = true;

        const fetchInitialData = async () => {
            try {
                const apiK = await getDojoApiKey();
                if (isMounted) setApiKey(apiK || "");

                const terminalNames = await getDojoTerminalNames();
                const namesMap: FriendlyNames = {};
                terminalNames.forEach((terminal: { dojoTid: string, name: string }) => {
                    namesMap[terminal.dojoTid] = terminal.name;
                });

                const terminalMappings = await getDojoTerminalMappings();

                if (isMounted) {
                    setFriendlyNames(namesMap);
                    setTerminalMappings(terminalMappings);
                    setInitialDataFetched(true);

                    console.log("Fetched terminal names and updated state.");
                }
            } catch (error) {
                if (isMounted) setErrorMessage("Failed to fetch initial data");
                console.error("Error while fetching initial data:", error);
            }
        };

        fetchInitialData();

        // Cleanup function to prevent state updates after unmount
        return () => {
            isMounted = false;
        };
    }, [isOpen, getDojoApiKey, getDojoTerminalNames, initialDataFetched]);

    const handleConfigureTerminals = useCallback(async (key: string) => {
        setErrorMessage("");
        setTerminals([]);

        try {
            const fetchedTerminals = await executeGetTerminalsAndSaveToken(key);
            if (fetchedTerminals) {
                // @ts-ignore
                setTerminals(fetchedTerminals);
            }
        } catch (error) {
            const message = (error as Error).message;
            if (message === "404: Not Found" || message === "401: Unauthorized") {
                setErrorMessage("Invalid API Key, please check and try again.");
            }
        }
    }, [executeGetTerminalsAndSaveToken]);

    const handleFriendlyNameChange = useCallback((terminalId: string, value: string) => {
        setFriendlyNames(names => ({
            ...names,
            [terminalId]: value,
        }));
    }, []);

    const handleNext = useCallback(async () => {
        for (const [terminalId, name] of Object.entries(friendlyNames)) {
            await setDojoTerminalNames(terminalId, name);
        }
        setTabIndex((prevIndex) => prevIndex + 1);
    }, [friendlyNames, setDojoTerminalNames]);

    return (
        <>
            <Button onClick={onOpen} colorScheme='green'>Dojo settings</Button>

            <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
                <ModalOverlay />
                <ModalContent width="90%">
                    <ModalHeader>Dojo Settings</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                        <Tabs index={tabIndex} onChange={setTabIndex} isLazy>
                            <TabPanels>
                                <TabPanel>
                                    <Flex align="center" mb={2}>
                                        <FormControl>
                                            <Input
                                                value={apiKey}
                                                onChange={e => setApiKey(e.target.value)}
                                                placeholder="Enter Dojo API Key"
                                                type="password"
                                                mr={3}
                                            />
                                        </FormControl>
                                        <IconButton
                                            aria-label="Configure Terminals"
                                            icon={<CheckIcon />}
                                            onClick={() => handleConfigureTerminals(apiKey)}
                                            colorScheme="green"
                                            borderRadius="5"
                                            ml={3}
                                        />
                                    </Flex>

                                    {errorMessage &&
                                        <Alert status='error'>
                                            <AlertIcon />
                                            {errorMessage}
                                        </Alert>
                                    }

                                    {terminals.length > 0 &&
                                        <VStack spacing={1}>
                                            <Flex alignItems="center">
                                                <CheckIcon color="green.500" />
                                                <Text ml={2}>{terminals.length} dojo Terminals Discovered</Text>
                                            </Flex>
                                            <Button mt={2} colorScheme="blue" onClick={() => setTabIndex(1)}>
                                                Configure Terminal Mapping
                                            </Button>
                                        </VStack>
                                    }
                                </TabPanel>

                                <TabPanel>
                                    <Text>Now you may configure a name for each of your dojo terminals to assist with identifying them more easily.</Text>
                                    <Table variant="striped" size='sm' mt={2}>
                                        <Thead>
                                            <Tr>
                                                <Th>Dojo Terminal</Th>
                                                <Th>Friendly Name</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {terminals.map((terminal) => (
                                                <Tr key={terminal.id}>
                                                    <Td>{terminal.properties.tid}</Td>
                                                    <Td>
                                                        <Input
                                                            placeholder="Enter friendly name"
                                                            value={friendlyNames[terminal.id] || ""}
                                                            onChange={(e) => handleFriendlyNameChange(terminal.id, e.target.value)}
                                                        />
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                    <Flex justify="flex-end" mt={4}>
                                        <Button colorScheme="blue" onClick={handleNext}>
                                            Next
                                        </Button>
                                    </Flex>
                                </TabPanel>

                                <TabPanel>
                                    <Text>Now you may configure the default dojo terminal for each of your seamless terminals.</Text>
                                    <Text mt={2}>If you do not select a default terminal, the user will be presented a list of all available terminals to choose from when starting a transaction.</Text>
                                    <Text mt={2}>You can find your dojo terminal id on any receipt, under 'tid'.</Text>
                                    <Table variant="striped" size='sm' mt={2}>
                                        <Thead>
                                            <Tr>
                                                <Th>POS Terminal</Th>
                                                <Th>Select dojo Terminal</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {posTerminals?.map((posTerminal: { _id: string, terminalNumber: string }) => (
                                                <Tr key={posTerminal._id}>
                                                    <Td>{posTerminal.terminalNumber}</Td>
                                                    <Td>
                                                        <Select
                                                            placeholder="No default terminal"
                                                            defaultValue={terminalMappings.find(mapping => mapping.terminal === posTerminal._id)?.dojoTid || ""}
                                                            onChange={(e) => setDojoTerminalMap(posTerminal._id, e.target.value)}
                                                        >
                                                            {terminals.map((terminal) => (
                                                                <option key={terminal.id} value={terminal.id}>
                                                                    {friendlyNames[terminal.id] + " " + terminal.properties.tid}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
