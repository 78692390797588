// Chakra imports
import { Flex, Text, useColorModeValue, Badge, Box } from '@chakra-ui/react';

export default function SalesByProductRow(props: {
    transactions: number;
    sum: string;
    name: string;
    color?: string;  // Added color prop
    [x: string]: any
}) {
    const { transactions, sum, name, color, ...rest } = props;

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const badgeColor = 'green.500';

    return (
        <Box
            w='full'
            p='10px'
            bg={useColorModeValue('white', 'gray.700')}
            borderRadius='8px'
            boxShadow='md'
            display='flex'
            {...rest}>

            <Box
                w='6px'
                bg={color}
                borderRadius='8px 0 0 8px'
            ></Box>

            <Flex
                justifyContent='space-between'
                alignItems='center'
                w='full'
                pl='10px'
                flexDirection={{ base: 'column', md: 'row' }}>

                <Flex direction='column' align={{ base: 'center', md: 'start' }}>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                        {name}
                    </Text>
                    <Text color={textColor} fontSize='md' fontWeight='500'>
                        {transactions} sold
                    </Text>
                </Flex>

                <Badge
                    ms={{ base: '0', md: 'auto' }}
                    colorScheme='green'
                    color={badgeColor}
                    fontSize='sm'
                    mt={{ base: '5px', md: '0' }}>
                    {sum}
                </Badge>
            </Flex>
        </Box>
    );
}
