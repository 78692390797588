
// Chakra imports
import {Flex, Spinner} from '@chakra-ui/react';
import Card from 'components/card/Card';

import UserTable from "./components/UserTable";
import useGetUsers from "../../../api/users/getUsers";

export default function ListUsers() {
    const [{data, isLoading, isError, refetch}] = useGetUsers();
    return (
        <Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
            <Card px='0px'>
                {isLoading &&
                    <Spinner/>
                }
                {data?.length > 0 &&
                    <UserTable tableData={data} refetch={refetch} key={data?.length} />
                }
            </Card>
        </Flex>
    );
}
