/* eslint-disable */

import {
    Badge,
    Button,
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Icon,
    Stack,
    Spacer,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    FormControl,
    FormLabel,
    Input,
    ModalFooter, useDisclosure, Select
} from '@chakra-ui/react';
// Custom components
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import * as React from 'react';
// Assets
import { SearchBar } from "components/navbar/searchBar/SearchBar";

import { Link } from "react-router-dom";

import {
    PaginationState,
    createColumnHelper,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
} from '@tanstack/react-table'
import {AddIcon, TriangleDownIcon, TriangleUpIcon} from "@chakra-ui/icons";
import {useNavigate} from "react-router-dom";
import CustomCirclePicker from "../../productList/components/CustomCirclePicker";
import useCreateLayout from "../../../../../api/productLibrary/createLayout";
import useManageModifierGroups from "../../../../../api/productLibrary/manageModifierGroups";
import AddNewCard from "../../../../../components/AddNewCard";

type Product = {
    _id: string;
    name: string;
    price: string;
    btnColour: string;
    category: {
        name: string;
    }
    steps: [any];
};

export default function ModifierGroupTable(props: { tableData: any }) {
    const {tableData} = props;
    const textColor = useColorModeValue("navy.700", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    const brandColor = useColorModeValue("brand.500", "brand.400");

    const [name, setName] = React.useState(''); // State for storing layout name
    const [color, setColor] = React.useState(''); //

    const {isOpen, onOpen, onClose} = useDisclosure();

    const {createLayout, isLoading, isError} = useCreateLayout();

    const [groupName, setGroupName] = React.useState(''); // State for storing the Group Name
    const [type, setType] = React.useState('INSTRUCTION'); // State for storing the Type
    const {addModifierGroup} = useManageModifierGroups()

    const handleColourChange = (colour: any) => {
        console.log("COLOR", colour)
        setColor(colour);
    }

    const handleAddNewGroup = async () => {
        if (!groupName || !type) {
            alert("Both 'Group Name' and 'Type' fields must be set!");
            return;
        }
        const newGroup = await addModifierGroup({
            name: groupName,
            type: type,
        });

        navigate(`/admin/library/modifiergrouplist/${newGroup}`);

        onClose();

        // Reset form
        setName('');
        setColor('');
        setGroupName('');
        setType('INSTRUCTION');
    }

    const navigate = useNavigate();

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
        []
    )
    let defaultData = tableData? tableData : [];
    const [globalFilter, setGlobalFilter] = React.useState('')
    const columnHelper = createColumnHelper<Product>();
    const columns = [
        columnHelper.accessor('name', {
            id: 'name',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '14px', lg: '16px'}}
                    color='blue.700'>
                    GROUP NAME
                </Text>
            ),
            cell: (info: any) => (
                <Text color={textColor} fontSize='xl' fontWeight='500'>
                    {info.getValue()}
                </Text>
            )
        }),
        columnHelper.accessor('steps', {
            id: 'stepsCount',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '14px', lg: '16px'}}
                    color='blue.700'>
                    NUMBER OF STEPS
                </Text>
            ),
            cell: (info) => (
                <Text color={textColor} fontSize='xl' fontWeight='500'>
                    {info.getValue()?.length}
                </Text>
            )
        }),
        columnHelper.accessor('_id', {
            id: 'edit',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '14px', lg: '16px'}}
                    color='blue.700'>
                    EDIT
                </Text>
            ),
            cell: (info) => (
                <Text color={textColor} fontSize='xl' fontWeight='500'>
                    <Link to={"/admin/library/modifiergrouplist/" + info.getValue()}>EDIT</Link>
                </Text>
            )
        }),
    ];
    const [data, setData] = React.useState(() => [...defaultData]);
    const [{pageIndex, pageSize}, setPagination] =
        React.useState<PaginationState>({
            pageIndex: 0,
            pageSize: 25,
        })

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )
    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
            globalFilter,
            pagination,
        },
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
    })
    const createPages = (count: number) => {
        let arrPageCount = [];

        for (let i = 1; i <= count; i++) {
            arrPageCount.push(i);
        }

        return arrPageCount;
    };

    React.useEffect(() => {
        if (table.getState().columnFilters[0]?.id === 'name') {
            if (table.getState().sorting[0]?.id !== 'name') {
                table.setSorting([{id: 'name', desc: false}])
            }
        }
    }, [table.getState().columnFilters[0]?.id])


    if ((!data || data.length === 0)) {
        return (
            <Flex justify='center' alignItems='center'>
                <Flex maxW={'50%'}>
                    <AddNewCard name={"Modifier Group"} pluralName={"Modifier Groups"}
                                action={() => onOpen()}/>
                </Flex>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader>Add New Modifier Group</ModalHeader>
                        <ModalCloseButton/>
                        <ModalBody pb={6}>
                            <FormControl>
                                <FormLabel>Group Name</FormLabel>
                                <Input placeholder="Group Name" value={groupName}
                                       onChange={(e) => setGroupName(e.target.value)}/>
                            </FormControl>

                            <FormControl mt={4}>
                                <FormLabel>First Step Type</FormLabel>
                                <Select placeholder="Select type" value={type}
                                        onChange={(e) => setType(e.target.value)}>
                                    <option value="INSTRUCTION">Instruction</option>
                                    <option value="PRODUCT">Product</option>
                                </Select>
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleAddNewGroup}>
                                Save
                            </Button>
                            <Button onClick={() => onClose()}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Flex>
        )
    } else {

        return (
            <Flex
                direction='column'
                w='100%'
                overflowX={{sm: "scroll", lg: "hidden"}}>
                <Flex
                    align={{sm: "flex-start", lg: "flex-start"}}
                    justify={{sm: "flex-start", lg: "flex-start"}}
                    w='100%'
                    px='22px'
                    mb='36px'>
                    <DebouncedInput
                        value={globalFilter ?? ''}
                        onChange={value => setGlobalFilter(String(value))}
                        className="p-2 font-lg shadow border border-block"
                        placeholder="Search..."
                    />
                    <Spacer/>
                    <Button colorScheme='green' rightIcon={<AddIcon/>} onClick={onOpen}>Add New</Button>
                </Flex>
                <Table variant='simple' color='gray.500' mb='24px'>
                    <Thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <Th
                                            pe='10px'
                                            borderColor={borderColor} key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder ? null : (
                                                <Flex
                                                    {...{
                                                        className: header.column.getCanSort()
                                                            ? 'cursor-pointer select-none'
                                                            : '',
                                                        onClick: header.column.getToggleSortingHandler(),
                                                    }}

                                                    align='center'
                                                    fontSize={{sm: "10px", lg: "12px"}}
                                                    color='blue.700'>
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    {{
                                                        asc: <TriangleUpIcon aria-label="sorted ascending"/>,
                                                        desc: <TriangleDownIcon aria-label="sorted descending"/>,
                                                    }[header.column.getIsSorted() as string] ?? null}
                                                </Flex>
                                            )}
                                        </Th>
                                    )
                                })}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map(row => {
                            return (
                                <Tr px="20px" key={row.id}>
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <Td key={cell.id}
                                                fontSize={{sm: "14px"}}
                                                minW={{sm: "150px", md: "200px", lg: "auto"}}
                                                borderColor={borderColor}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </Td>
                                        )
                                    })}
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
                <Flex w="100%" justify="space-between" px="20px" pt="10px" pb="5px">
                    {/* SET ROW NUMBER */}
                    <Text
                        fontSize='sm'
                        color='gray.500'
                        fontWeight='normal'
                        mb={{sm: "24px", md: "0px"}}>
                        Showing {pageSize * pageIndex + 1} to{" "}
                        {pageSize * (pageIndex + 1) <= tableData.length
                            ? pageSize * (pageIndex + 1)
                            : tableData.length}{" "}
                        of {tableData.length} entries
                    </Text>
                    {/* PAGINATION BUTTONS */}
                    <div className="flex items-center gap-2">
                        <Stack direction='row' alignSelf='flex-end' spacing='4px' ms='auto'>
                            <Button
                                variant='no-effects'
                                onClick={() => table.previousPage()}
                                disabled={!table.getCanPreviousPage()}
                                transition='all .5s ease'
                                w='40px'
                                h='40px'
                                borderRadius='50%'
                                bg='transparent'
                                border='1px solid'
                                borderColor={useColorModeValue("gray.200", "white")}
                                display={
                                    pageSize === 5 ? "none" : table.getCanPreviousPage() ? "flex" : "none"
                                }
                                _hover={{
                                    bg: "whiteAlpha.100",
                                    opacity: "0.7",
                                }}>
                                <Icon as={MdChevronLeft} w='16px' h='16px' color={textColor}/>
                            </Button>
                            {createPages(table.getPageCount()).map((pageNumber, index) => {
                                return (
                                    <Button
                                        variant='no-effects'
                                        transition='all .5s ease'
                                        onClick={() => table.setPageIndex(pageNumber - 1)}
                                        w='40px'
                                        h='40px'
                                        borderRadius='50%'
                                        bg={
                                            pageNumber === pageIndex + 1 ? brandColor : "transparent"
                                        }
                                        border={
                                            pageNumber === pageIndex + 1
                                                ? "none"
                                                : "1px solid lightgray"
                                        }
                                        _hover={
                                            pageNumber === pageIndex + 1
                                                ? {
                                                    opacity: "0.7",
                                                }
                                                : {
                                                    bg: "whiteAlpha.100",
                                                }
                                        }
                                        key={index}>
                                        <Text
                                            fontSize='sm'
                                            color={pageNumber === pageIndex + 1 ? "#fff" : textColor}>
                                            {pageNumber}
                                        </Text>
                                    </Button>
                                );
                            })}
                            <Button
                                variant='no-effects'
                                onClick={() => table.nextPage()}
                                disabled={!table.getCanNextPage()}
                                transition='all .5s ease'
                                w='40px'
                                h='40px'
                                borderRadius='50%'
                                bg='transparent'
                                border='1px solid'
                                borderColor={useColorModeValue("gray.200", "white")}
                                display={pageSize === 5 ? "none" : table.getCanNextPage() ? "flex" : "none"}
                                _hover={{
                                    bg: "whiteAlpha.100",
                                    opacity: "0.7",
                                }}>
                                <Icon as={MdChevronRight} w='16px' h='16px' color={textColor}/>
                            </Button>
                        </Stack>
                    </div>

                </Flex>

                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader>Add New Modifier Group</ModalHeader>
                        <ModalCloseButton/>
                        <ModalBody pb={6}>
                            <FormControl>
                                <FormLabel>Group Name</FormLabel>
                                <Input placeholder="Group Name" value={groupName}
                                       onChange={(e) => setGroupName(e.target.value)}/>
                            </FormControl>

                            <FormControl mt={4}>
                                <FormLabel>First Step Type</FormLabel>
                                <Select placeholder="Select type" value={type}
                                        onChange={(e) => setType(e.target.value)}>
                                    <option value="INSTRUCTION">Instruction</option>
                                    <option value="PRODUCT">Product</option>
                                </Select>
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleAddNewGroup}>
                                Save
                            </Button>
                            <Button onClick={() => onClose()}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

            </Flex>


        )
    }

// A debounced input react component
    function DebouncedInput({
                                value: initialValue,
                                onChange,
                                debounce = 500,
                                ...props
                            }: {
        value: string | number
        onChange: (value: string | number) => void
        debounce?: number
    } & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
        const [value, setValue] = React.useState(initialValue)

        React.useEffect(() => {
            setValue(initialValue)
        }, [initialValue])

        React.useEffect(() => {
            const timeout = setTimeout(() => {
                onChange(value)
            }, debounce)

            return () => clearTimeout(timeout)
        }, [value])

        return (
            <SearchBar
                {...props}
                value={value}
                onChange={(e: any) => setValue(e.target.value)}
                h='44px'
                w={{lg: "390px"}}
                borderRadius='16px'
            />
        )
    }
}
