// Chakra imports
import {Box, Button, Flex, FormControl, Heading, useColorModeValue} from '@chakra-ui/react';

// Custom components
import DefaultAuth from 'layouts/auth/variants/Default';

// Assets
import illustration from 'assets/img/auth/auth.png';

import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

declare global {
    interface Window { callFromSwift: any; }
}
function AppLogin() {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    window.callFromSwift = function (text: string) {
        alert(text);
        console.log("RECD");
    };
    const {login, register, getToken} = useKindeAuth();
    const navigate = useNavigate();

    // eslint-disable-next-line no-restricted-globals
    const query = new URLSearchParams(location.search);
    const loggedOut = query.get('loggedOut')

    useEffect(() => {
        const checkIfTokenExists = async () => {

            let accessToken = null;

            try {
                accessToken = await getToken();
            } catch (e) {

            }
            if (accessToken) {
                navigate('/admin/dashboard');
            } else {
                //  await login();
            }

        }


        if(loggedOut !== 'y') {
            checkIfTokenExists()
        }

    }, [login, getToken, loggedOut]);


    // Chakra color mode
    const textColor = useColorModeValue('navy.700', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                w='100%'
                maxW='max-content'
                mx={{base: 'auto', lg: '0px'}}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{base: '30px', md: '60px', lg: '120px', xl: '60px'}}
                px={{base: '25px', md: '0px'}}
                mt={{base: '40px', lg: '18vh', xl: '22vh'}}
                flexDirection='column'>
                <Box me='auto' mb='34px'>
                    <Heading color={textColor} fontSize='36px' mb='16px'>
                        Login to the backoffice
                    </Heading>
                </Box>
                <Flex
                    zIndex='2'
                    direction='column'
                    w={{base: '100%', lg: '420px'}}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{base: 'auto', lg: 'unset'}}
                    me='auto'
                    mb={{base: '20px', md: 'auto'}}>
                    <FormControl>
                        <Button fontSize='14px' variant='brand' fontWeight='500' w='100%' h='50' mb='24px'
                                onClick={() => login()}>
                            Login!
                        </Button>
                    </FormControl>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default AppLogin;
