import React, {useState, createContext, useCallback, useEffect} from 'react';
import {usePresence, useChannel} from "ably/react";

export const DeviceContext = createContext<{
    presentDevices: any[];
    setPresentDevices: React.Dispatch<React.SetStateAction<any[]>>;
    triggerOrdersRefetch: () => void;
    trigger: number;
} | undefined>(undefined);

export const DeviceProvider = (props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal; }) => {
   // const { presenceData, updateStatus } = usePresence('tableOrders');

    const [presentDevices, setPresentDevices] = useState([]);
    const [tableOrdersTrigger, setTableOrdersTrigger] = useState(Date.now());

    const [trigger, setTrigger] = useState(0);
    const triggerOrdersRefetch = () => {
        setTrigger(oldTrigger => oldTrigger + 1); // increment trigger state.
    };

   /* const { channel } = useChannel('tableOrders', (message) => {

        console.log("MESSAGE", message)

        triggerOrdersRefetch();

    }); */

  /*  useEffect(() => {
        const newPresentDevices = presenceData.filter(item => item.clientId !== 'backoffice');
        setPresentDevices(newPresentDevices);

    }, [presenceData]);

    useEffect(() => {
    console.log("Present devices", presentDevices)

    }, [presentDevices]); */

    return (
        <DeviceContext.Provider value={{ presentDevices, setPresentDevices, triggerOrdersRefetch, trigger}}>
            {props.children}
        </DeviceContext.Provider>
    )
};
