import { useApp } from "@realm/react";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { ObjectId } from "bson";

type SavePrintRouteParams = {
    routeId: ObjectId;
    name: string;
    printer: string;
    copies: number;
    categories: { category: ObjectId; position: number }[];
};

const useSavePrintRoute = () => {
    const app = useApp();
    const { user } = useContext(UserContext);

    const savePrintRoute = async (params: SavePrintRouteParams) => {
        if (!user) {
            throw new Error("User is not authenticated");
        }

        try {
            const mongo = user.mongoClient("mongodb-atlas");
            const printRouteCollection = mongo.db("pos").collection("PrintRoute");

            const convertedCategories = params.categories.map((cat) => ({
                category: { $oid: cat.category.toString() },
                position: cat.position,
            }));

            // Upsert the print route
            await printRouteCollection.updateOne(
                { _id: {$oid: params.routeId.toString() }},
                {
                    $set: {
                        name: params.name,
                        printer: params.printer,
                        copies: params.copies,
                        category: convertedCategories,
                    },
                },
                { upsert: true }
            );
        } catch (error) {
            throw new Error(`Failed to save print route`);
        }
    };

    return savePrintRoute;
};

export default useSavePrintRoute;
