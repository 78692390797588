import { gql, useMutation } from '@apollo/client';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useApp } from "@realm/react";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

interface UpdateLayoutInput {
    _id: string;
    name: string;
    icon: string;
}

const UPDATE_LAYOUT = gql`
    mutation UpdateLayout($_id: ObjectId!, $updateLayout: LayoutUpdateInput!) {
        updateOneLayout(query: { _id: $_id }, set: $updateLayout) {
            _id,
            name,
            btnColour,
            owner_id,
            siteId
        }
    }
`;

const useUpdateLayout = () => {

    const app = useApp();
    const { user } = useContext(UserContext);

    const [updateLayout, { loading: isLoading, error: isError }] = useMutation(
        UPDATE_LAYOUT,
        {
            context: {
                headers: {
                    Authorization: "Bearer " + user?.accessToken,
                }
            }
        }
    );

    const updateLayoutById = async (updateData: UpdateLayoutInput) => {
        await updateLayout({
            variables: {
                _id: updateData._id,
                updateLayout: {
                    name: updateData.name,
                    icon: updateData.icon,
                    owner_id: app.currentUser.id,
                    siteId: app.currentUser.customData.currentSite.toString(),
                    enabled: true
                }
            }
        });
    }

    return { updateLayoutById, isLoading, isError };
};

export default useUpdateLayout;
