// Chakra imports
import {Flex, SimpleGrid, Box} from '@chakra-ui/react';
import Card from 'components/card/Card';

import TerminalCard from "../../../components/TerminalCard";
import { css } from '@emotion/react';
import useGetTerminals from "../../../api/terminals/getTerminals";
import { Key } from 'react';

const gridItemStyle = css`
    max-width: 460px;
`

export default function ListTerminals() {

    const [{data}] = useGetTerminals();

    return (
        <Flex direction='column' pt={{sm: '125px', lg: '75px'}}>
            <Box gridArea='1 / 1 / 2 / 2'>
                <SimpleGrid columns={{base: 1, md: 2, '2xl': 3}} gap='20px' mb='20px'>
                    {data && data.map((terminal: any, index: Key) => (
                        <Box key={index} css={gridItemStyle} maxW={460}>
                            <TerminalCard terminal={terminal}/>
                        </Box>
                    ))}

                </SimpleGrid>
            </Box>

        </Flex>
    );
}
