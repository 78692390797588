import { Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, FormControl, FormLabel, Input, NumberInput, NumberInputField, useToast } from "@chakra-ui/react"
import React from "react";
import {useCreateTaxRate} from "../../../../api/productLibrary/createTaxRate";
import {AddIcon} from "@chakra-ui/icons";

type AddTaxRateButtonProps = {
    onTaxRateCreated: () => void;
}

export default function AddTaxRateButton({onTaxRateCreated}: AddTaxRateButtonProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = React.useRef();
    const toast = useToast();

    const [newRate, setNewRate] = React.useState({name: "", rate: 0});
    const [createTaxRate, { isLoading }] = useCreateTaxRate();

    const handleSubmit = async () => {
        if (isNaN(newRate.rate) || newRate.rate < 0 || newRate.rate > 100) { // as rate is within 0 and 1 so; limit is between 0 and 1
            toast({
                title: "Invalid input",
                description: "Rate must be a number between 0 and 100, you entered "+ newRate.rate,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right"
            })
        } else {
            newRate.rate = newRate.rate * 100;
           await createTaxRate(newRate);
            toast({
                title: "Tax Rate Created",
                description: "Your new tax rate has been created successfully!",
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top-right"
            })
            onClose();


                onTaxRateCreated();

        }
    };

    return (
        <>
            <Button colorScheme='green' rightIcon={<AddIcon/>} onClick={onOpen} m={'10px'} >Add New</Button>

            <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add a new tax rate</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input ref={initialRef} value={newRate.name} onChange={(event) => setNewRate({...newRate, name: event.target.value})} />

                            <FormLabel mt="4">Rate</FormLabel>
                            <NumberInput precision={2} value={newRate.rate} onChange={(value) => setNewRate({...newRate, rate: parseFloat(value)})}>
                                <NumberInputField />
                            </NumberInput>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
                            Save
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
