import {useContext} from 'react';
import {UserContext} from '../../contexts/UserContext';
import {useQuery, gql} from '@apollo/client';
import {reportsClient} from '../apolloClients/reportsClient';


const GET_TRANSACTION_DATA = gql`
    query Query($startDate: String, $endDate: String) {
        detailedOrderData(startDate: $startDate, endDate: $endDate) {
            orderId
            date
            discounts
            grossAmount
            name
            netAmount
            orderNumber
            taxAmount
            terminalNumber
            userName
            categoryName
            orderType
        }
    }
`;

export const useGetSalesData = (startDate: string, endDate: string) => {
    const {token} = useContext(UserContext);
    const shouldSkip = !token;

    const {data, loading: apolloLoading, error, refetch} = useQuery(GET_TRANSACTION_DATA,
        {
            variables: {
                startDate: startDate,
                endDate: endDate
            },
            context: {
                headers: {
                    Authorization: "Bearer " + token,
                },
            },
            skip: shouldSkip,
            client: reportsClient,
        });

    const isLoading = apolloLoading || shouldSkip;

    console.log("RAW DATA", data);

    return [{
        data: data?.detailedOrderData,
        isLoading,
        isError: !!error,
        refetch,
    }] as const;
};


