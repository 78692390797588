import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Flex, Text, Icon, Button } from '@chakra-ui/react';
import { DragHandleIcon, CloseIcon } from '@chakra-ui/icons';
import { ObjectId } from 'bson';

interface CategoryObject {
    category: ObjectId;
    position: number;
}

interface DraggableCategoryProps {
    categoryObj: CategoryObject;
    index: number;
    moveCategory: (fromIndex: number, toIndex: number) => void;
    getCategoryNameById: (categoryId: ObjectId) => string;
    removeCategory: (index: number) => void;
}

const DraggableCategory: React.FC<DraggableCategoryProps> = ({
                                                                 categoryObj,
                                                                 index,
                                                                 moveCategory,
                                                                 getCategoryNameById,
                                                                 removeCategory,
                                                             }) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [, drop] = useDrop({
        accept: 'CATEGORY',
        hover(item: { index: number }) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            moveCategory(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'CATEGORY',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <Flex
            ref={ref}
            p={2}
            bgColor="gray.100"
            rounded="md"
            mt={1}
            alignItems="center"
            borderWidth="1px"
            borderColor="gray.300"
            cursor="grab"
            opacity={isDragging ? 0.5 : 1}
        >
            <Icon as={DragHandleIcon} mr={2} />
            <Text flex="1">{getCategoryNameById(categoryObj.category)}</Text>
            <Button size="xs" colorScheme="red" onClick={() => removeCategory(index)}>
                <CloseIcon />
            </Button>
        </Flex>
    );
};

export default DraggableCategory;
