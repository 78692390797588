import React, {useRef, useState} from 'react';
import {
    Box,
    Text,
    Button,
    IconButton,
    Select,
    Stack,
    VStack,
    HStack,
    Circle,
    useColorModeValue,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialog,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    FormControl, FormLabel, Input
} from '@chakra-ui/react';
import {CloseIcon} from '@chakra-ui/icons';
import ModifierItem from "./ModifierItem";
import useManageModifierGroups from "../../../../../api/productLibrary/manageModifierGroups";
import ModifierProductList from "./ModifierProductList";

const InstructionModifierGroupCard = (props: {
    step: number;
    stepType: string;
    modifierItems: [];
    allowedChoices: number;
    modifierStep: string;
    modifierGroupId: string;
    refetch: any;
}) => {
    const {step, stepType, modifierItems, allowedChoices, modifierStep, modifierGroupId, refetch} = props;

    const [isAlertOpen, setAlertOpen] = useState(false);
    const cancelRef = useRef();
    const onCloseAlert = () => setAlertOpen(false);

    const onDelete = () => {
        deleteStep(modifierStep, modifierGroupId).then(() => refetch());
        setAlertOpen(false);
    };

    const {isOpen: isProductOpen, onOpen: onProductOpen, onClose: onProductClose} = useDisclosure();

    const [modifierName, setModifierName] = useState("");

    const {addModifier, deleteStep} = useManageModifierGroups();


    return (
        <Box p={4} borderWidth="0px" borderRadius="xl" backgroundColor="#FBF1E5">
            <HStack justifyContent="space-between">
                <HStack spacing={4}>
                    <Circle size="48px" bg="#995200" color="white">
                        <Text fontSize="28px" fontWeight={"700"}>{step}</Text>
                    </Circle>
                    <VStack align="flex-start" spacing={0}>
                        <Text fontSize="lg" fontWeight="bold">Instruction Step</Text>
                        <Text fontSize="sm">Add an instruction, e.g. a cooking instruction like Rare.</Text>
                    </VStack>
                </HStack>
                {step !== 1 &&
                    <Button colorScheme="red" variant="outline" size="md" onClick={() => setAlertOpen(true)}>
                        Delete
                    </Button>
                }
            </HStack>
            <VStack align="stretch" mt={4} backgroundColor={"white"} p={2} borderRadius="lg" pt={4}>

                {modifierItems && modifierItems.map((modifier: {
                        name: string;
                        _id: string;
                        type: string;
                        product: { name: string };
                    }, index: React.Key) =>
                        <ModifierItem key={modifier._id}
                                      name={(modifier.type === "PRODUCT") ? modifier.product.name : modifier.name}
                                      stepId={modifierStep} modifierId={modifier._id} refetch={refetch} mb={"10px"}
                                      bg={"#F6F7F9"} hilightColour={"#995200"} type={"instruction"} orderModes={[]} taxRates={[]} productId={""}/>
                )}

                <Button variant="outline" mt={2} borderStyle={'dashed'} py={'30px'} color={'#995200'}
                        borderColor={'#995200'} onClick={() => onProductOpen()}>Add Instruction</Button>
            </VStack>
            <Box mt={4} p={4} bg="white" borderRadius="xl">
                <HStack justifyContent="space-between">
                    <Text>Go to the next step after the user has made one choice</Text>

                </HStack>
            </Box>

            <AlertDialog
                leastDestructiveRef={cancelRef}
                isOpen={isAlertOpen}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Step
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You cannot undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={onDelete} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            <Modal isOpen={isProductOpen} onClose={onProductClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Instruction</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Modifier Name</FormLabel>
                            <Input value={modifierName} onChange={(e) => setModifierName(e.target.value)} placeholder="Modifier name" />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={() => {
                            addModifier(modifierStep, { name: modifierName, type: "INSTRUCTION" }).then(() => { refetch(); onProductClose(); })
                        }}>
                            Save
                        </Button>
                        <Button onClick={onProductClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default InstructionModifierGroupCard;
