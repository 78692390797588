import Dinero from "dinero.js";

export function formatCurrency(input: any) {

  //  console.log("Formatting C", input)

    if(input === null || input === undefined || isNaN(input)) {
        input = 0;
    }

    const inp = Number(input);
    const cur = Dinero({amount: inp, currency: 'GBP'});
    return cur.toFormat();

}
