import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Flex,
    Tabs,
    TabPanel,
    TabPanels,
    Tab,
    TabList,
    Input,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useManageSumupSettings from "../../../../api/site/manageSumupSettings";
import {m} from "framer-motion";

interface SumupSettingsModalProps {
    isOpen: boolean;
    onClose: () => void;
    authCode: string;
}

const SumupSettingsModal: React.FC<SumupSettingsModalProps> = ({ isOpen, onClose, authCode }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [token, setToken] = useState<string | null>(null);
    const [refreshToken, setRefreshToken] = useState<string | null>(null);
    const [merchantCode, setMerchantCode] = useState<string | null>(null);
    const [readers, setReaders] = useState<any[]>([]);
    const [pairingCode, setPairingCode] = useState<string>("");

    const { setSumupTokens, setSumupReaders } = useManageSumupSettings();

    const initiateOAuthFlow = () => {
        const clientId = 'cc_classic_luXfRye45p2oytJHVxikgeoRJ8MX8';
        const redirectUri = 'https://beta.seamlesshq.co.uk/admin/settings';
        const scope = 'payments readers.read readers.write user.profile_readonly transactions.history';
        const sumupAuthURL = `https://api.sumup.com/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${encodeURIComponent(scope)}`;

        window.location.href = sumupAuthURL;
    };

    useEffect(() => {
        if (authCode) {
            // Send the authorization code to the serverless function for token exchange
            const fetchToken = async () => {
                try {
                    const response = await fetch('/api/sumup-exchange-code', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ code: authCode })
                    });

                    if (response.ok) {
                        const data = await response.json();
                        const { accessToken, refreshToken, authTokenExpiry } = data;
                        setToken(accessToken);
                        setRefreshToken(refreshToken);
                        // Call the setSumupTokens hook
                        await setSumupTokens(accessToken, refreshToken, authTokenExpiry);
                    } else {
                        const errorData = await response.json();
                        console.error('Error exchanging code:', errorData);
                    }
                } catch (error) {
                    console.error('Error fetching token from backend:', error);
                }
            };

            fetchToken();
        }
    }, [authCode]);

    useEffect(() => {
        if (token) {
            const fetchMerchantCode = async () => {
                try {
                    const response = await fetch('https://api.sumup.com/v0.1/me', {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    const data = await response.json();
                    setMerchantCode(data.merchant_profile?.merchant_code);
                } catch (error) {
                    console.error('Error fetching merchant code:', error);
                }
            };

            fetchMerchantCode();
        }
    }, [token]);

    useEffect(() => {
        if (merchantCode) {
            const fetchReaders = async () => {
                try {
                    const response = await fetch(`https://api.sumup.com/v0.1/merchants/${merchantCode}/readers`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    const data = await response.json();
                    setReaders(data.items || []);

                    for (let i = 0; i < data.items.length; i++) {
                        const reader = data.items[i];
                        await setSumupReaders(merchantCode, [{ id: reader.id, name: reader.name ? reader.name : null }]);
                    }
                } catch (error) {
                    console.error('Error fetching readers:', error);
                }
            };

            fetchReaders();
        }
    }, [merchantCode]);

    const handlePairReader = async () => {
        if (!pairingCode) {
            console.error("Pairing code is required");
            return;
        }
        if (!merchantCode || !token) {
            console.error("Merchant code or token is missing");
            return;
        }

        try {
            const response = await fetch(`https://api.sumup.com/v0.1/merchants/${merchantCode}/readers`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ pairing_code: pairingCode }),
            });

            if (response.ok) {
                const data = await response.json();
                // Assuming the response contains the newly paired reader info
                await setSumupReaders(merchantCode, [{ id: data.id, name: data.name }]);
                setReaders([...readers, { id: data.id, name: data.name }]);
                setPairingCode("");  // Clear the input field
            } else {
                const errorData = await response.json();
                console.error('Error pairing reader:', errorData);
            }
        } catch (error) {
            console.error('Error during pairing reader:', error);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
            <ModalOverlay />
            <ModalContent width="90%">
                <ModalHeader>Sumup Settings</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {merchantCode && (
                        <Flex align="center" mb={2}>
                            <p>Your merchant code: {merchantCode}</p>
                        </Flex>
                    )}
                    <Tabs index={tabIndex} onChange={setTabIndex} isLazy>
                        <TabList>
                            <Tab>Connect Sumup</Tab>
                            <Tab>Readers</Tab>
                            <Tab>Pair a Reader</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Flex align="center" mb={2}>
                                    <Button
                                        onClick={initiateOAuthFlow}
                                        colorScheme='blue'
                                    >
                                        Connect Sumup
                                    </Button>
                                </Flex>
                            </TabPanel>
                            <TabPanel>
                                {readers.length > 0 ? (
                                    <div>
                                        <p>Readers:</p>
                                        <ul>
                                            {readers.map((reader) => (
                                                <li key={reader.id}>{reader.name || reader.device.identifier}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <p>No readers found. Please pair a reader.</p>
                                )}
                                <Button
                                    colorScheme="blue"
                                    onClick={() => setTabIndex(2)} // Navigate to 'Pair a Reader' Tab
                                >
                                    Add a Reader
                                </Button>
                            </TabPanel>
                            <TabPanel>
                                <Flex align="center" mb={2}>
                                    <Input
                                        placeholder="Enter pairing code"
                                        value={pairingCode}
                                        onChange={(e) => setPairingCode(e.target.value)}
                                        mb={2}
                                    />
                                    <Button
                                        colorScheme="blue"
                                        onClick={handlePairReader}
                                    >
                                        Pair Reader
                                    </Button>
                                </Flex>
                            </TabPanel>
                            {/* Additional panels */}
                        </TabPanels>
                    </Tabs>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default SumupSettingsModal;
