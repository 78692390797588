import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Box, Flex, SimpleGrid, Button } from '@chakra-ui/react';
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import Tour from 'reactour';

import Delete from './components/Delete';
import Info from './components/Info';
import Pricing from './components/Pricing';
import SalesStats from "./components/SalesStats";
import ModifierGroup from "./components/ModifierGroup";
import AddModifierPortion from "./components/AddModifierPortion";
import useGetProduct from "../../../../api/productLibrary/getProduct";
import useGetProductSalesStats from "../../../../api/productLibrary/getProductSalesStats";
import ModifyPortion from "./components/ModifyPortion";

interface Props {
	setTitle?: any;
}

// @ts-ignore
export default function ProductPage(propsIn: Props) {
	const { productId } = useParams();
	const methods = useForm();
	const [isTourOpen, setIsTourOpen] = useState(false);

	const [formValues, setFormValues] = useState({});
	const { formState: { isDirty } } = methods;
	const [{ data, isLoading, isError, refetch }] = useGetProduct(productId);
	const [{ data: salesData }] = useGetProductSalesStats(productId);

	const stableData = useMemo(() => !isLoading && !isError && data, [isLoading, isError, data]);
	const stableSalesData = useMemo(() => !isError && salesData, [isError, salesData]);

	useEffect(() => {
		if (stableData) {
			propsIn.setTitle?.(stableData.name);
		}
	}, [stableData]);

	useEffect(() => {
		if (formValues) {
			console.log(formValues);
		}
	}, [formValues]);

	useEffect(() => {
		if (isError) {
			console.log("ERROR LOADING PRODUCT");
		}
	}, [isError]);

	const steps = [
		{
			selector: '.pricing',
			content: 'Ensure you setup pricing for the new portion here.',
			disableInteraction: true,
			disableDotsNavigation: true,
			showNavigation: false,
			showPrevNextButtons: false,
			showBadge: false
		},
	];

	const openTour = useCallback(() => {
		setIsTourOpen(true);
	}, []);

	return (
		<FormProvider {...methods}>
			<Tour
				steps={steps}
				isOpen={isTourOpen}
				onRequestClose={() => setIsTourOpen(false)}
				showNumber={false}
				showPrevNextButtons={false}
				showNavigation={false}
				disableDotsNavigation={true}

			/>

			<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
				<SimpleGrid columns={{ sm: 1, xl: 2 }} spacing={{ base: '20px', xl: '20px' }}>
					<Flex direction='column'>
						<Info data={stableData} refetch={refetch} />
						<Box className="pricing">
							<Pricing data={stableData} refetch={refetch} />
						</Box>
					</Flex>
					<Flex direction="column">
						<SalesStats data={stableSalesData} />
						{stableData?.modifierGroup?._id ? (
							<ModifyPortion productId={stableData?._id}
										   currentName={stableData?.modifierGroup?.name}
										   type={"modifier"}
										   refetch={refetch}
										   openTour={openTour} />
						) : stableData?.variantGroups ? (
							<ModifyPortion productId={stableData?._id}
										   currentName={stableData?.variantGroups[0]?.name}
										   type={"portion"}
										   refetch={refetch}
										   openTour={openTour} />
						) : (
							<AddModifierPortion
								productId={stableData?._id}
								refetch={refetch}
								openTour={openTour}
							/>
						)}
					</Flex>
				</SimpleGrid>
				<Delete productId={stableData?._id} refetch={refetch} />
			</Box>
		</FormProvider>
	);
}
