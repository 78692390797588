import {
    Button,
    ButtonGroup,
    useRadioGroup,
    Flex,
    Radio,
    RadioGroup,
    Spinner,
    HStack,
    Box,
    useRadio,
    Text,
    VStack
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { subDays, startOfWeek, endOfWeek, startOfDay, subWeeks, endOfDay, addDays } from 'date-fns';
import Card from 'components/card/Card';
import {useState, forwardRef, useEffect} from "react";

import "react-datepicker/dist/react-datepicker.css";
import SalesByCategoryTable from "./components/SalesByCategoryTable";
import {useGetSalesByCategory} from "../../../../api/reports/getSalesByCategory";
import {getRolloverDates} from "../../../../helpers/getRolloverDates";

import { Input } from "@chakra-ui/react";


import { CSVLink } from "react-csv";
import { Data } from "react-csv/lib/core";
import CustomDateSelector from "../../../../components/reportDateSelector";


export default function SalesByCategoryReport() {
    const dateTimeToday = getRolloverDates(1, startOfDay(new Date()));
    const [startDate, setStartDate] = useState(dateTimeToday[0]);
    const [endDate, setEndDate] = useState(dateTimeToday[1]);
    const [{
        data: orders,
        isLoading,
        isError,
        refetch
    }] = useGetSalesByCategory(startDate.toISOString(), endDate.toISOString())


    // @ts-ignore
    // @ts-ignore
    return (
        <Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
            <CustomDateSelector handleDateChange={({startDate, endDate}: {startDate: Date, endDate: Date}) => {
                console.log(startDate)
                console.log(endDate)
                setStartDate(startDate);
                setEndDate(endDate);

                setTimeout(() => {
                    refetch({
                        query: {
                            closedDate_gt: startDate.toISOString(),
                            closedDate_lt: endDate.toISOString(),
                        },
                    });
                }, 0);
            }} />
            <Card px='0px'>
                {isLoading &&
                    <Spinner/>
                }
                {orders?.length > 0 &&
                    <SalesByCategoryTable key={startDate.getTime()} tableData={orders} startDate={startDate} endDate={endDate}/>
                }
            </Card>
        </Flex>
    );
}
