// Chakra imports
import {Flex, FormControl, Icon, Text, useColorModeValue, SimpleGrid, Skeleton} from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import MiniStatistics from "../../../../../components/card/MiniStatistics";
import IconBox from "../../../../../components/icons/IconBox";
import {MdShowChart} from "react-icons/md";

export default function SalesStats(data: any) {
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';
	// Chakra Color Mode

	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

	//console.log(data);

	return (
		<Card mb='20px'>
			<Flex direction='column' mb='10px' ms='10px'>
				<Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
					Product Stats
				</Text>
			</Flex>
			<SimpleGrid columns={3} spacing={3}>
				<Skeleton isLoaded={!!data.data.count}>
					<MiniStatistics
						startContent={
							<IconBox
								w="56px"
								h="56px"
								bg={boxBg}
								icon={
									<Icon w="32px" h="32px" as={MdShowChart} color={brandColor} />
								}
							/>
						}
						name="Total Sales"
						value={data.data.count}
					/>
				</Skeleton>
				<Skeleton isLoaded={!!data.data.count}>
					<MiniStatistics
						startContent={
							<IconBox
								w="56px"
								h="56px"
								bg={boxBg}
								icon={
									<Icon w="32px" h="32px" as={MdShowChart} color={brandColor} />
								}
							/>
						}
						name="Total Revenue"
						value={data.data.total}
					/>
				</Skeleton>
				<Skeleton isLoaded={!!data.data.count}>
					<MiniStatistics
						startContent={
							<IconBox
								w="56px"
								h="56px"
								bg={boxBg}
								icon={
									<Icon w="32px" h="32px" as={MdShowChart} color={brandColor} />
								}
							/>
						}
						name="Average Sale Price"
						value={data.data.avg}
					/>
				</Skeleton>
			</SimpleGrid>
		</Card>
	);
}
