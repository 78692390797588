// hooks/useAddTableArea.ts
import { useCallback, useContext } from 'react';
import { useApp } from '@realm/react';
import { UserContext } from '../../contexts/UserContext';
import {ObjectId} from "bson";

interface TableArea {
    _id: ObjectId;
    name: string;
    siteId: string;
}

export const useAddTableArea = () => {
    const app = useApp();
    const { user } = useContext(UserContext);

    const addTableArea = useCallback(async (name: string) => {
        if (!user || !app.currentUser) {
            console.error("User is not authenticated");
            return;
        }

        const siteId = app.currentUser.customData.currentSite.toString();

        const newTableArea: TableArea = {
            _id: new ObjectId(),
            name,
            siteId,
        };

        console.log(newTableArea);

        try {
            const mongo = user.mongoClient('mongodb-atlas');
            const tableAreaCollection = mongo.db("pos").collection("TableArea");
            await tableAreaCollection.insertOne({
                ...newTableArea,
                _id: { $oid: newTableArea._id.toString() }, // Use {$oid: <id string>} format
            });
            console.log("Table area added successfully");

            // Optional: return the newTableArea if it needs to be added to the parent state
            return newTableArea;
        } catch (error) {
            console.error("Failed to add table area: ", error);
        }
    }, [user, app]);

    return { addTableArea };
};
