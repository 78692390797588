
// Chakra imports
import {Box, Flex, Spinner, useDisclosure} from '@chakra-ui/react';
import Card from 'components/card/Card';

import DiscountTable from "./components/DiscountTable";
import useGetDiscounts from "../../../../api/productLibrary/getDiscounts";
import {useState} from "react";
import AddNewCard from "../../../../components/AddNewCard";
import AddCategoryModal from "../categoryList/components/AddCategoryModal";
import * as React from "react";
import AddDiscountModal from "./components/AddDiscountModal";

export default function ListDiscounts() {
	const {discounts, loading, error, refetch} = useGetDiscounts();
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
			<Card px='0px'>
				{loading &&
					<Spinner/>
				}


				{!loading && (!discounts || discounts.length === 0) &&
					<><Flex justify='center' alignItems='center'>
						<Flex maxW={'50%'}>
							<AddNewCard name={"Discount"} pluralName={"Discounts"}
										action={onOpen}/>
						</Flex>
					</Flex>
						<Box display={"none"}>
							<AddDiscountModal refetch={refetch} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
						</Box>
					</>
				}


				{discounts?.length > 0 &&
					//@ts-ignore
					<DiscountTable tableData={discounts} refetch={refetch}/>
				}
			</Card>
		</Flex>
	);
}
